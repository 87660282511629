export const AUTHENTICATION_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  LOADING: 'LOADING',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  UNAUTHORISED: 'UNAUTHORISED',
  VERIFY: 'VERIFY',
  SOFT_ACCOUNT_REGISTRATION: 'SOFT_ACCOUNT_REGISTRATION',
  MFA_STATE: 'MFA_STATE',
  SMS_SUCCESS: 'SMS_SUCCESS',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  MFA_SETUP: 'MFA_SETUP',
  MFA_CHALLENGE: 'MFA_CHALLENGE',
  MFA_SUCCESS: 'MFA_SUCCESS',
  MFA_CANCEL: 'MFA_CANCEL',
  REGISTERED: 'REGISTERED',
  BIOMETRIC_STATE: 'BIOMETRIC_STATE',
  BIOMETRIC_FALURE: 'BIOMETRIC_FAILURE',
  ADD_PHONE: 'ADD_PHONE',
};

export const DEFAULT_APP_NAME = 'eg-auth-ui-v2';

export const DEFAULT_APP_VERSION = '1.0.0';

export const HEADER_EVENT_NAME = {
  'Sign In': 'form_sign_in_field.selected',
  'Sign Up': 'form_sign_up_field.selected',
  'Forgot Password': 'form_forgot_password_field.selected',
  'Reset Password': 'form_reset_password_field.selected',
};

export const DELETE_ACCOUNT_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  RETRY: 'RETRY',
};

export const MAX_VERIFICATION_CODE_LENGTH = 6;

export const RESEND_CODE_SECONDS_TIMER = 30;

export const BRAND_EXPEDIA = 'expedia';
export const BRAND_MRJET = 'mrjet';
export const BRAND_TRAVELOCITY = 'travelocity';
export const BRAND_CHEAPTICKETS = 'cheaptickets';
export const BRAND_LASTMINUTE = 'lastminute';
export const BRAND_WOTIF = 'wotif';

export const BRAND_EXPEDIA_TITLE = 'Expedia';

export const BRAND_FERRIS = 'ferris';

export const TITLE_CASE_BRAND_FERRIS = 'Ferris';

export const BRAND_HOTELS = 'hotels';

export const BRAND_EBOOKERS = 'ebookers';

export const BRAND_VRBO = 'vrbo';

export const EN_US_LOCALE = 'en_US';

export const BRANDS_WITH_EXTENSION = [BRAND_EBOOKERS, BRAND_HOTELS];

export const SocialAuthConstants = {
  SOCIAL_AUTH_REDIRECT_LINK: '/eg-auth-svcs/api/v1/oauth2/authorization',
  SOCIAL_AUTH_ORCHESTRATOR_ENDPOINT: '/identity/api/v1/oauth2/authorization',
  SOCIAL_AUTH_CHANNEL_TYPE: 'WEB',
  SOCIAL_AUTH_CLIENT_IDENTIFIER: 'UL',
  SOCIAL_AUTH_OI_CLIENT_IDENTIFIER: 'UL-OI',
  SOCIAL_AUTH_PROVIDERS: {
    APPLE: {
      PROVIDER_ID: 'apple-web',
      MARK_NAME: 'apple',
      PROVIDER_NAME: 'apple',
      FRAME_WIDTH: 700,
      FRAME_HEIGHT: 600,
    },
    GOOGLE: {
      PROVIDER_ID: 'google-web',
      MARK_NAME: 'google',
      PROVIDER_NAME: 'google',
      FRAME_WIDTH: 425,
      FRAME_HEIGHT: 450,
    },
    FACEBOOK: {
      PROVIDER_ID: 'facebook-web',
      MARK_NAME: 'facebook',
      PROVIDER_NAME: 'facebook',
      FRAME_WIDTH: 700,
      FRAME_HEIGHT: 637,
    },
    NAVER: {
      PROVIDER_ID: 'naver',
      PROVIDER_NAME: 'naver',
      FRAME_WIDTH: 454,
      FRAME_HEIGHT: 560,
      OIDC_URL: '/api/v3/oauth2/authorize',
      OIDC_PARAMS: {
        response_type: 'code',
        state: '',
        nonce: '',
        code_challenge: '',
        code_challenge_method: 'S256',
        client_id: '',
        redirect_uri: '/user/oidc/authenticate',
        idp_id: '',
      },
    },
  },
};

export const SIGNIN_SIGNUP_FLOW = 'Sign In Sign Up';
export const SIGNIN_FLOW = 'Sign In';

export const LEGACY_SCENARIO = {
  email: 1,
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const SCENARIO = {
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  PROFILE: 'PROFILE',
  CHECKOUT_SIGNIN: 'CHECKOUT_SIGNIN',
  CHECKOUT_SIGNUP: 'CHECKOUT_SIGNUP',
  AUTHENTICATED: 'AUTHENTICATED',
};

export const FLOW_IDENTIFIER_MAP = {
  OTP_SCREEN: 1,
  OTP_SCREEN_WITH_PASSWORD: 2,
  CREATE_PASSWORD_LEGACY: 3,
  OTP_WITHOUT_PASSWORD: 'OTP_0',
  OTP_WITH_PASSWORD: 'OTP_1',
  OTP_WITHOUT_PASSWORD_ONBOARDING: 'OTP_2',
  OTP_WITH_PASSWORD_ONBOARDING: 'OTP_3',
  ORIGIN: 4,
  OI_ORIGIN: 'ORIGIN',
  ONE_KEY_INTERSTITIAL: 'ONE_KEY',
  BETA_CONSENT_NEW_ACCOUNT: 'BETA_CONSENT_0',
  BETA_CONSENT_SINGLE_ACCOUNT: 'BETA_CONSENT_1',
  BETA_CONSENT_MULTI_ACCOUNT: 'BETA_CONSENT_2',
  UPGRADE_MERGE_CREATE_PASSWORD: 'ONE_IDENTITY_0',
  UPGRADE_MERGE_CONTINUE: 'ONE_IDENTITY_1',
  UPGRADE_MERGE_SET_PASSWORD: 'ONE_IDENTITY_2',
  SET_PASSWORD_WITH_MARKETING_CONSENT: 'PASSWORD_0',
  CREATE_PASSWORD_WITH_MARKETING_CONSENT: 'PASSWORD_1',
  SET_PASSWORD_WITHOUT_MARKETING_CONSENT: 'PASSWORD_2',
  CREATE_PASSWORD_WITHOUT_MARKETING_CONSENT: 'PASSWORD_3',
  ACCOUNT_INFO_WITH_MARKETING_CONSENT: 'PROFILE_DETAIL_0',
  ACCOUNT_INFO_WITHOUT_MARKETING_CONSENT: 'PROFILE_DETAIL_1',
  MARKETING_CONSENT: 'PROFILE_DETAIL_2',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
};

export const SIGNUP_FLOW_IDENTIFIER_LIST = [FLOW_IDENTIFIER_MAP.OTP_SCREEN, FLOW_IDENTIFIER_MAP.OTP_WITHOUT_PASSWORD];

export const MULTI_ACCOUNT_SIGNUP_FLOW_IDENTIFIER_LIST = [FLOW_IDENTIFIER_MAP.OTP_WITHOUT_PASSWORD_ONBOARDING];

export const OTP_PATH = {
  CKO: 'cko',
  EMAIL: 'email',
  PROFILE: 'profile',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const FORGOT_PASSWORD_LINK_CASE = {
  LEGACY: 'CASE_1',
  ONBOARDED: 'CASE_2',
};

// Copied from Customer-Account-PWA
export const EMAIL_REGEX =
  /^[a-zA-Z0-9_\.\-\+](?:[a-z0-9!#$%&'*+/=?^_`{|}~-]*(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i; // eslint-disable-line
export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 100;
export const PASSWORD_MIN_LENGTH = 9;
export const PASSWORD_MAX_LENGTH = 64;
export const PASSWORD_HAS_ALPHA_REGEX = /[a-zA-Z]/;
export const PASSWORD_HAS_NUMBER_REGEX = /[0-9]/;
export const PASSWORD_HAS_SPECIAL_CHAR_REGEX = /[~|!|@|#|$|%|^|&|*|\-|_|?]/;
export const INPUT_HAS_SPACE = /\s/;
export const INPUT_HAS_ONLY_NUMBER_REGEX = /^[0-9\b]+$/;
export const NAME_HAS_SPECIAL_CHAR_REGEX = /.*[&\\"<>\/:*%`+!?].*/; // eslint-disable-line
export const HAN_REGEX = /\p{sc=Han}/u; // CHINESE
export const ARABIC_REGEX = /\p{sc=Arab}/u; // ARABIC
export const HIRAGANA_REGEX = /\p{sc=Hira}/u; // JAPANESE
export const HANGUL_REGEX = /\p{sc=Hang}/u; // KOREAN
export const PHONE_NUMBER_REGEX = /^\d+$/;
export const EXPEDIA_GROUP_EMAIL_REGEX = /expediagroup\.com$/;
export const CODE_HAS_SIX_DIGITS = /^[0-9]{6}$/;

export const enum HEADER_TOOLBAR_TYPE {
  CLOSE = 'close',
  BACK = 'back',
}
export const enum ERROR_PAGE_CODE {
  GOOGLEONETAP = 'googleOneTap',
  DEFAULT = 'default',
  VERIFY_OTP_FORM_NAME = 'verifyOtpForm',
  ADD_PHONE_ERROR = 'addPhone',
  OTP_LOGIN_ERROR = 'otpLogin',
  ADD_USER_DETAILS = 'addUserDetails',
}

export const ERROR_PAGE_CLICKSTREAM_CONFIG = {
  [ERROR_PAGE_CODE.GOOGLEONETAP]: {
    error_name: 'Authentication Failure',
    error_description: 'Google one tap authentication failed',
    error_code: '401',
  },
  [ERROR_PAGE_CODE.DEFAULT]: {
    error_name: 'Error page',
    error_description: 'Error page',
    error_code: '401',
  },
  [ERROR_PAGE_CODE.ADD_PHONE_ERROR]: {
    error_name: 'Error page',
    error_description: 'Error description',
    error_code: '500',
  },
  [ERROR_PAGE_CODE.VERIFY_OTP_FORM_NAME]: {
    error_name: 'Error page',
    error_description: 'Error description',
    error_code: '500',
  },
  [ERROR_PAGE_CODE.OTP_LOGIN_ERROR]: {
    error_name: 'Error page',
    error_description: 'Error description',
    error_code: '500',
  },
  [ERROR_PAGE_CODE.ADD_USER_DETAILS]: {
    error_name: 'Error page',
    error_description: 'Error description',
    error_code: '500',
  },
};

export const EG_PROMOTIONAL_MAIL_SIGNUP_CHECKBOX = 'EG_PROMOTIONAL_MAIL_SIGNUP_CHECKBOX';
