// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { ExtendedContextStore } from 'types';
import { ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { EN_US_LOCALE } from 'src/constants';
import { capitalizeFirstLetter } from 'src/utils';
import { AccountInfoLogEvents } from 'loggers';

export class AccountInfoController implements Controller {
  public pageId = 'AccountInfo';
  public path = '/:locale?/accountinfo';
  public routeName = 'accountinfo';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/account-info-page/account-info-page.view'));
  public exact = true;

  public async fetch(options: FetchOptions) {
    const { isServer, stores, logger } = options;

    logger.logEvent(AccountInfoLogEvents.startLog);

    const contextStore = stores.get<ExtendedContextStore>('context');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    logger.logEvent(AccountInfoLogEvents.storesCreated);

    const placement = configStore.getAccountInfoPlacement();
    logger.logEvent(AccountInfoLogEvents.placementLogger(placement));

    let pageRequestContextStore;

    if (isServer) {
      logger.logEvent(AccountInfoLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(AccountInfoLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(AccountInfoLogEvents.requestContextError(error.message));
        });
    }
    return await Promise.all([pageRequestContextStore]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const brandName = capitalizeFirstLetter(options?.context?.site?.uiBrand);
    const formattedTitle = localization.formatText('eg.firstnamelastname.page.title.with.brand', brandName);
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
