// TODO: when OK support is added for wotif add logo here
export const FooterLogoAssets = {
  hotels: {
    de_AT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ca_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    da_DK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fi_FI: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    el_GR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    hu_HU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    is_IS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ID: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    id_ID: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_IT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_JP: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ja_JP: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ms_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ME: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_NL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nb_NO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_PH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_EU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_SG: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ZA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_KR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ko_KR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    sv_SE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_TW: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_TW: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_TH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    th_TH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    tr_TR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_GB: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_VN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    vi_VN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__stayz.svg',
        brand: 'Stayz',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    fr_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    fr_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    de_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    de_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    es_AR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_EC: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_SV: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_MX: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_MX: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_LA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_NZ: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__bookabach.svg',
        brand: 'Bookabach',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    es_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_VE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    pt_PT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    pt_BR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_FR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    zh_CN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    de_DE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
  },
  vrbo: {
    el_GR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ca_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_GB: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    de_AT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    de_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    en_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    da_DK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fi_FI: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_NL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_IT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ja_JP: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nb_NO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    sv_SE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_SG: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_Nl: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_NZ: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__bookabach.svg',
        brand: 'Bookabach',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    fr_FR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    pt_BR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    pt_PT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_MX: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__stayz.svg',
        brand: 'Stayz',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    de_DE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    en_AS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_PH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ZA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_AR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_EC: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_SV: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_VE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    fr_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    hu_HU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    id_ID: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    is_IS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ko_KR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ms_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    th_TH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    tr_TR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    vi_VN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_CN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_TW: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
  },
  expedia: {
    ar_SA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ar_AE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    de_AT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_CA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    da_DK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_DK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_EG: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_EU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_FI: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fi_FI: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_HK: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ID: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    id_ID: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_IT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_IT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_JP: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ja_JP: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ms_MY: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nl_NL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_NL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_NO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    nb_NO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_PH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_SA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_SG: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_KR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ko_KR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_SE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_TW: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_TW: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_TH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    th_TH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_GB: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_VN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    vi_VN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_CN: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    zh_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    fr_BE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    fr_FR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    en_BR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    pt_BR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_AR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CL: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CO: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_CR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_CR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_MX: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_MX: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_PE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    ca_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_ES: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    de_DE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    en_AU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__stayz.svg',
        brand: 'Stayz',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    en_NZ: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__bookabach.svg',
        brand: 'Bookabach',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    fr_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__abritel.svg',
        brand: 'Abritel',
      },
    },
    de_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__fewodirect.svg',
        brand: 'Fewodirect',
      },
    },
    en_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    sv_SE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    el_GR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_AS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    en_ZA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_EC: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_PA: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_SV: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_US: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    es_VE: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__spanish.svg',
        brand: 'Hoteles.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    hu_HU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    is_IS: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    it_CH: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    pt_PT: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__portuguese.svg',
        brand: 'Hoteis.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
    tr_TR: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg',
        brand: 'Vrbo',
      },
    },
  },
  wotif: {
    en_AU: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__stayz.svg',
        brand: 'Stayz',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
    en_NZ: {
      asset1: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__expedia.svg',
        brand: 'Expedia',
      },
      asset2: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__hotels__english.svg',
        brand: 'Hotels.com',
      },
      asset3: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__bookabach.svg',
        brand: 'Bookabach',
      },
      asset4: {
        link: 'https://a.travel-assets.com/egds/marks/onekey__wotif.svg',
        brand: 'Wotif',
      },
    },
  },
};

// Abritel, Fewo-direkt are not marked as heritage brands in the headers
export const VrboHeritageBrandEapIds = [
  29, // Vrbo Bookabach
  24, // Vrbo Stayz
  8, // Vrbo Fewo-direkt
];
