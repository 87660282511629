// External dependencies
import { Context } from 'bernie-context';
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';

// Internal dependencies
import { action, observable } from 'mobx';
import { callFetch } from 'src/utils';
import { RESEND_CODE_SECONDS_TIMER } from 'src/constants';
import { Headers } from 'src/types';
import {
  LoyaltyContext,
  MarketingEmailContext,
  PrepopulatedFieldsContext,
  RequestContextFetchResponse,
  SocialAuthConfigs,
  TermsAndConditionsContext,
} from 'types';

export class RequestContextServiceStore extends Store {
  requestContextServiceHost: string;
  requestContextServiceUrl: string;
  forwardedHeaders: Headers;

  @observable mfa_required: boolean;
  @observable password_strength_meter: boolean;
  @observable remember_me_enabled: boolean;
  @observable loyalty_context: LoyaltyContext;
  @observable terms_and_conditions_context: TermsAndConditionsContext;
  @observable marketing_email_context: MarketingEmailContext;
  @observable social_login_config: SocialAuthConfigs;
  @observable prepopulated_ui_fields: PrepopulatedFieldsContext;
  @observable resend_code_after_seconds: number;

  public constructor(
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forwardedHeaders?: Headers
  ) {
    super(state, logger);
    if (state) {
      this.requestContextServiceHost = state.host;
      this.requestContextServiceUrl = state.url;
    }
  }

  public hydrate(data: SerializedData) {
    delete data.requestContextState;
    Object.assign(this, data);
  }

  @action
  public setRequestContextDataForPage(data) {
    this.mfa_required = data.mfa_required || false;
    this.password_strength_meter = data.password_strength_meter || false;
    this.remember_me_enabled = data.remember_me_enabled || false;
    this.social_login_config = data.social_login_config || {};
    this.loyalty_context = data.loyalty_context || {};
    this.marketing_email_context = data.marketing_email_context || {};
    this.prepopulated_ui_fields = data.prepopulated_ui_fields || {};
    this.terms_and_conditions_context = data.terms_and_conditions_context || {};
    this.resend_code_after_seconds = data.resend_code_after_seconds || RESEND_CODE_SECONDS_TIMER;
  }

  @action
  public async getRequestContext(
    context: Context,
    requestHeaders = {},
    requestContextHost: string,
    requestContextUrl: string
  ) {
    const {
      site: { domain },
      deviceId,
    } = context;
    const traceId = context.traceInfo ? context.traceInfo['Trace-ID'] : '';
    const systemErrorEvent = { level: SystemEventLevel.ERROR, name: 'eg-auth-ui' };
    const headers = {
      'Trace-Id': traceId,
      'Device-User-Agent-ID': deviceId,
      'X-Forwarded-Host': domain,
      'Content-type': 'application/json',
    };

    const requestParams = {
      siteid: context.site.id,
    };

    try {
      const {
        hasError,
        code,
        message,
        requestId,
        mfa_required,
        password_strength_meter,
        remember_me_enabled,
        social_login_config,
        loyalty_context,
        terms_and_conditions_context,
        marketing_email_context,
        prepopulated_ui_fields,
        resend_code_after_seconds,
      } = (await callFetch(
        `${requestContextHost}${requestContextUrl}`,
        'GET',
        context,
        headers,
        requestParams
      )) as RequestContextFetchResponse;

      this.logger.logEvent(
        { level: SystemEventLevel.INFO, name: 'eg-auth-ui' },
        `REQUESTCONTEXTSTORE - request context response hasError=${hasError} statusCode=${code} message=${message} requestId=${requestId} mfa_required=${mfa_required} password_strength_meter=${password_strength_meter} resend_code_after_seconds=${resend_code_after_seconds} prepupulated_ui_fields=${JSON.stringify(
          prepopulated_ui_fields
        )} with context =${JSON.stringify(context.site)}`
      );

      if (hasError) {
        this.logger.logEvent(
          systemErrorEvent,
          `REQUESTCONTEXTSTORE - Get request Context API return with code=${code} error=${message} requestId=${requestId}`
        );
      } else {
        this.logger.logEvent(
          { level: SystemEventLevel.INFO, name: 'eg-auth-ui' },
          `REQUESTCONTEXTSTORE - Get request Context API return successfully mfa_required=${mfa_required} password_strength_meter=${password_strength_meter} remember_me_enabled=${remember_me_enabled} prepupulated_ui_fields=${JSON.stringify(
            prepopulated_ui_fields
          )}`
        );
      }

      if (marketing_email_context?.consent_checkbox === 'DEFAULT_OPT_IN') {
        this.logger.logEvent(
          systemErrorEvent,
          `REQUESTCONTEXTSTORE - Get request Context API return marketing_email_context.consent_checkbox as DEFAULT_OPT_IN`
        );
      }

      return {
        mfa_required,
        password_strength_meter,
        remember_me_enabled,
        social_login_config,
        loyalty_context,
        terms_and_conditions_context,
        marketing_email_context,
        prepopulated_ui_fields,
        resend_code_after_seconds,
      };
    } catch (_) {
      this.logger.logEvent(systemErrorEvent, 'Unexpected Request Context error during api call');
      // recover and pass object with error details
      return {
        mfa_required: false,
        password_strength_meter: false,
        remember_me_enabled: false,
        social_login_config: {},
        loyalty_context: {},
        terms_and_conditions_context: {},
        marketing_email_context: {},
        prepopulated_ui_fields: {},
        resend_code_after_seconds: RESEND_CODE_SECONDS_TIMER,
      };
    }
  }
}
