import { SystemEvent, SystemEventLevel } from 'bernie-logger';

const ControllerList = {
  loginController: 'login.controller.ts',
  signupController: 'signup.controller.ts',
  forgotPasswordController: 'forgot-password.controller.ts',
  setPasswordController: 'set-password.controller.ts',
  selectProviderController: 'select-provider.controller.ts',
  forgotPasswordControllerV2: 'forgot-password-v2.controller.ts',
  enterPasswordController: 'enter-password.controller.ts',
  userDeleteController: 'user-delete.controller.ts',
  verifyOtpController: 'verify-otp.controller.ts',
  addPasswordController: 'add-password.controller.ts',
  marketingConsentController: 'marketing-consent.controller.ts',
  accountInfoController: 'account-info.controller.ts',
  addPhoneController: 'add-phone.controller.ts',
  accountMergeController: 'account-merge.controller.ts',
  oauthVerifyEmailController: 'oauth-verify-email.controller.ts',
};

export const SelectProviderEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.loginController} initializing logic`),
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.selectProviderController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.selectProviderController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.selectProviderController} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const LoginLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.loginController} initializing logic`),
  storesCreated: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.loginController} - store instances created`),
  placementLogger: (placement: string) => {
    return new SystemEvent(SystemEventLevel.INFO, `${ControllerList.loginController} - using placement=${placement}`);
  },
  captchaStart: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.loginController} - starting to get captcha`),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.loginController} - captcha script has loaded correctly`
  ),
  captchaLoadedEvent: (captchaScript: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.loginController} - captchaScript = ${captchaScript}`
    );
  },
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.loginController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.loginController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.loginController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.loginController} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const SignUpLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.signupController} initializing logic`),
  storesCreated: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.signupController} - store instances created`),
  placementLogger: (placement: string) => {
    return new SystemEvent(SystemEventLevel.INFO, `${ControllerList.signupController} - using placement=${placement}`);
  },
  captchaStart: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.signupController} - starting to get captcha`),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.signupController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.signupController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.signupController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.signupController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.signupController} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const SetPasswordLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.setPasswordController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.setPasswordController} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.setPasswordController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.setPasswordController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.setPasswordController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.setPasswordController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.setPasswordController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.setPasswordController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.setPasswordController} - was not able to get identity config context error=${contextError}`
    );
  },
  verifyLinkExpiration: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.setPasswordController} - starting to verify the expiration date of password link`
  ),
  verifyLinkResponse: (hasExpired: boolean, emailAddress: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.setPasswordController} - verification link for emailAddress=${emailAddress}  has hasExpired=${hasExpired}`
    );
  },
  verifyLinkError: (verifyLink: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.setPasswordController} - verification link was no able to complete successfully error=${verifyLink}`
    );
  },
};

export const ForgotPasswordLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.forgotPasswordController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordController} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.forgotPasswordController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.forgotPasswordController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
};

export const ForgotPasswordLogEventsV2 = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.forgotPasswordControllerV2} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordControllerV2} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.forgotPasswordControllerV2} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordControllerV2} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordControllerV2} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.forgotPasswordControllerV2} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.forgotPasswordControllerV2} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.forgotPasswordControllerV2} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.forgotPasswordControllerV2} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const UserDeleteLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.userDeleteController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.userDeleteController} - store instances created`
  ),
  deleteAccountLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.userDeleteController} - delete Account script has loaded correctly`
  ),
  deleteAccountLoadedError: (deleteAccountError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.userDeleteController} - delete Account script was not able to be loaded error=${deleteAccountError}`
    );
  },
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.userDeleteController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.userDeleteController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.userDeleteController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.userDeleteController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  getIsOneIdentityOrOneKeyAccount: () => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.userDeleteController} - fetching isOneIdentityKey flag`
    );
  },
  setIsOneIdentityOrOneKeyAccount: (isOneIdentityFlag: boolean) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.userDeleteController} - sets the isOneIdentityKeyOrOneKeyAccount=${isOneIdentityFlag}`
    );
  },
  isOneIdentityOrOneKeyAccountError: (oneIdentityError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.userDeleteController} - was not able to retrieve oneIdentityKeyFlag error=${oneIdentityError}`
    );
  },
};

export const VerifyOtpLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.verifyOtpController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.verifyOtpController} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.verifyOtpController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.verifyOtpController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.verifyOtpController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.verifyOtpController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.verifyOtpController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.verifyOtpController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.verifyOtpController} - was not able to get identity config context error=${contextError}`
    );
  },
  emailOtpToSessionUserError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.verifyOtpController} - emailOtpToSessionUser API call failed with error=${contextError}`
    );
  },
};

export const EnterPasswordLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.enterPasswordController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.enterPasswordController} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.enterPasswordController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.enterPasswordController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.enterPasswordController} - captcha script has loaded correctly`
  ),
  captchaLoadedEvent: (captchaScript: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.enterPasswordController} - captchaScript = ${captchaScript}`
    );
  },
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.enterPasswordController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.enterPasswordController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.enterPasswordController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.enterPasswordController} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const AddPasswordLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.addPasswordController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.addPasswordController} - store instances created`
  ),
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.addPasswordController} - using placement=${placement}`
    );
  },
  captchaStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.addPasswordController} - starting to get captcha`
  ),
  captchaLoaded: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.addPasswordController} - captcha script has loaded correctly`
  ),
  captchaLoadedError: (captchaError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.addPasswordController} - captchaScript was not able to be loaded error=${captchaError}`
    );
  },
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.addPasswordController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.addPasswordController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.addPasswordController} - was not able to get identity config context error=${contextError}`
    );
  },
};

export const AccountInfoLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.accountInfoController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.accountInfoController} - store instances created`
  ),
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.accountInfoController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.accountInfoController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.accountInfoController} - was not able to get identity config context error=${contextError}`
    );
  },
  placementLogger: (placement: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.accountInfoController} - using placement=${placement}`
    );
  },
};

export const AddPhoneNumberLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.addPhoneController} page initialized`),
};

export const OAuthVerifyEmailLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.oauthVerifyEmailController} page initialized`),
  fetchOptionsFailed: new SystemEvent(
    SystemEventLevel.ERROR,
    `${ControllerList.oauthVerifyEmailController} - fetch options not returned`
  ),
};

export const AccountMergeLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.accountMergeController} page initialized`),
};

export const MarketingConsentLogEvents = {
  startLog: new SystemEvent(SystemEventLevel.INFO, `${ControllerList.marketingConsentController} initializing logic`),
  storesCreated: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.marketingConsentController} - store instances created`
  ),
  requestContextStart: new SystemEvent(
    SystemEventLevel.INFO,
    `${ControllerList.marketingConsentController} - starting to get identity config context information`
  ),
  requestContextLoaded: (data: string) => {
    return new SystemEvent(
      SystemEventLevel.INFO,
      `${ControllerList.marketingConsentController} - identity config context endpoint response data=${data}`
    );
  },
  requestContextError: (contextError: string) => {
    return new SystemEvent(
      SystemEventLevel.ERROR,
      `${ControllerList.marketingConsentController} - was not able to get identity config context error=${contextError}`
    );
  },
};
