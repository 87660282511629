// External dependencies
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';
import { action, observable } from 'mobx';
import {
  DeleteStatus,
  LoyaltyRewards,
  LoyaltyPrograms,
  UserDeleteAccountResponse,
  RewardStamp,
  RewardNight,
  Headers,
  CreditCardType,
} from 'src/types';
import { callFetch } from 'src/utils';
import { Context } from 'bernie-context';
import { ConfigServiceStore } from 'stores/config-service.store';

export class UserDeleteAccountDataStore extends Store {
  @observable public deleteStatus: DeleteStatus;
  @observable public isOneIdentityOnboardedUser = false;
  @observable public isOneKeyOnboardedUser = false;
  @observable public loyaltyRewards: LoyaltyRewards;
  @observable public rewardStamp: RewardStamp;
  @observable public rewardNight: RewardNight;
  @observable public credits: string;
  @observable public isHcomCCUser: boolean;
  @observable public hasBrandLegacyRewards: boolean;
  @observable public oneKeyCashValue: string;
  @observable public fetchingData = true;
  @observable public fetchFailed = false;
  @observable public fetchStatusCode: string;
  @observable public creditCardType: CreditCardType;
  forwardedHeaders: Headers;

  constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER, forwardedHeaders?: Headers) {
    super(state, logger);
    this.forwardedHeaders = forwardedHeaders;
  }

  public hydrate(data: SerializedData) {
    delete data.userDeleteAccount;
    Object.assign(this, data);
  }

  @action
  public setIsOneIdentityOnboardedUser(isOneIdentityFlag: boolean) {
    this.isOneIdentityOnboardedUser = isOneIdentityFlag;
  }

  @action
  public setCreditCardType(creditCardType: CreditCardType) {
    this.creditCardType = creditCardType;
  }

  setLoyaltyProgramValues = (listOfEnrolledPrograms: LoyaltyPrograms[]) => {
    for (const program of listOfEnrolledPrograms)
      switch (program.type) {
        case 'ONE_KEY_CASH': {
          if (program.value) {
            this.oneKeyCashValue = program.value;
          }
          break;
        }
        case 'CREDITS': {
          if (program.brand === 'HCOM') {
            this.isHcomCCUser = true;
          }
          break;
        }
        case 'POINTS': {
          if (program.value) {
            this.hasBrandLegacyRewards = true;
          }
          break;
        }
        case 'NIGHTS': {
          if (program.count > 0) {
            this.hasBrandLegacyRewards = true;
          }
          break;
        }
        case 'STAMPS': {
          if (program.count > 0) {
            this.hasBrandLegacyRewards = true;
          }
          break;
        }
      }
  };
  @action
  public setUserDeleteAccountData(responseData) {
    this.fetchingData = false;
    if (this.isOneIdentityOnboardedUser) {
      this.deleteStatus = responseData.status || 'ERROR';
      this.isOneKeyOnboardedUser = responseData.is_one_key_onboarded;
      if (responseData.program?.length > 0) {
        this.setLoyaltyProgramValues(responseData.program);
      }
    } else {
      this.deleteStatus = responseData.delete_status || 'ERROR';
      this.loyaltyRewards = responseData.loyalty_rewards || {};
      this.rewardStamp = responseData.reward_stamp || {};
      this.rewardNight = responseData.reward_night || {};
      this.credits = responseData.credits || '';
    }
  }

  @action
  public async getUserDeleteAccountData(
    context: Context,
    requestHeaders,
    configStore: ConfigServiceStore,
    mock?: string
  ) {
    const systemErrorEvent = { level: SystemEventLevel.ERROR, name: 'eg-auth-ui-v2' };
    const additionalHeaders = this.isOneIdentityOnboardedUser
      ? { cookie: this.forwardedHeaders['cookie'], ...requestHeaders }
      : Object.assign({}, {...requestHeaders}, this.forwardedHeaders);
    const duaid = context?.deviceId;
    const siteid = context?.site?.id;
    const userDeleteAccountStatusApi = this.isOneIdentityOnboardedUser
      ? `${configStore.getEgDeleteAccountHost()}${configStore.getEgDeleteAccountUrl()}`
      : `${configStore.getDeleteAccountHost()}${configStore.getDeleteAccountUrl()}`;

    try {
      const response = (await callFetch(userDeleteAccountStatusApi, 'GET', context, additionalHeaders, {
        mock,
      })) as UserDeleteAccountResponse;
      if (response.hasError || response.status === 'ERROR') {
        this.fetchFailed = true;
        this.fetchStatusCode = `${response.code}`;
        this.logger.logEvent(
          systemErrorEvent,
          `USER_DELETE_ACCOUNT_STORE - User Delete Account Status API return with code=${response.code} error=${response.message} requestId=${response.requestId} duaid=${duaid}`
        );
      } else {
        this.logger.logEvent(
          { level: SystemEventLevel.INFO, name: 'eg-auth-ui-v2' },
          `USER_DELETE_ACCOUNT_STORE - User Delete Account Status API success
          [duaid=${duaid}, siteid=${siteid}, delete_status:${response.delete_status || response.status || ''},
          loyalty_rewards:{reward_points:${response.loyalty_rewards?.reward_points}, value_worth:${
            response.loyalty_rewards?.value_worth
          },
          reward_night:{reward_count:${response.reward_night?.reward_count},reward_value:${
            response.reward_night?.reward_value
          },
          reward_stamp:{stamp_value:${response.reward_stamp?.stamp_value},stamp_count:${
            response.reward_stamp?.stamp_count
          }}
          isOneIdentityUser:${this.isOneIdentityOnboardedUser}, is_one_key_onboarded:${
            response.is_one_key_onboarded
          }, program:${JSON.stringify(response.program)}]`
        );
      }
      return response;
    } catch (_e) {
      this.logger.logEvent(systemErrorEvent, `Unexpected Request user delete account error during api call ${_e}`);
      // recover and pass object with error details
      return {
        hasError: true,
      };
    }
  }
}
