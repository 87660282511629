// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';
import { ExperimentStore } from 'bernie-plugin-mobx';

// Internal dependencies
import { ExtendedContextStore } from 'types';
import { CaptchaServiceStore, ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { LoginLogEvents } from 'loggers';
import { EN_US_LOCALE, UNIVERSAL_LOGIN_WITH_EMAIL_OTP } from 'src/constants';
import { capitalizeFirstLetter } from 'src/utils';

export class LoginController implements Controller {
  public pageId = 'Login';
  public path = '/:locale?/:sso?/login';
  public routeName = 'login';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/login-page/login-page.view'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async fetch(options: FetchOptions): Promise<Result[]> {
    const {
      isServer,
      stores,
      query: { oldAto },
      logger,
    } = options;

    logger.logEvent(LoginLogEvents.startLog);

    const experiments: ExperimentStore = stores.get<ExperimentStore>('experiment');
    const contextStore = stores.get<ExtendedContextStore>('context');
    const captchaStore = stores.get<CaptchaServiceStore>('captchaStore');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    const isTwoStepEnabledForUser = experiments?.exposures?.[UNIVERSAL_LOGIN_WITH_EMAIL_OTP]?.bucket || 0;
    let loginCaptchaWidget, pageRequestContextStore, verifyOtpSigninCaptchaWidget, verifyOtpSignupCaptchaWidget;
    logger.logEvent(LoginLogEvents.storesCreated);

    const placement =
      contextStore.site.brand === 'vrbo' ? configStore.getVrboLoginPlacement() : configStore.getLoginPlacement();

    logger.logEvent(LoginLogEvents.placementLogger(placement));
    logger.logEvent(LoginLogEvents.captchaStart);

    if (isServer) {
      loginCaptchaWidget = captchaStore
        .getCaptcha(contextStore, oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(LoginLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(LoginLogEvents.captchaLoadedError(error.message));
        });
      if (isTwoStepEnabledForUser) {
        verifyOtpSigninCaptchaWidget = captchaStore
          .getCaptcha(
            contextStore,
            oldAto,
            configStore.getVerifyOtpSigninPlacement(),
            configStore.oldCaptchaUrl,
            configStore.newCaptchaUrl
          )
          .then((data) => {
            if (data.widget) {
              captchaStore.setVerifyOtpSigninCaptchaScriptOnPage(data.widget);
              logger.logEvent(LoginLogEvents.captchaLoaded);
            }
          })
          .catch((error) => {
            logger.logEvent(LoginLogEvents.captchaLoadedError(error.message));
          });

        verifyOtpSignupCaptchaWidget = captchaStore
          .getCaptcha(
            contextStore,
            oldAto,
            configStore.getVerifyOtpSignupPlacement(),
            configStore.oldCaptchaUrl,
            configStore.newCaptchaUrl
          )
          .then((data) => {
            if (data.widget) {
              captchaStore.setVerifyOtpSignupCaptchaScriptOnPage(data.widget);
              logger.logEvent(LoginLogEvents.captchaLoaded);
            }
          })
          .catch((error) => {
            logger.logEvent(LoginLogEvents.captchaLoadedError(error.message));
          });
      }

      logger.logEvent(LoginLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(LoginLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(LoginLogEvents.requestContextError(error.message));
        });
      // Added this special scenario because site 29 (BEX NZ) is configured to use
      // en_AU locale instead of en_NZ, causing a mislead in translation keys.
      // The locale change at metadata level for site 29 is currently not possible,
      // so we are patching our code with this statement to solve this issue in the meantime.
      if (contextStore.site.id === 29) {
        const l10nOverride = new Localization('en_NZ');
        configStore.setLocaleOverrides({
          'eg.one.key.login.page.rewards.subheading': l10nOverride.formatText(
            'eg.one.key.login.page.rewards.subheading'
          ),
          'eg.one.key.login.page.travel.subheading': l10nOverride.formatText('eg.one.key.login.page.travel.subheading'),
        });
      }
    }

    return await Promise.all([
      loginCaptchaWidget,
      verifyOtpSigninCaptchaWidget,
      verifyOtpSignupCaptchaWidget,
      pageRequestContextStore,
    ]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const brandName = capitalizeFirstLetter(options?.context?.site?.uiBrand);
    const formattedTitle = localization.formatText('eg.login.account.page.title', brandName);
    return Promise.resolve({ title: formattedTitle }) as Promise<PageData>;
  }
}
