// Internal dependencies
import { EG_PROMOTIONAL_MAIL_SIGNUP_CHECKBOX } from 'src/constants';

export const getShowConsentCheckbox = (showMarketingConsent, marketing_email_context) => {
  const consentCheckbox = marketing_email_context.consent_checkbox || '';
  const showConsentCheckbox = showMarketingConsent && consentCheckbox !== 'AUTO_OPT_IN';
  return showConsentCheckbox;
};

export const storeMarketingConsent = (isSavePromotionalMailCheckboxValue: boolean, marketingConsentValue: boolean) => {
  if (isSavePromotionalMailCheckboxValue) {
    sessionStorage.setItem(EG_PROMOTIONAL_MAIL_SIGNUP_CHECKBOX, marketingConsentValue.toString());
  }
};
