export const HOME_REDIRECT_URL = '/';
export const LOGIN_REDIRECT_URL = '/login';
export const SSO_REDIRECT_URL = '/sso';
export const SIGNUP_REDIRECT_URL = '/signup';
export const VERIFY_OTP_REDIRECT_URL = '/verifyotp';
export const ENTER_PASSWORD_REDIRECT_URL = '/enterpassword';
export const USER_DELETE = '/user/delete';
export const USER_ACCOUNT_REDIRECT_URL = '/user/account';
export const UNIVERSAL_PROFILE_URL = '/account';
export const SL_MARKETING_CONSENT_URL = '/marketingconsent';
export const ACCOUNT_INFO_URL = '/accountinfo';
export const ADD_PHONE_URL = '/addphone';
export const ACCOUNT_MERGE_URL = '/accountmerge';

export const OIDC_AUTHORIZE_V2_URL = '/api/v2/oidc/authorize';
export const OIDC_AUTHORIZE_V3_URL = '/api/v3/oauth2/authorize';

export const AUTHENTICATED_USER_ALLOWED_PATHS = [USER_DELETE, VERIFY_OTP_REDIRECT_URL, ADD_PHONE_URL];

export const HCOM_CHECKOUT_URL_LIST = ['/booking/details.html'];
