import { Store } from 'bernie-plugin-mobx';
import { SerializedData } from 'bernie-core';
import { Logger } from 'bernie-logger';

export interface OAuth2Config {
  idp_id?: string;
  clientId?: string;
}

interface ServiceConfig {
  idp_id: string;
  clientId: string;
}

interface ProviderConfig {
  expedia: ServiceConfig;
  hotels: ServiceConfig;
}

interface Configs {
  naver: ProviderConfig;
}

export class OAuth2Configuration extends Store {
  naverOAuth2Config: OAuth2Config;

  public constructor(state: SerializedData, logger: Logger, brand?: string, oauth2Config?: Configs) {
    super(state, logger);

    if (oauth2Config && Object.keys(oauth2Config).length > 0) {
      this.naverOAuth2Config = oauth2Config.naver[brand];
    }
  }

  public hydrate(data: SerializedData) {
    Object.assign(this, data);
  }
}
