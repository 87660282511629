import { SIGN_IN_PAGE, SIGN_UP_PAGE } from '../constants';

export const getGoogleButtonLabelKey = (pageName) => {
  switch (pageName) {
    case SIGN_UP_PAGE:
      return 'eg.social.auth.signup.google.label';
    case SIGN_IN_PAGE:
    default:
      return 'eg.social.auth.signin.google.label';
  }
};

export const getContainerLabelKeyForSocialButtons = (pageName) => {
  switch (pageName) {
    case SIGN_IN_PAGE:
      return 'eg.social.auth.other.ways.to.signin';
    case SIGN_UP_PAGE:
      return 'eg.social.auth.other.ways.to.signup';
    default:
      return 'eg.social.auth.continue.label';
  }
};

export const getDiscountInfoTextKey = (siteId) => {
  switch (siteId) {
    case 72: // Switzerland
    case 1262: // Sweden
    case 65: // Sweden
      return 'eg.social.auth.save.on.hotels.more';
    default:
      return 'eg.social.auth.save.on.hotels';
  }
};

export const getDiscountRate = (siteId) => {
  switch (siteId) {
    case 72: // Switzerland
    case 1262: // Sweden
    case 65: // Sweden
      return 10;
    default:
      return 15;
  }
};

export const getSubHeaderText = ({ oneKeyFullLaunch, displayNewSubHeader, l10n, localeOverrides = {} }) => {
  if (displayNewSubHeader) {
    if (oneKeyFullLaunch) {
      return (
        localeOverrides['eg.one.key.login.page.rewards.subheading'] ??
        l10n.formatText('eg.one.key.login.page.rewards.subheading')
      ); // OK
    }
    return (
      localeOverrides['eg.one.key.login.page.travel.subheading'] ??
      l10n.formatText('eg.one.key.login.page.travel.subheading')
    ); // OI
  }
  return '';
};
