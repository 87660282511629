export const OTP_SCREEN_IDENTIFIER_MAP = {
  1: 'OTP_WITHOUT_PASSWORD',
  2: 'OTP_WITH_PASSWORD',
  OTP_0: 'OTP_WITHOUT_PASSWORD',
  OTP_1: 'OTP_WITH_PASSWORD',
  OTP_2: 'OTP_WITHOUT_PASSWORD_ONBOARDING',
  OTP_3: 'OTP_WITH_PASSWORD_ONBOARDING',
  CHANGE_EMAIL: 'PROFILE_FLOW',
  CHANGE_PASSWORD: 'PROFILE_FLOW',
};

export const OTP_FLOW_CONFIG = {
  OLD_OTP_PAGE: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.one.key.otp.page.subheading',
    showJunkMailInfo: false,
    showPasswordNavLink: true,
    showKeepMeSignedIn: true,
    showCreateAccountLink: true,
  },
  OTP_WITH_PASSWORD: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.one.key.otp.page.subheading',
    showJunkMailInfo: false,
    showPasswordNavLink: true,
    showKeepMeSignedIn: true,
    showCreateAccountLink: false,
  },
  OTP_WITHOUT_PASSWORD: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.one.key.otp.page.subheading',
    showJunkMailInfo: false,
    showPasswordNavLink: false,
    showKeepMeSignedIn: true,
    showCreateAccountLink: false,
  },
  PROFILE_FLOW: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.verify.otp.subheading.for.profile.update',
    showJunkMailInfo: false,
    showPasswordNavLink: false,
    showKeepMeSignedIn: false,
    showCreateAccountLink: false,
  },
  OTP_WITH_PASSWORD_ONBOARDING: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.one.key.otp.page.subheading.2',
    showJunkMailInfo: true,
    showPasswordNavLink: true,
    showKeepMeSignedIn: true,
    showCreateAccountLink: false,
  },
  OTP_WITHOUT_PASSWORD_ONBOARDING: {
    heading: 'eg.one.key.otp.page.heading',
    subheading: 'eg.one.key.otp.page.subheading.2',
    showJunkMailInfo: true,
    showPasswordNavLink: false,
    showKeepMeSignedIn: true,
    showCreateAccountLink: false,
  },
};
