export const countryCodes = [
  {
    id: 'AC',
    text: 'AC +247',
    shortLabel: 'AC +247',
    value: '247',
    selected: false,
    icon: null,
  },
  {
    id: 'AD',
    text: 'AD +376',
    shortLabel: 'AD +376',
    value: '376',
    selected: false,
    icon: null,
  },
  {
    id: 'AE',
    text: 'AE +971',
    shortLabel: 'AE +971',
    value: '971',
    selected: false,
    icon: null,
  },
  {
    id: 'AF',
    text: 'AF +93',
    shortLabel: 'AF +93',
    value: '93',
    selected: false,
    icon: null,
  },
  {
    id: 'AG',
    text: 'AG +1268',
    shortLabel: 'AG +1268',
    value: '1268',
    selected: false,
    icon: null,
  },
  {
    id: 'AI',
    text: 'AI +1264',
    shortLabel: 'AI +1264',
    value: '1264',
    selected: false,
    icon: null,
  },
  {
    id: 'AL',
    text: 'AL +355',
    shortLabel: 'AL +355',
    value: '355',
    selected: false,
    icon: null,
  },
  {
    id: 'AM',
    text: 'AM +374',
    shortLabel: 'AM +374',
    value: '374',
    selected: false,
    icon: null,
  },
  {
    id: 'AN',
    text: 'AN +599',
    shortLabel: 'AN +599',
    value: '599',
    selected: false,
    icon: null,
  },
  {
    id: 'AO',
    text: 'AO +244',
    shortLabel: 'AO +244',
    value: '244',
    selected: false,
    icon: null,
  },
  {
    id: 'AQ',
    text: 'AQ +672',
    shortLabel: 'AQ +672',
    value: '672',
    selected: false,
    icon: null,
  },
  {
    id: 'AR',
    text: 'AR +54',
    shortLabel: 'AR +54',
    value: '54',
    selected: false,
    icon: null,
  },
  {
    id: 'AS',
    text: 'AS +1684',
    shortLabel: 'AS +1684',
    value: '1684',
    selected: false,
    icon: null,
  },
  {
    id: 'AT',
    text: 'AT +43',
    shortLabel: 'AT +43',
    value: '43',
    selected: false,
    icon: null,
  },
  {
    id: 'AU',
    text: 'AU +61',
    shortLabel: 'AU +61',
    value: '61',
    selected: false,
    icon: null,
  },
  {
    id: 'AW',
    text: 'AW +297',
    shortLabel: 'AW +297',
    value: '297',
    selected: false,
    icon: null,
  },
  {
    id: 'AX',
    text: 'AX +358',
    shortLabel: 'AX +358',
    value: '358',
    selected: false,
    icon: null,
  },
  {
    id: 'AZ',
    text: 'AZ +994',
    shortLabel: 'AZ +994',
    value: '994',
    selected: false,
    icon: null,
  },
  {
    id: 'BA',
    text: 'BA +387',
    shortLabel: 'BA +387',
    value: '387',
    selected: false,
    icon: null,
  },
  {
    id: 'BB',
    text: 'BB +1246',
    shortLabel: 'BB +1246',
    value: '1246',
    selected: false,
    icon: null,
  },
  {
    id: 'BD',
    text: 'BD +880',
    shortLabel: 'BD +880',
    value: '880',
    selected: false,
    icon: null,
  },
  {
    id: 'BE',
    text: 'BE +32',
    shortLabel: 'BE +32',
    value: '32',
    selected: false,
    icon: null,
  },
  {
    id: 'BF',
    text: 'BF +226',
    shortLabel: 'BF +226',
    value: '226',
    selected: false,
    icon: null,
  },
  {
    id: 'BG',
    text: 'BG +359',
    shortLabel: 'BG +359',
    value: '359',
    selected: false,
    icon: null,
  },
  {
    id: 'BH',
    text: 'BH +973',
    shortLabel: 'BH +973',
    value: '973',
    selected: false,
    icon: null,
  },
  {
    id: 'BI',
    text: 'BI +257',
    shortLabel: 'BI +257',
    value: '257',
    selected: false,
    icon: null,
  },
  {
    id: 'BJ',
    text: 'BJ +229',
    shortLabel: 'BJ +229',
    value: '229',
    selected: false,
    icon: null,
  },
  {
    id: 'BL',
    text: 'BL +590',
    shortLabel: 'BL +590',
    value: '590',
    selected: false,
    icon: null,
  },
  {
    id: 'BM',
    text: 'BM +1441',
    shortLabel: 'BM +1441',
    value: '1441',
    selected: false,
    icon: null,
  },
  {
    id: 'BN',
    text: 'BN +673',
    shortLabel: 'BN +673',
    value: '673',
    selected: false,
    icon: null,
  },
  {
    id: 'BO',
    text: 'BO +591',
    shortLabel: 'BO +591',
    value: '591',
    selected: false,
    icon: null,
  },
  {
    id: 'BR',
    text: 'BR +55',
    shortLabel: 'BR +55',
    value: '55',
    selected: false,
    icon: null,
  },
  {
    id: 'BS',
    text: 'BS +1242',
    shortLabel: 'BS +1242',
    value: '1242',
    selected: false,
    icon: null,
  },
  {
    id: 'BT',
    text: 'BT +975',
    shortLabel: 'BT +975',
    value: '975',
    selected: false,
    icon: null,
  },
  {
    id: 'BW',
    text: 'BW +267',
    shortLabel: 'BW +267',
    value: '267',
    selected: false,
    icon: null,
  },
  {
    id: 'BY',
    text: 'BY +375',
    shortLabel: 'BY +375',
    value: '375',
    selected: false,
    icon: null,
  },
  {
    id: 'BZ',
    text: 'BZ +501',
    shortLabel: 'BZ +501',
    value: '501',
    selected: false,
    icon: null,
  },
  {
    id: 'CC',
    text: 'CC +61',
    shortLabel: 'CC +61',
    value: '61',
    selected: false,
    icon: null,
  },
  {
    id: 'CD',
    text: 'CD +243',
    shortLabel: 'CD +243',
    value: '243',
    selected: false,
    icon: null,
  },
  {
    id: 'CF',
    text: 'CF +236',
    shortLabel: 'CF +236',
    value: '236',
    selected: false,
    icon: null,
  },
  {
    id: 'CG',
    text: 'CG +242',
    shortLabel: 'CG +242',
    value: '242',
    selected: false,
    icon: null,
  },
  {
    id: 'CH',
    text: 'CH +41',
    shortLabel: 'CH +41',
    value: '41',
    selected: false,
    icon: null,
  },
  {
    id: 'CI',
    text: 'CI +225',
    shortLabel: 'CI +225',
    value: '225',
    selected: false,
    icon: null,
  },
  {
    id: 'CK',
    text: 'CK +682',
    shortLabel: 'CK +682',
    value: '682',
    selected: false,
    icon: null,
  },
  {
    id: 'CL',
    text: 'CL +56',
    shortLabel: 'CL +56',
    value: '56',
    selected: false,
    icon: null,
  },
  {
    id: 'CM',
    text: 'CM +237',
    shortLabel: 'CM +237',
    value: '237',
    selected: false,
    icon: null,
  },
  {
    id: 'CN',
    text: 'CN +86',
    shortLabel: 'CN +86',
    value: '86',
    selected: false,
    icon: null,
  },
  {
    id: 'CO',
    text: 'CO +57',
    shortLabel: 'CO +57',
    value: '57',
    selected: false,
    icon: null,
  },
  {
    id: 'CR',
    text: 'CR +506',
    shortLabel: 'CR +506',
    value: '506',
    selected: false,
    icon: null,
  },
  {
    id: 'CU',
    text: 'CU +53',
    shortLabel: 'CU +53',
    value: '53',
    selected: false,
    icon: null,
  },
  {
    id: 'CV',
    text: 'CV +238',
    shortLabel: 'CV +238',
    value: '238',
    selected: false,
    icon: null,
  },
  {
    id: 'CX',
    text: 'CX +61',
    shortLabel: 'CX +61',
    value: '61',
    selected: false,
    icon: null,
  },
  {
    id: 'CY',
    text: 'CY +357',
    shortLabel: 'CY +357',
    value: '357',
    selected: false,
    icon: null,
  },
  {
    id: 'CZ',
    text: 'CZ +420',
    shortLabel: 'CZ +420',
    value: '420',
    selected: false,
    icon: null,
  },
  {
    id: 'DE',
    text: 'DE +49',
    shortLabel: 'DE +49',
    value: '49',
    selected: false,
    icon: null,
  },
  {
    id: 'DJ',
    text: 'DJ +253',
    shortLabel: 'DJ +253',
    value: '253',
    selected: false,
    icon: null,
  },
  {
    id: 'DK',
    text: 'DK +45',
    shortLabel: 'DK +45',
    value: '45',
    selected: false,
    icon: null,
  },
  {
    id: 'DM',
    text: 'DM +1767',
    shortLabel: 'DM +1767',
    value: '1767',
    selected: false,
    icon: null,
  },
  {
    id: 'DO',
    text: 'DO +1849',
    shortLabel: 'DO +1849',
    value: '1849',
    selected: false,
    icon: null,
  },
  {
    id: 'DZ',
    text: 'DZ +213',
    shortLabel: 'DZ +213',
    value: '213',
    selected: false,
    icon: null,
  },
  {
    id: 'EC',
    text: 'EC +593',
    shortLabel: 'EC +593',
    value: '593',
    selected: false,
    icon: null,
  },
  {
    id: 'EE',
    text: 'EE +372',
    shortLabel: 'EE +372',
    value: '372',
    selected: false,
    icon: null,
  },
  {
    id: 'EG',
    text: 'EG +20',
    shortLabel: 'EG +20',
    value: '20',
    selected: false,
    icon: null,
  },
  {
    id: 'ER',
    text: 'ER +291',
    shortLabel: 'ER +291',
    value: '291',
    selected: false,
    icon: null,
  },
  {
    id: 'ES',
    text: 'ES +34',
    shortLabel: 'ES +34',
    value: '34',
    selected: false,
    icon: null,
  },
  {
    id: 'ET',
    text: 'ET +251',
    shortLabel: 'ET +251',
    value: '251',
    selected: false,
    icon: null,
  },
  {
    id: 'FI',
    text: 'FI +358',
    shortLabel: 'FI +358',
    value: '358',
    selected: false,
    icon: null,
  },
  {
    id: 'FJ',
    text: 'FJ +679',
    shortLabel: 'FJ +679',
    value: '679',
    selected: false,
    icon: null,
  },
  {
    id: 'FK',
    text: 'FK +500',
    shortLabel: 'FK +500',
    value: '500',
    selected: false,
    icon: null,
  },
  {
    id: 'FM',
    text: 'FM +691',
    shortLabel: 'FM +691',
    value: '691',
    selected: false,
    icon: null,
  },
  {
    id: 'FO',
    text: 'FO +298',
    shortLabel: 'FO +298',
    value: '298',
    selected: false,
    icon: null,
  },
  {
    id: 'FR',
    text: 'FR +33',
    shortLabel: 'FR +33',
    value: '33',
    selected: false,
    icon: null,
  },
  {
    id: 'GA',
    text: 'GA +241',
    shortLabel: 'GA +241',
    value: '241',
    selected: false,
    icon: null,
  },
  {
    id: 'GB',
    text: 'GB +44',
    shortLabel: 'GB +44',
    value: '44',
    selected: false,
    icon: null,
  },
  {
    id: 'GD',
    text: 'GD +1473',
    shortLabel: 'GD +1473',
    value: '1473',
    selected: false,
    icon: null,
  },
  {
    id: 'GE',
    text: 'GE +995',
    shortLabel: 'GE +995',
    value: '995',
    selected: false,
    icon: null,
  },
  {
    id: 'GF',
    text: 'GF +594',
    shortLabel: 'GF +594',
    value: '594',
    selected: false,
    icon: null,
  },
  {
    id: 'GG',
    text: 'GG +44',
    shortLabel: 'GG +44',
    value: '44',
    selected: false,
    icon: null,
  },
  {
    id: 'GH',
    text: 'GH +233',
    shortLabel: 'GH +233',
    value: '233',
    selected: false,
    icon: null,
  },
  {
    id: 'GI',
    text: 'GI +350',
    shortLabel: 'GI +350',
    value: '350',
    selected: false,
    icon: null,
  },
  {
    id: 'GL',
    text: 'GL +299',
    shortLabel: 'GL +299',
    value: '299',
    selected: false,
    icon: null,
  },
  {
    id: 'GM',
    text: 'GM +220',
    shortLabel: 'GM +220',
    value: '220',
    selected: false,
    icon: null,
  },
  {
    id: 'GN',
    text: 'GN +224',
    shortLabel: 'GN +224',
    value: '224',
    selected: false,
    icon: null,
  },
  {
    id: 'GP',
    text: 'GP +590',
    shortLabel: 'GP +590',
    value: '590',
    selected: false,
    icon: null,
  },
  {
    id: 'GQ',
    text: 'GQ +240',
    shortLabel: 'GQ +240',
    value: '240',
    selected: false,
    icon: null,
  },
  {
    id: 'GR',
    text: 'GR +30',
    shortLabel: 'GR +30',
    value: '30',
    selected: false,
    icon: null,
  },
  {
    id: 'GS',
    text: 'GS +500',
    shortLabel: 'GS +500',
    value: '500',
    selected: false,
    icon: null,
  },
  {
    id: 'GT',
    text: 'GT +502',
    shortLabel: 'GT +502',
    value: '502',
    selected: false,
    icon: null,
  },
  {
    id: 'GU',
    text: 'GU +1671',
    shortLabel: 'GU +1671',
    value: '1671',
    selected: false,
    icon: null,
  },
  {
    id: 'GW',
    text: 'GW +245',
    shortLabel: 'GW +245',
    value: '245',
    selected: false,
    icon: null,
  },
  {
    id: 'GY',
    text: 'GY +592',
    shortLabel: 'GY +592',
    value: '592',
    selected: false,
    icon: null,
  },
  {
    id: 'HK',
    text: 'HK +852',
    shortLabel: 'HK +852',
    value: '852',
    selected: false,
    icon: null,
  },
  {
    id: 'HN',
    text: 'HN +504',
    shortLabel: 'HN +504',
    value: '504',
    selected: false,
    icon: null,
  },
  {
    id: 'HR',
    text: 'HR +385',
    shortLabel: 'HR +385',
    value: '385',
    selected: false,
    icon: null,
  },
  {
    id: 'HT',
    text: 'HT +509',
    shortLabel: 'HT +509',
    value: '509',
    selected: false,
    icon: null,
  },
  {
    id: 'HU',
    text: 'HU +36',
    shortLabel: 'HU +36',
    value: '36',
    selected: false,
    icon: null,
  },
  {
    id: 'ID',
    text: 'ID +62',
    shortLabel: 'ID +62',
    value: '62',
    selected: false,
    icon: null,
  },
  {
    id: 'IE',
    text: 'IE +353',
    shortLabel: 'IE +353',
    value: '353',
    selected: false,
    icon: null,
  },
  {
    id: 'IL',
    text: 'IL +972',
    shortLabel: 'IL +972',
    value: '972',
    selected: false,
    icon: null,
  },
  {
    id: 'IM',
    text: 'IM +44',
    shortLabel: 'IM +44',
    value: '44',
    selected: false,
    icon: null,
  },
  {
    id: 'IN',
    text: 'IN +91',
    shortLabel: 'IN +91',
    value: '91',
    selected: false,
    icon: null,
  },
  {
    id: 'IO',
    text: 'IO +246',
    shortLabel: 'IO +246',
    value: '246',
    selected: false,
    icon: null,
  },
  {
    id: 'IQ',
    text: 'IQ +964',
    shortLabel: 'IQ +964',
    value: '964',
    selected: false,
    icon: null,
  },
  {
    id: 'IR',
    text: 'IR +98',
    shortLabel: 'IR +98',
    value: '98',
    selected: false,
    icon: null,
  },
  {
    id: 'IS',
    text: 'IS +354',
    shortLabel: 'IS +354',
    value: '354',
    selected: false,
    icon: null,
  },
  {
    id: 'IT',
    text: 'IT +39',
    shortLabel: 'IT +39',
    value: '39',
    selected: false,
    icon: null,
  },
  {
    id: 'JE',
    text: 'JE +44',
    shortLabel: 'JE +44',
    value: '44',
    selected: false,
    icon: null,
  },
  {
    id: 'JM',
    text: 'JM +1876',
    shortLabel: 'JM +1876',
    value: '1876',
    selected: false,
    icon: null,
  },
  {
    id: 'JO',
    text: 'JO +962',
    shortLabel: 'JO +962',
    value: '962',
    selected: false,
    icon: null,
  },
  {
    id: 'JP',
    text: 'JP +81',
    shortLabel: 'JP +81',
    value: '81',
    selected: false,
    icon: null,
  },
  {
    id: 'KE',
    text: 'KE +254',
    shortLabel: 'KE +254',
    value: '254',
    selected: false,
    icon: null,
  },
  {
    id: 'KG',
    text: 'KG +996',
    shortLabel: 'KG +996',
    value: '996',
    selected: false,
    icon: null,
  },
  {
    id: 'KH',
    text: 'KH +855',
    shortLabel: 'KH +855',
    value: '855',
    selected: false,
    icon: null,
  },
  {
    id: 'KI',
    text: 'KI +686',
    shortLabel: 'KI +686',
    value: '686',
    selected: false,
    icon: null,
  },
  {
    id: 'KM',
    text: 'KM +269',
    shortLabel: 'KM +269',
    value: '269',
    selected: false,
    icon: null,
  },
  {
    id: 'KN',
    text: 'KN +1869',
    shortLabel: 'KN +1869',
    value: '1869',
    selected: false,
    icon: null,
  },
  {
    id: 'KP',
    text: 'KP +850',
    shortLabel: 'KP +850',
    value: '850',
    selected: false,
    icon: null,
  },
  {
    id: 'KR',
    text: 'KR +82',
    shortLabel: 'KR +82',
    value: '82',
    selected: false,
    icon: null,
  },
  {
    id: 'KW',
    text: 'KW +965',
    shortLabel: 'KW +965',
    value: '965',
    selected: false,
    icon: null,
  },
  {
    id: 'KY',
    text: 'KY +1345',
    shortLabel: 'KY +1345',
    value: '1345',
    selected: false,
    icon: null,
  },
  {
    id: 'KZ',
    text: 'KZ +77',
    shortLabel: 'KZ +77',
    value: '77',
    selected: false,
    icon: null,
  },
  {
    id: 'LA',
    text: 'LA +856',
    shortLabel: 'LA +856',
    value: '856',
    selected: false,
    icon: null,
  },
  {
    id: 'LB',
    text: 'LB +961',
    shortLabel: 'LB +961',
    value: '961',
    selected: false,
    icon: null,
  },
  {
    id: 'LC',
    text: 'LC +1758',
    shortLabel: 'LC +1758',
    value: '1758',
    selected: false,
    icon: null,
  },
  {
    id: 'LI',
    text: 'LI +423',
    shortLabel: 'LI +423',
    value: '423',
    selected: false,
    icon: null,
  },
  {
    id: 'LK',
    text: 'LK +94',
    shortLabel: 'LK +94',
    value: '94',
    selected: false,
    icon: null,
  },
  {
    id: 'LR',
    text: 'LR +231',
    shortLabel: 'LR +231',
    value: '231',
    selected: false,
    icon: null,
  },
  {
    id: 'LS',
    text: 'LS +266',
    shortLabel: 'LS +266',
    value: '266',
    selected: false,
    icon: null,
  },
  {
    id: 'LT',
    text: 'LT +370',
    shortLabel: 'LT +370',
    value: '370',
    selected: false,
    icon: null,
  },
  {
    id: 'LU',
    text: 'LU +352',
    shortLabel: 'LU +352',
    value: '352',
    selected: false,
    icon: null,
  },
  {
    id: 'LV',
    text: 'LV +371',
    shortLabel: 'LV +371',
    value: '371',
    selected: false,
    icon: null,
  },
  {
    id: 'LY',
    text: 'LY +218',
    shortLabel: 'LY +218',
    value: '218',
    selected: false,
    icon: null,
  },
  {
    id: 'MA',
    text: 'MA +212',
    shortLabel: 'MA +212',
    value: '212',
    selected: false,
    icon: null,
  },
  {
    id: 'MC',
    text: 'MC +377',
    shortLabel: 'MC +377',
    value: '377',
    selected: false,
    icon: null,
  },
  {
    id: 'MD',
    text: 'MD +373',
    shortLabel: 'MD +373',
    value: '373',
    selected: false,
    icon: null,
  },
  {
    id: 'ME',
    text: 'ME +382',
    shortLabel: 'ME +382',
    value: '382',
    selected: false,
    icon: null,
  },
  {
    id: 'MF',
    text: 'MF +590',
    shortLabel: 'MF +590',
    value: '590',
    selected: false,
    icon: null,
  },
  {
    id: 'MG',
    text: 'MG +261',
    shortLabel: 'MG +261',
    value: '261',
    selected: false,
    icon: null,
  },
  {
    id: 'MH',
    text: 'MH +692',
    shortLabel: 'MH +692',
    value: '692',
    selected: false,
    icon: null,
  },
  {
    id: 'MK',
    text: 'MK +389',
    shortLabel: 'MK +389',
    value: '389',
    selected: false,
    icon: null,
  },
  {
    id: 'ML',
    text: 'ML +223',
    shortLabel: 'ML +223',
    value: '223',
    selected: false,
    icon: null,
  },
  {
    id: 'MM',
    text: 'MM +95',
    shortLabel: 'MM +95',
    value: '95',
    selected: false,
    icon: null,
  },
  {
    id: 'MN',
    text: 'MN +976',
    shortLabel: 'MN +976',
    value: '976',
    selected: false,
    icon: null,
  },
  {
    id: 'MO',
    text: 'MO +853',
    shortLabel: 'MO +853',
    value: '853',
    selected: false,
    icon: null,
  },
  {
    id: 'MP',
    text: 'MP +1670',
    shortLabel: 'MP +1670',
    value: '1670',
    selected: false,
    icon: null,
  },
  {
    id: 'MQ',
    text: 'MQ +596',
    shortLabel: 'MQ +596',
    value: '596',
    selected: false,
    icon: null,
  },
  {
    id: 'MR',
    text: 'MR +222',
    shortLabel: 'MR +222',
    value: '222',
    selected: false,
    icon: null,
  },
  {
    id: 'MS',
    text: 'MS +1664',
    shortLabel: 'MS +1664',
    value: '1664',
    selected: false,
    icon: null,
  },
  {
    id: 'MT',
    text: 'MT +356',
    shortLabel: 'MT +356',
    value: '356',
    selected: false,
    icon: null,
  },
  {
    id: 'MU',
    text: 'MU +230',
    shortLabel: 'MU +230',
    value: '230',
    selected: false,
    icon: null,
  },
  {
    id: 'MV',
    text: 'MV +960',
    shortLabel: 'MV +960',
    value: '960',
    selected: false,
    icon: null,
  },
  {
    id: 'MW',
    text: 'MW +265',
    shortLabel: 'MW +265',
    value: '265',
    selected: false,
    icon: null,
  },
  {
    id: 'MX',
    text: 'MX +52',
    shortLabel: 'MX +52',
    value: '52',
    selected: false,
    icon: null,
  },
  {
    id: 'MY',
    text: 'MY +60',
    shortLabel: 'MY +60',
    value: '60',
    selected: false,
    icon: null,
  },
  {
    id: 'MZ',
    text: 'MZ +258',
    shortLabel: 'MZ +258',
    value: '258',
    selected: false,
    icon: null,
  },
  {
    id: 'NA',
    text: 'NA +264',
    shortLabel: 'NA +264',
    value: '264',
    selected: false,
    icon: null,
  },
  {
    id: 'NC',
    text: 'NC +687',
    shortLabel: 'NC +687',
    value: '687',
    selected: false,
    icon: null,
  },
  {
    id: 'NE',
    text: 'NE +227',
    shortLabel: 'NE +227',
    value: '227',
    selected: false,
    icon: null,
  },
  {
    id: 'NF',
    text: 'NF +672',
    shortLabel: 'NF +672',
    value: '672',
    selected: false,
    icon: null,
  },
  {
    id: 'NG',
    text: 'NG +234',
    shortLabel: 'NG +234',
    value: '234',
    selected: false,
    icon: null,
  },
  {
    id: 'NI',
    text: 'NI +505',
    shortLabel: 'NI +505',
    value: '505',
    selected: false,
    icon: null,
  },
  {
    id: 'NL',
    text: 'NL +31',
    shortLabel: 'NL +31',
    value: '31',
    selected: false,
    icon: null,
  },
  {
    id: 'NO',
    text: 'NO +47',
    shortLabel: 'NO +47',
    value: '47',
    selected: false,
    icon: null,
  },
  {
    id: 'NP',
    text: 'NP +977',
    shortLabel: 'NP +977',
    value: '977',
    selected: false,
    icon: null,
  },
  {
    id: 'NR',
    text: 'NR +674',
    shortLabel: 'NR +674',
    value: '674',
    selected: false,
    icon: null,
  },
  {
    id: 'NU',
    text: 'NU +683',
    shortLabel: 'NU +683',
    value: '683',
    selected: false,
    icon: null,
  },
  {
    id: 'NZ',
    text: 'NZ +64',
    shortLabel: 'NZ +64',
    value: '64',
    selected: false,
    icon: null,
  },
  {
    id: 'OM',
    text: 'OM +968',
    shortLabel: 'OM +968',
    value: '968',
    selected: false,
    icon: null,
  },
  {
    id: 'PA',
    text: 'PA +507',
    shortLabel: 'PA +507',
    value: '507',
    selected: false,
    icon: null,
  },
  {
    id: 'PE',
    text: 'PE +51',
    shortLabel: 'PE +51',
    value: '51',
    selected: false,
    icon: null,
  },
  {
    id: 'PF',
    text: 'PF +689',
    shortLabel: 'PF +689',
    value: '689',
    selected: false,
    icon: null,
  },
  {
    id: 'PG',
    text: 'PG +675',
    shortLabel: 'PG +675',
    value: '675',
    selected: false,
    icon: null,
  },
  {
    id: 'PH',
    text: 'PH +63',
    shortLabel: 'PH +63',
    value: '63',
    selected: false,
    icon: null,
  },
  {
    id: 'PK',
    text: 'PK +92',
    shortLabel: 'PK +92',
    value: '92',
    selected: false,
    icon: null,
  },
  {
    id: 'PL',
    text: 'PL +48',
    shortLabel: 'PL +48',
    value: '48',
    selected: false,
    icon: null,
  },
  {
    id: 'PM',
    text: 'PM +508',
    shortLabel: 'PM +508',
    value: '508',
    selected: false,
    icon: null,
  },
  {
    id: 'PN',
    text: 'PN +872',
    shortLabel: 'PN +872',
    value: '872',
    selected: false,
    icon: null,
  },
  {
    id: 'PR',
    text: 'PR +1939',
    shortLabel: 'PR +1939',
    value: '1939',
    selected: false,
    icon: null,
  },
  {
    id: 'PS',
    text: 'PS +970',
    shortLabel: 'PS +970',
    value: '970',
    selected: false,
    icon: null,
  },
  {
    id: 'PT',
    text: 'PT +351',
    shortLabel: 'PT +351',
    value: '351',
    selected: false,
    icon: null,
  },
  {
    id: 'PW',
    text: 'PW +680',
    shortLabel: 'PW +680',
    value: '680',
    selected: false,
    icon: null,
  },
  {
    id: 'PY',
    text: 'PY +595',
    shortLabel: 'PY +595',
    value: '595',
    selected: false,
    icon: null,
  },
  {
    id: 'QA',
    text: 'QA +974',
    shortLabel: 'QA +974',
    value: '974',
    selected: false,
    icon: null,
  },
  {
    id: 'RE',
    text: 'RE +262',
    shortLabel: 'RE +262',
    value: '262',
    selected: false,
    icon: null,
  },
  {
    id: 'RO',
    text: 'RO +40',
    shortLabel: 'RO +40',
    value: '40',
    selected: false,
    icon: null,
  },
  {
    id: 'RS',
    text: 'RS +381',
    shortLabel: 'RS +381',
    value: '381',
    selected: false,
    icon: null,
  },
  {
    id: 'RU',
    text: 'RU +7',
    shortLabel: 'RU +7',
    value: '7',
    selected: false,
    icon: null,
  },
  {
    id: 'RW',
    text: 'RW +250',
    shortLabel: 'RW +250',
    value: '250',
    selected: false,
    icon: null,
  },
  {
    id: 'SA',
    text: 'SA +966',
    shortLabel: 'SA +966',
    value: '966',
    selected: false,
    icon: null,
  },
  {
    id: 'SB',
    text: 'SB +677',
    shortLabel: 'SB +677',
    value: '677',
    selected: false,
    icon: null,
  },
  {
    id: 'SC',
    text: 'SC +248',
    shortLabel: 'SC +248',
    value: '248',
    selected: false,
    icon: null,
  },
  {
    id: 'SD',
    text: 'SD +249',
    shortLabel: 'SD +249',
    value: '249',
    selected: false,
    icon: null,
  },
  {
    id: 'SE',
    text: 'SE +46',
    shortLabel: 'SE +46',
    value: '46',
    selected: false,
    icon: null,
  },
  {
    id: 'SG',
    text: 'SG +65',
    shortLabel: 'SG +65',
    value: '65',
    selected: false,
    icon: null,
  },
  {
    id: 'SH',
    text: 'SH +290',
    shortLabel: 'SH +290',
    value: '290',
    selected: false,
    icon: null,
  },
  {
    id: 'SI',
    text: 'SI +386',
    shortLabel: 'SI +386',
    value: '386',
    selected: false,
    icon: null,
  },
  {
    id: 'SJ',
    text: 'SJ +47',
    shortLabel: 'SJ +47',
    value: '47',
    selected: false,
    icon: null,
  },
  {
    id: 'SK',
    text: 'SK +421',
    shortLabel: 'SK +421',
    value: '421',
    selected: false,
    icon: null,
  },
  {
    id: 'SL',
    text: 'SL +232',
    shortLabel: 'SL +232',
    value: '232',
    selected: false,
    icon: null,
  },
  {
    id: 'SM',
    text: 'SM +378',
    shortLabel: 'SM +378',
    value: '378',
    selected: false,
    icon: null,
  },
  {
    id: 'SN',
    text: 'SN +221',
    shortLabel: 'SN +221',
    value: '221',
    selected: false,
    icon: null,
  },
  {
    id: 'SO',
    text: 'SO +252',
    shortLabel: 'SO +252',
    value: '252',
    selected: false,
    icon: null,
  },
  {
    id: 'SR',
    text: 'SR +597',
    shortLabel: 'SR +597',
    value: '597',
    selected: false,
    icon: null,
  },
  {
    id: 'SS',
    text: 'SS +211',
    shortLabel: 'SS +211',
    value: '211',
    selected: false,
    icon: null,
  },
  {
    id: 'ST',
    text: 'ST +239',
    shortLabel: 'ST +239',
    value: '239',
    selected: false,
    icon: null,
  },
  {
    id: 'SV',
    text: 'SV +503',
    shortLabel: 'SV +503',
    value: '503',
    selected: false,
    icon: null,
  },
  {
    id: 'SX',
    text: 'SX +1721',
    shortLabel: 'SX +1721',
    value: '1721',
    selected: false,
    icon: null,
  },
  {
    id: 'SY',
    text: 'SY +963',
    shortLabel: 'SY +963',
    value: '963',
    selected: false,
    icon: null,
  },
  {
    id: 'SZ',
    text: 'SZ +268',
    shortLabel: 'SZ +268',
    value: '268',
    selected: false,
    icon: null,
  },
  {
    id: 'TC',
    text: 'TC +1649',
    shortLabel: 'TC +1649',
    value: '1649',
    selected: false,
    icon: null,
  },
  {
    id: 'TD',
    text: 'TD +235',
    shortLabel: 'TD +235',
    value: '235',
    selected: false,
    icon: null,
  },
  {
    id: 'TG',
    text: 'TG +228',
    shortLabel: 'TG +228',
    value: '228',
    selected: false,
    icon: null,
  },
  {
    id: 'TH',
    text: 'TH +66',
    shortLabel: 'TH +66',
    value: '66',
    selected: false,
    icon: null,
  },
  {
    id: 'TJ',
    text: 'TJ +992',
    shortLabel: 'TJ +992',
    value: '992',
    selected: false,
    icon: null,
  },
  {
    id: 'TK',
    text: 'TK +690',
    shortLabel: 'TK +690',
    value: '690',
    selected: false,
    icon: null,
  },
  {
    id: 'TL',
    text: 'TL +670',
    shortLabel: 'TL +670',
    value: '670',
    selected: false,
    icon: null,
  },
  {
    id: 'TM',
    text: 'TM +993',
    shortLabel: 'TM +993',
    value: '993',
    selected: false,
    icon: null,
  },
  {
    id: 'TN',
    text: 'TN +216',
    shortLabel: 'TN +216',
    value: '216',
    selected: false,
    icon: null,
  },
  {
    id: 'TO',
    text: 'TO +676',
    shortLabel: 'TO +676',
    value: '676',
    selected: false,
    icon: null,
  },
  {
    id: 'TR',
    text: 'TR +90',
    shortLabel: 'TR +90',
    value: '90',
    selected: false,
    icon: null,
  },
  {
    id: 'TT',
    text: 'TT +1868',
    shortLabel: 'TT +1868',
    value: '1868',
    selected: false,
    icon: null,
  },
  {
    id: 'TV',
    text: 'TV +688',
    shortLabel: 'TV +688',
    value: '688',
    selected: false,
    icon: null,
  },
  {
    id: 'TW',
    text: 'TW +886',
    shortLabel: 'TW +886',
    value: '886',
    selected: false,
    icon: null,
  },
  {
    id: 'TZ',
    text: 'TZ +255',
    shortLabel: 'TZ +255',
    value: '255',
    selected: false,
    icon: null,
  },
  {
    id: 'UA',
    text: 'UA +380',
    shortLabel: 'UA +380',
    value: '380',
    selected: false,
    icon: null,
  },
  {
    id: 'UG',
    text: 'UG +256',
    shortLabel: 'UG +256',
    value: '256',
    selected: false,
    icon: null,
  },
  {
    id: 'US',
    text: 'US +1',
    shortLabel: 'US +1',
    value: '1',
    selected: false,
    icon: null,
  },
  {
    id: 'UY',
    text: 'UY +598',
    shortLabel: 'UY +598',
    value: '598',
    selected: false,
    icon: null,
  },
  {
    id: 'UZ',
    text: 'UZ +998',
    shortLabel: 'UZ +998',
    value: '998',
    selected: false,
    icon: null,
  },
  {
    id: 'VA',
    text: 'VA +379',
    shortLabel: 'VA +379',
    value: '379',
    selected: false,
    icon: null,
  },
  {
    id: 'VC',
    text: 'VC +1784',
    shortLabel: 'VC +1784',
    value: '1784',
    selected: false,
    icon: null,
  },
  {
    id: 'VE',
    text: 'VE +58',
    shortLabel: 'VE +58',
    value: '58',
    selected: false,
    icon: null,
  },
  {
    id: 'VG',
    text: 'VG +1284',
    shortLabel: 'VG +1284',
    value: '1284',
    selected: false,
    icon: null,
  },
  {
    id: 'VI',
    text: 'VI +1340',
    shortLabel: 'VI +1340',
    value: '1340',
    selected: false,
    icon: null,
  },
  {
    id: 'VN',
    text: 'VN +84',
    shortLabel: 'VN +84',
    value: '84',
    selected: false,
    icon: null,
  },
  {
    id: 'VU',
    text: 'VU +678',
    shortLabel: 'VU +678',
    value: '678',
    selected: false,
    icon: null,
  },
  {
    id: 'WF',
    text: 'WF +681',
    shortLabel: 'WF +681',
    value: '681',
    selected: false,
    icon: null,
  },
  {
    id: 'WS',
    text: 'WS +685',
    shortLabel: 'WS +685',
    value: '685',
    selected: false,
    icon: null,
  },
  {
    id: 'XK',
    text: 'XK +383',
    shortLabel: 'XK +383',
    value: '383',
    selected: false,
    icon: null,
  },
  {
    id: 'YE',
    text: 'YE +967',
    shortLabel: 'YE +967',
    value: '967',
    selected: false,
    icon: null,
  },
  {
    id: 'YT',
    text: 'YT +262',
    shortLabel: 'YT +262',
    value: '262',
    selected: false,
    icon: null,
  },
  {
    id: 'ZA',
    text: 'ZA +27',
    shortLabel: 'ZA +27',
    value: '27',
    selected: false,
    icon: null,
  },
  {
    id: 'ZM',
    text: 'ZM +260',
    shortLabel: 'ZM +260',
    value: '260',
    selected: false,
    icon: null,
  },
  {
    id: 'ZW',
    text: 'ZW +263',
    shortLabel: 'ZW +263',
    value: '263',
    selected: false,
    icon: null,
  },
];
