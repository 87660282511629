// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { ExtendedContextStore } from 'types';
import { CaptchaServiceStore, ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { EnterPasswordLogEvents } from 'loggers';
import { EN_US_LOCALE } from 'src/constants';

export class EnterPasswordController implements Controller {
  public pageId = 'EnterPassword';
  public path = '/:locale?/enterpassword';
  public routeName = 'enterpassword';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/enter-password-page/enter-password-page.view'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async fetch(options: FetchOptions): Promise<Result[]> {
    const {
      isServer,
      stores,
      query: { oldAto },
      logger,
    } = options;

    logger.logEvent(EnterPasswordLogEvents.startLog);

    const contextStore = stores.get<ExtendedContextStore>('context');
    const captchaStore = stores.get<CaptchaServiceStore>('captchaStore');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    let loginCaptchaWidget, pageRequestContextStore;
    logger.logEvent(EnterPasswordLogEvents.storesCreated);

    const placement =
      contextStore.site.brand === 'vrbo' ? configStore.getVrboLoginPlacement() : configStore.getLoginPlacement();

    logger.logEvent(EnterPasswordLogEvents.placementLogger(placement));
    logger.logEvent(EnterPasswordLogEvents.captchaStart);

    if (isServer) {
      loginCaptchaWidget = captchaStore
        .getCaptcha(contextStore, oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(EnterPasswordLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(EnterPasswordLogEvents.captchaLoadedError(error.message));
        });

      logger.logEvent(EnterPasswordLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(EnterPasswordLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(EnterPasswordLogEvents.requestContextError(error.message));
        });
    }

    return await Promise.all([loginCaptchaWidget, pageRequestContextStore]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const formattedTitle = localization.formatText('eg.login.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
