// Internal dependencies
import {
  HOME_REDIRECT_URL,
  LOGIN_REDIRECT_URL,
  SIGNUP_REDIRECT_URL,
  SSO_REDIRECT_URL,
  ENTER_PASSWORD_REDIRECT_URL,
  VERIFY_OTP_REDIRECT_URL,
  USER_DELETE,
  USER_ACCOUNT_REDIRECT_URL,
  SL_MARKETING_CONSENT_URL,
  HCOM_CHECKOUT_URL_LIST,
  AUTHENTICATED_USER_ALLOWED_PATHS,
  UNIVERSAL_PROFILE_URL,
  ADD_PHONE_URL,
} from '../constants';
import { getBasePath } from '../utils';

const matchesUrl = (redirectUrl, url) => {
  const basePath = getBasePath(url);
  const expectedUrl = `${basePath}${redirectUrl}`;
  return url === expectedUrl;
};

export const isLoginUrl = (url) => matchesUrl(LOGIN_REDIRECT_URL, url);
export const isVerifyOtpUrl = (url) => matchesUrl(VERIFY_OTP_REDIRECT_URL, url);
export const isEnterPasswordUrl = (url) => matchesUrl(ENTER_PASSWORD_REDIRECT_URL, url);
export const isSSOLoginUrl = (url) => matchesUrl(SSO_REDIRECT_URL + LOGIN_REDIRECT_URL, url);
export const isSignupUrl = (url) => matchesUrl(SIGNUP_REDIRECT_URL, url);
export const isUserDeleteUrl = (url) => matchesUrl(USER_DELETE, url);
export const isMarketingConsentUrl = (url) => matchesUrl(SL_MARKETING_CONSENT_URL, url);
export const isAddPhoneUrl = (url) => matchesUrl(ADD_PHONE_URL, url);

export const getAbsoluteRedirectUrl = (relativeUrl: string, origin?: string) => {
  if (origin) {
    return `${origin}${relativeUrl}`;
  } else {
    if (typeof window !== 'undefined') {
      try {
        // blocking external urls in relativeUrl
        const isExternalURl = new URL(`${window.location.origin}${relativeUrl}`);
        if (isExternalURl.origin !== window.location.origin) {
          return HOME_REDIRECT_URL;
        }
      } catch (e) {
        return HOME_REDIRECT_URL;
      }
      return `${window.location.origin}${relativeUrl}`;
    } else {
      return HOME_REDIRECT_URL;
    }
  }
};

export const verifyRelativeRedirectUrl = (relativeUrl: string) => {
  if (typeof window !== 'undefined') {
    try {
      // blocking external urls in relativeUrl
      const isExternalURl = new URL(`${window.location.origin}${relativeUrl}`);
      if (isExternalURl.origin !== window.location.origin) {
        return HOME_REDIRECT_URL;
      }
      return relativeUrl;
    } catch (e) {
      return HOME_REDIRECT_URL;
    }
  } else {
    return HOME_REDIRECT_URL;
  }
};

export const isSessionCheckUrl = (url) => {
  return (
    isLoginUrl(url) ||
    isVerifyOtpUrl(url) ||
    isEnterPasswordUrl(url) ||
    isSSOLoginUrl(url) ||
    isSignupUrl(url) ||
    isUserDeleteUrl(url) ||
    isAddPhoneUrl(url)
  );
};

export const getBrandProfilePath = (brand: string, locale: string) => {
  switch (brand) {
    case 'vsc':
      return `/${locale}/espaceclient/home-client`;
    case 'ferris':
      return '/dashboard';
    case 'vrbo':
    case 'hotels':
    case 'expedia':
      return UNIVERSAL_PROFILE_URL;
    default:
      return USER_ACCOUNT_REDIRECT_URL;
  }
};

export const getPathBasisRedirectToUrl = (pageUrl, context, redirectTo) => {
  if (isUserDeleteUrl(pageUrl)) {
    const brand = context?.site?.brand || '';
    const locale = context?.locale || '';

    return getBrandProfilePath(brand, locale);
  }
  return redirectTo ? encodeURIComponent(redirectTo) : HOME_REDIRECT_URL;
};

const isCheckoutRedirectUrl = (redirectUrl, checkoutUrlList) => {
  try {
    return checkoutUrlList.some((rurl) => redirectUrl.includes(rurl));
  } catch (e) {
    return false;
  }
};

export const isPageForAuthenticatedUser = (pageUrl) => {
  try {
    return AUTHENTICATED_USER_ALLOWED_PATHS.some((url) => pageUrl.includes(url));
  } catch (e) {
    return false;
  }
};

export const isHcomCheckoutRedirectUrl = (redirectUrl) => {
  return isCheckoutRedirectUrl(redirectUrl, HCOM_CHECKOUT_URL_LIST);
};

export const appendQueryParamsInRelativeUrl = (relativeUrl: string, params: object) => {
  const url = new URL(relativeUrl, window.location.origin);
  Object.keys(params).forEach((paramName) => {
    url.searchParams.set(paramName, params[paramName]);
  });
  return url.pathname + url.search;
};
