import { ADD_PASSWORD_SCENARIO_CONFIG, ADD_PASSWORD_SCREEN_VARIANT_MAP } from '../constants';
import { FlowIdentifierType } from 'src/types';

export const getAddPasswordScenarioConfig = (scenario: FlowIdentifierType) => {
  const defaultConfig = ADD_PASSWORD_SCENARIO_CONFIG.CREATE_PASSWORD_WITHOUT_MARKETING_CONSENT;
  const screenVariant = ADD_PASSWORD_SCREEN_VARIANT_MAP[scenario];
  if (!screenVariant) {
    return defaultConfig;
  }
  const config = ADD_PASSWORD_SCENARIO_CONFIG[screenVariant];
  if (!config) {
    return defaultConfig;
  }
  return config;
};
