// External dependencies
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, observable } from 'mobx';

// Internal dependencies

export class ConfigServiceStore extends Store {
  @observable public trustWidgetUrl: string;
  @observable public forgotPasswordPlacement: string;
  @observable public setPasswordPlacement: string;
  @observable public signUpPlacement: string;
  @observable public verifyOtpSigninPlacement: string;
  @observable public verifyOtpSignupPlacement: string;
  @observable public addPasswordPlacement: string;
  @observable public accountInfoPlacement: string;
  @observable public biometricChallenge: string;
  @observable public biometricLoginPlacement: string;
  @observable public biometricUser: string;
  @observable public login: string;
  @observable public vrboLogin: string;
  @observable public changePasswordPlacement: string;
  @observable public deleteAccountPlacement: string;
  @observable public verifyOtpChangePasswordPlacement: string;
  @observable public verifyOtpChangeEmailPlacement: string;
  @observable public socialAuthGooglePlacement: string;
  @observable public socialAuthFacebookPlacement: string;
  @observable public socialAuthApplePlacement: string;
  @observable public mfaStatePlacement: string;
  @observable public mfaVerifyPlacement: string;
  @observable public mfaChallengePlacement: string;
  @observable public loginEmailPlacement: string;
  @observable public sendOtpPlacement: string;

  @observable public oldCaptchaUrl: string;
  @observable public newCaptchaUrl: string;

  @observable public requestContextHost: string;
  @observable public requestContextUrl: string;

  @observable public deleteAccountHost: string;
  @observable public deleteAccountUrl: string;

  @observable public egDeleteAccountHost: string;
  @observable public egDeleteAccountUrl: string;

  @observable public emailOtpToSessionUserHost: string;
  @observable public emailOtpToSessionUserUrl: string;

  @observable public resetPwdHost: string;
  @observable public resetPwdUrl: string;
  @observable public resetPwdOldUrl: string;

  @observable public setPasswordUrl: string;
  @observable public setPasswordOldUrl: string;
  @observable public setPasswordOneIdUrl: string;

  @observable public waitBeforeRedirectingUp: number;

  @observable public accountInfoUrl: string;

  @observable public addPasswordUrl: string;
  @observable public addPasswordChangeUrl: string;
  @observable public addPasswordAuthSvcUrl: string;

  @observable public biometricUrl: string;

  @observable public enterPasswordUrl: string;
  @observable public enterPasswordOauth2Url: string;
  @observable public enterPasswordAuthSvcUrl: string;

  @observable public mfaSendSMSUrl: string;
  @observable public mfaSignupVerifyUrl: string;
  @observable public mfaStateEndpointUrl: string;
  @observable public mfaChallengeEndpointUrl: string;
  @observable public mfaLoginVerifyUrl: string;

  @observable public passwordAuthUrl: string;
  @observable public passwordAuthOauth2Url: string;

  @observable public resendOtpUrl: string;
  @observable public resendOtpAuthSvcUrl: string;

  @observable public forgotPasswordUrl: string;
  @observable public forgotPasswordOldUrl: string;

  @observable public signUpUrl: string;

  @observable public socialAuthUrl: string;
  @observable public socialAuthRedirectUrl: string;

  @observable public verifyOtpUrl: string;
  @observable public verifyOtpAuthSvcUrl: string;
  @observable public verifyOtpElevateAuthSessionUrl: string;

  @observable public localeOverrides: Record<string, string>;
  @observable public mfaResendCode: object;

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);

    if (state.trustWidgetUrl) {
      this.trustWidgetUrl = state.trustWidgetUrl.url;
    }

    if (state.securityToken) {
      this.forgotPasswordPlacement = state.securityToken.FORGOT_PASSWORD;
      this.setPasswordPlacement = state.securityToken.SET_PASSWORD;
      this.signUpPlacement = state.securityToken.SIGN_UP;
      this.biometricChallenge = state.securityToken.BIOMETRIC_CHALLENGE;
      this.biometricLoginPlacement = state.securityToken.BIOMETRIC_LOGIN;
      this.biometricUser = state.securityToken.BIOMETRIC_USER;
      this.login = state.securityToken.LOGIN;
      this.vrboLogin = state.securityToken.VRBO_LOGIN;
      this.verifyOtpSigninPlacement = state.securityToken.VERIFY_OTP_SIGNIN;
      this.verifyOtpSignupPlacement = state.securityToken.VERIFY_OTP_SIGNUP;
      this.addPasswordPlacement = state.securityToken.ADD_PASSWORD;
      this.accountInfoPlacement = state.securityToken.SET_ACCOUNT_INFO;
      this.changePasswordPlacement = state.securityToken.CHANGE_PASSWORD;
      this.deleteAccountPlacement = state.securityToken.DELETE_ACCOUNT;
      this.verifyOtpChangePasswordPlacement = state.securityToken.VERIFY_OTP_CHANGE_PASSWORD;
      this.verifyOtpChangeEmailPlacement = state.securityToken.VERIFY_OTP_CHANGE_EMAIL;
      this.socialAuthGooglePlacement = state.securityToken.SOCIAL_AUTH_GOOGLE;
      this.socialAuthFacebookPlacement = state.securityToken.SOCIAL_AUTH_FACEBOOK;
      this.socialAuthApplePlacement = state.securityToken.SOCIAL_AUTH_APPLE;
      this.mfaStatePlacement = state.securityToken.MFA_STATE;
      this.mfaVerifyPlacement = state.securityToken.MFA_VERIFY;
      this.mfaChallengePlacement = state.securityToken.MFA_CHALLENGE;
      this.loginEmailPlacement = state.securityToken.LOGIN_VIA_EMAIL;
      this.sendOtpPlacement = state.securityToken.SEND_OTP;
      this.mfaResendCode = {};
      this.mfaResendCode['MFA_RESEND_CODE_1'] = state.securityToken.MFA_RESEND_CODE_1;
      this.mfaResendCode['MFA_RESEND_CODE_2'] = state.securityToken.MFA_RESEND_CODE_2;
      this.mfaResendCode['MFA_RESEND_CODE_3'] = state.securityToken.MFA_RESEND_CODE_3;
      this.mfaResendCode['MFA_RESEND_CODE_4'] = state.securityToken.MFA_RESEND_CODE_4;
      this.mfaResendCode['MFA_RESEND_CODE_5'] = state.securityToken.MFA_RESEND_CODE_5;
      this.mfaResendCode['MFA_RESEND_CODE_6'] = state.securityToken.MFA_RESEND_CODE_6;
    }

    if (state.captchaData) {
      this.oldCaptchaUrl = state.captchaData.oldUrl;
      this.newCaptchaUrl = state.captchaData.newUrl;
    }
    if (state.requestContext) {
      this.requestContextHost = state.requestContext.host;
      this.requestContextUrl = state.requestContext.url;
    }
    if (state.deleteAccount) {
      this.deleteAccountHost = state.deleteAccount.host;
      this.deleteAccountUrl = state.deleteAccount.url;
    }
    if (state.egDeleteAccount) {
      this.egDeleteAccountHost = state.egDeleteAccount.host;
      this.egDeleteAccountUrl = state.egDeleteAccount.url;
    }
    if (state.emailOtpToSessionUser) {
      this.emailOtpToSessionUserHost = state.emailOtpToSessionUser.host;
      this.emailOtpToSessionUserUrl = state.emailOtpToSessionUser.url;
    }
    if (state.resetPasswordLinkVerification) {
      this.resetPwdHost = state.resetPasswordLinkVerification.host;
      this.resetPwdUrl = state.resetPasswordLinkVerification.url;
      this.resetPwdOldUrl = state.resetPasswordLinkVerification.oldUrl;
    }
    if (state.setPassword) {
      this.setPasswordUrl = state.setPassword.url;
      this.setPasswordOldUrl = state.setPassword.oldUrl;
      this.setPasswordOneIdUrl = state.setPassword.oneIdUrl;
    }
    if (state.waitBeforeRedirectingUp) {
      this.waitBeforeRedirectingUp = state.waitBeforeRedirectingUp;
    }
    if (state.accountInfo) {
      this.accountInfoUrl = state.accountInfo.url;
    }
    if (state.addPassword) {
      this.addPasswordUrl = state.addPassword.url;
      this.addPasswordChangeUrl = state.addPassword.changeUrl;
      this.addPasswordAuthSvcUrl = state.addPassword.authSvcUrl;
    }
    if (state.biometric) {
      this.biometricUrl = state.biometric.url;
    }
    if (state.enterPassword) {
      this.enterPasswordUrl = state.enterPassword.url;
      this.enterPasswordOauth2Url = state.enterPassword.oauth2Url;
      this.enterPasswordAuthSvcUrl = state.enterPassword.authSvcUrl;
    }
    if (state.mfa) {
      this.mfaSendSMSUrl = state.mfa.sendSMSUrl;
      this.mfaSignupVerifyUrl = state.mfa.signupVerifyUrl;
      this.mfaStateEndpointUrl = state.mfa.stateEndpointUrl;
      this.mfaChallengeEndpointUrl = state.mfa.challengeEndpointUrl;
      this.mfaLoginVerifyUrl = state.mfa.loginVerifyUrl;
    }
    if (state.passwordAuth) {
      this.passwordAuthUrl = state.passwordAuth.url;
      this.passwordAuthOauth2Url = state.passwordAuth.oauth2Url;
    }
    if (state.resendOtp) {
      this.resendOtpUrl = state.resendOtp.url;
      this.resendOtpAuthSvcUrl = state.resendOtp.authSvcUrl;
    }
    if (state.forgotPassword) {
      this.forgotPasswordUrl = state.forgotPassword.url;
      this.forgotPasswordOldUrl = state.forgotPassword.oldUrl;
    }
    if (state.signUp) {
      this.signUpUrl = state.signUp.url;
    }
    if (state.socialAuth) {
      this.socialAuthUrl = state.socialAuth.url;
      this.socialAuthRedirectUrl = state.socialAuth.redirectUrl;
    }
    if (state.verifyOtp) {
      this.verifyOtpUrl = state.verifyOtp.url;
      this.verifyOtpAuthSvcUrl = state.verifyOtp.authSvcUrl;
      this.verifyOtpElevateAuthSessionUrl = state.verifyOtp.elevateAuthSessionUrl;
    }
    if (state.localeOverrides) {
      this.localeOverrides = state.localeOverrides;
    }
  }

  public hydrate(data: SerializedData) {
    Object.assign(this, data);
  }

  @action
  public getResetPwdHost(): string {
    return this.resetPwdHost;
  }

  @action
  public setResetPwdHost(host: string) {
    this.resetPwdHost = host;
  }

  @action
  public getResetPwdOldUrl(): string {
    return this.resetPwdOldUrl;
  }

  @action
  public setResetPwdOldUrl(url: string) {
    this.resetPwdOldUrl = url;
  }

  @action
  public getResetPwdUrl(): string {
    return this.resetPwdUrl;
  }

  @action
  public setResetPwdUrl(value: string) {
    this.resetPwdUrl = value;
  }

  @action
  public setRequestContextHost(host: string) {
    this.requestContextHost = host;
  }

  @action
  public getRequestContextHost(): string {
    return this.requestContextHost;
  }

  @action
  public setRequestContextUrl(url: string) {
    this.requestContextUrl = url;
  }

  @action
  public getRequestContextUrl(): string {
    return this.requestContextUrl;
  }

  @action
  public setDeleteAccountHost(host: string) {
    this.deleteAccountHost = host;
  }

  @action
  public getEgDeleteAccountHost(): string {
    return this.egDeleteAccountHost;
  }

  @action
  public setEgDeleteAccountHost(host: string) {
    this.egDeleteAccountHost = host;
  }

  @action
  public getDeleteAccountHost(): string {
    return this.deleteAccountHost;
  }

  @action
  public setDeleteAccountUrl(url: string) {
    this.deleteAccountUrl = url;
  }

  @action
  public getDeleteAccountUrl(): string {
    return this.deleteAccountUrl;
  }

  @action
  public setEgDeleteAccountUrl(url: string) {
    this.egDeleteAccountUrl = url;
  }

  @action
  public getEgDeleteAccountUrl(): string {
    return this.egDeleteAccountUrl;
  }
  @action
  public setEmailOtpToSessionUserHost(host: string) {
    this.emailOtpToSessionUserHost = host;
  }

  @action
  public getEmailOtpToSessionUserHost(): string {
    return this.emailOtpToSessionUserHost;
  }

  @action
  public setEmailOtpToSessionUserUrl(url: string) {
    this.emailOtpToSessionUserUrl = url;
  }

  @action
  public getEmailOtpToSessionUserUrl(): string {
    return this.emailOtpToSessionUserUrl;
  }

  @action
  public setOldCaptchaUrl(url: string) {
    this.oldCaptchaUrl = url;
  }

  @action
  public getOldCaptchaUrl(): string {
    return this.oldCaptchaUrl;
  }

  @action
  public setNewCaptchaUrl(url: string) {
    this.newCaptchaUrl = url;
  }

  @action
  public getNewCaptchaUrl(): string {
    return this.newCaptchaUrl;
  }

  @action
  public setForgotPasswordPlacement(placement: string) {
    this.forgotPasswordPlacement = placement;
  }

  @action
  public getForgotPasswordPlacement(): string {
    return this.forgotPasswordPlacement;
  }

  @action
  public setSetPasswordPlacement(placement: string) {
    this.setPasswordPlacement = placement;
  }

  @action
  public getSetPasswordPlacement(): string {
    return this.setPasswordPlacement;
  }

  @action
  public getSignUpPlacement(): string {
    return this.signUpPlacement;
  }

  @action
  public setSignUpPlacement(placement: string) {
    this.signUpPlacement = placement;
  }

  @action
  public getBiometricChallengePlacement(): string {
    return this.biometricChallenge;
  }

  @action
  public setBiometricChallengePlacement(placement: string) {
    this.biometricChallenge = placement;
  }

  @action
  public getLoginPlacement(): string {
    return this.login;
  }

  @action
  public setLoginPlacement(placement: string) {
    this.login = placement;
  }

  @action
  public getVerifyOtpSigninPlacement(): string {
    return this.verifyOtpSigninPlacement;
  }
  @action
  public getVerifyOtpChangeEmailPlacement(): string {
    return this.verifyOtpChangeEmailPlacement;
  }

  @action
  public setVerifyOtpSigninPlacement(placement: string) {
    this.verifyOtpSigninPlacement = placement;
  }

  @action
  public getVerifyOtpSignupPlacement(): string {
    return this.verifyOtpSignupPlacement;
  }

  @action
  public setVerifyOtpSignupPlacement(placement: string) {
    this.verifyOtpSignupPlacement = placement;
  }

  @action
  public getVrboLoginPlacement(): string {
    return this.vrboLogin;
  }

  @action
  public setVrboLoginPlacement(placement: string) {
    this.vrboLogin = placement;
  }

  @action
  public getAddPasswordPlacement(): string {
    return this.addPasswordPlacement;
  }

  @action
  public setAddPasswordPlacement(placement: string) {
    this.addPasswordPlacement = placement;
  }

  @action
  public getAccountInfoPlacement(): string {
    return this.accountInfoPlacement;
  }

  @action
  public setAccountInfoPlacement(placement: string) {
    this.accountInfoPlacement = placement;
  }

  @action
  public getWaitBeforeRedirectingUp(): number {
    return this.waitBeforeRedirectingUp;
  }

  @action
  public setWaitBeforeRedirectingUp(waitBeforeRedirectingUp: number) {
    this.waitBeforeRedirectingUp = waitBeforeRedirectingUp;
  }

  @action
  public getLocaleOverrides() {
    return this.localeOverrides;
  }

  @action setLocaleOverrides(newKeyValues: Record<string, string>) {
    this.localeOverrides = newKeyValues;
  }
}
