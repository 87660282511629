// External dependencies
import { zxcvbn } from '@zxcvbn-ts/core';

// Internal dependencies
import { PASSWORD_WITH_STRENGTH_METER_DEFAULT_VALUES, PASSWORD_WITH_STRENGTH_METER_DICTIONARY } from '../constants';
import { validateCreatePassword } from '../utils';

export const getConsentCheckboxLabel = (consentCheckboxTextType: string) => {
  switch (consentCheckboxTextType) {
    case 'BEXG_OPT_IN_OUT':
      return 'eg.signup.travel.deals.with.opt.out';
    case 'BEXG_OPT_IN':
      return 'eg.signup.travel.deals';
    case 'VRBO_OPT_IN_OUT':
      return 'eg.signup.vacation.deals.with.opt.out';
    case 'VRBO_OPT_IN':
      return 'eg.signup.vacation.deals';
    default:
      return 'eg.signup.travel.deals';
  }
};

export const getMarketingConsentCheckboxLabel = (marketingConsentCheckboxTextType: string) => {
  switch (marketingConsentCheckboxTextType) {
    case 'BEXG_OPT_IN':
      return 'eg.firstnamelastname.form.marketing.checkbox.label';
    case 'BEXG_OPT_IN_OUT':
      return 'eg.firstnamelastname.form.marketing.checkbox.label.with.opt.out';
    case 'HCOM_OPT_IN':
      return 'eg.firstnamelastname.form.marketing.checkbox.label';
    case 'HCOM_OPT_IN_OUT':
      return 'eg.firstnamelastname.form.marketing.checkbox.label.with.opt.out';
    case 'VRBO_OPT_IN':
      return 'eg.firstnamelastname.form.marketing.checkbox.label';
    case 'VRBO_OPT_IN_OUT':
      return 'eg.firstnamelastname.form.marketing.checkbox.label.with.opt.out';
    default:
      return 'eg.firstnamelastname.form.marketing.checkbox.label';
  }
};

export const hidePassword = (form: HTMLFormElement | undefined) => {
  if (!form || form.nodeName !== 'FORM') return;

  const hideShowButton = form.querySelector('button.uitk-password-visibility-button') as HTMLButtonElement;
  const hideShowLabel = form.querySelector('span.is-visually-hidden') as HTMLSpanElement;
  if (hideShowLabel && hideShowLabel.textContent === 'Hide password') hideShowButton.click();
};

export const passwordWithRulesMeterValidate = (password: string) => {
  const { lengthRule, lettersAndNumbersRule, specialCharRule } = validateCreatePassword(password);
  const isValid = lengthRule && lettersAndNumbersRule && specialCharRule;
  return { isValid };
};

export const getPasswordWithStrengthMeterDescription = (score: number) => {
  switch (score) {
    case 3:
      return 'eg.signup.form.passwordStrength.strong';
    case 4:
      return 'eg.signup.form.passwordStrength.veryStrong';
    default:
      return 'eg.signup.form.passwordStrength.weak';
  }
};

export const getPasswordWithStrengthMeterSuggestions = (suggestionData: string[], password: string): string[] => {
  const suggestions = [];

  for (const suggestion of suggestionData.slice(0, 3)) {
    suggestions.push(PASSWORD_WITH_STRENGTH_METER_DICTIONARY[suggestion]);
  }

  const { lengthRule, lettersAndNumbersRule, specialCharRule, noSpaceRule } = validateCreatePassword(password);

  const customSuggestions = [];
  const maxSuggestionsLength = 3;
  if (!(lengthRule && lettersAndNumbersRule && specialCharRule && noSpaceRule)) {
    if (!lengthRule) {
      const suggestionForRule1 = 'eg.signup.form.password.rule.1';
      customSuggestions.push(suggestionForRule1);
    }
    if (!lettersAndNumbersRule) {
      const suggestionForRule2 = 'eg.signup.form.password.rule.2';
      customSuggestions.push(suggestionForRule2);
    }
    if (!specialCharRule) {
      const suggestionForRule3 = 'eg.signup.form.password.rule.3';
      customSuggestions.push(suggestionForRule3);
    }
    if (!noSpaceRule) {
      const suggestionForRule4 = 'eg.signup.form.password.rule.4';
      customSuggestions.push(suggestionForRule4);
    }
    // slice() is used here to keep the maximum suggestions to 3
    const ruleSuggestions = customSuggestions.slice(0, 3);
    return [...ruleSuggestions, ...suggestions.slice(0, maxSuggestionsLength - ruleSuggestions.length)];
  } else {
    return suggestions;
  }
};

export const passwordWithStrengthMeterValidate = (password: string) => {
  if (password === '') return PASSWORD_WITH_STRENGTH_METER_DEFAULT_VALUES;

  const zxcvbnResult = zxcvbn(password);

  const {
    feedback: { suggestions },
    score: passwordScore,
  } = zxcvbnResult;

  const passwordDescription = getPasswordWithStrengthMeterDescription(passwordScore);
  const passwordSuggestions = getPasswordWithStrengthMeterSuggestions(suggestions, password);
  const isValid = passwordScore > 2 && passwordSuggestions.length === 0;

  return { passwordScore, passwordDescription, passwordSuggestions, isValid };
};
