// External dependencies
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEvent, SystemEventLevel } from 'bernie-logger';
import { observable } from 'mobx';
import { callAxios } from 'src/utils';
import { Context } from 'bernie-context';

export const defaults = { inviteEmail: '', hasValidInvite: false, inviteRedirectUrl: '' };

export type InviteClientId = 'eg-platform-console' | 'advertiser-pwa';
export type InviteConfig = {
  clients: Record<InviteClientId, string>;
  redeemUrl: string;
  verifyUrl: string;
};

export class InviteStore extends Store {
  @observable public inviteEmail: string;
  @observable public hasValidInvite: boolean;
  @observable public inviteRedirectUrl: string;

  config: InviteConfig | Record<string, string>;
  verifyUrl: string;
  redeemUrl: string;

  constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    this.hasValidInvite = defaults.hasValidInvite;
    this.inviteEmail = defaults.inviteEmail;
    this.inviteRedirectUrl = defaults.inviteRedirectUrl;

    this.config = state;
  }

  public hydrate(data: SerializedData) {
    Object.assign(this, data);
  }

  public async verifyInviteCode(
    context: Context,
    code: string,
    partnerAccountId: string,
    clientId = 'eg-platform-console'
  ) {
    const inviteHost = this.config.clients[clientId]?.host;
    if (code && partnerAccountId && inviteHost) {
      const verifyUrl = `${inviteHost}${this.config.verifyUrl}`;
      const redeemUrl = `${inviteHost}${this.config.redeemUrl}`;

      const {
        hasError,
        code: status,
        traceId,
        email,
      } = await callAxios(verifyUrl, 'GET', context, null, {
        code,
        partnerAccountId,
        clientId,
      });

      if (hasError || !email) {
        const maskedEmail = (email || '').replace(/./g, '*');
        this.logger.logEvent(
          new SystemEvent(SystemEventLevel.INFO, 'inviteStore, verifyInviteCode'),
          `Error Response ${verifyUrl}, response_status=${status} traceId=${traceId} email:${maskedEmail} `
        );
        return;
      }

      const searchParams = new URLSearchParams({
        code,
        partnerAccountId,
      }).toString();
      this.inviteEmail = email;
      this.hasValidInvite = true;
      this.inviteRedirectUrl = `${redeemUrl}?${searchParams}`;
    }
  }
}
