export const TELL_US_WHY_ORDERED_CHECKBOX_LIST = [
  {
    checkboxInputName: 'deleteAccountTooManyEmailsCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.too.many.emails',
  },
  {
    checkboxInputName: 'deleteAccountHaveOtherAccount',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.another.account',
  },
  {
    checkboxInputName: 'deleteAccountTripIsOverCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.trip.over',
  },
  {
    checkboxInputName: 'deleteAccountNegativeBookingExperienceCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.negative.experience',
  },
  {
    checkboxInputName: 'deleteAccountReasonOtherCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.reason.other',
  },
];

export const TELL_US_WHY_OI_ORDERED_CHECKBOX_LIST = [
  {
    checkboxInputName: 'deleteAccountTooManyEmailsCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.too.many.emails',
  },
  {
    checkboxInputName: 'deleteAccountHaveOtherAccount',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.another.account.oneid',
  },
  {
    checkboxInputName: 'deleteAccountTripIsOverCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.trip.over',
  },
  {
    checkboxInputName: 'deleteAccountNegativeBookingExperienceCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.negative.experience.oneid',
  },
  {
    checkboxInputName: 'deleteAccountReasonOtherCheckbox',
    checkboxInputCustomLabel: 'eg.delete.account.why.leaving.reason.other',
  },
];
