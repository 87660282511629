// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';

// Internal dependencies
import { CaptchaServiceStore, ConfigServiceStore } from 'src/stores';
import { ExtendedContextStore } from 'types';
import { ForgotPasswordLogEvents } from 'loggers';

export class ForgotPasswordController implements Controller {
  public pageId = 'ForgotPassword';
  public path = '/:locale?/forgotpassword';
  public routeName = 'forgotpassword';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/forgot-password-page/forgot-password-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('ForgotPasswordController.fetch received invalid FetchOptions');
    }

    const {
      isServer,
      stores,
      query: { oldAto },
      logger,
    } = options;

    logger.logEvent(ForgotPasswordLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const captchaStore: CaptchaServiceStore = stores.get<CaptchaServiceStore>('captchaStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');

    logger.logEvent(ForgotPasswordLogEvents.storesCreated);

    const placement = configStore.getForgotPasswordPlacement();

    logger.logEvent(ForgotPasswordLogEvents.placementLogger(placement));
    logger.logEvent(ForgotPasswordLogEvents.captchaStart);

    if (isServer) {
      await captchaStore
        .getCaptcha(context, oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(ForgotPasswordLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(ForgotPasswordLogEvents.captchaLoadedError(error.message));
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.forgotpassword.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
