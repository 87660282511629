// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { EN_US_LOCALE } from 'src/constants';

export class LoginDefaultErrorController implements Controller {
  public pageId = 'LoginDefaultError';
  public path = '/:locale?/login/error';
  public routeName = 'logindefaulterror';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('views/default-error-page/default-error-page.view'));
  public exact = true;

  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('LoginDefaultErrorController.fetch received invalid FetchOptions');
    }
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const formattedTitle = localization.formatText('eg.login.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
