// External dependencies
import { MatchExtended, Matcher, Match } from '@zxcvbn-ts/core/dist/types';
// Internal dependencies
import { validateCreatePassword } from '../utils';

//Length Rule
export const lengthMatcher: Matcher = {
  Matching: class MatchLength {
    match({ password }: { password: string }) {
      const matches: Match[] = [];
      const { lengthRule } = validateCreatePassword(password);
      if (!lengthRule) {
        matches.push({
          pattern: 'length',
          token: password,
          i: 0,
          j: password.length - 1,
        });
      }
      return matches;
    }
  },
  feedback() {
    return {
      warning: '',
      suggestions: [],
    };
  },
  scoring(match: MatchExtended) {
    return match.token.length * 10;
  },
};

// Letters & Numbers Rule
export const lettersAndNumbersMatcher: Matcher = {
  Matching: class MatchLettersAndNumbers {
    match({ password }: { password: string }) {
      const matches: Match[] = [];
      const { lettersAndNumbersRule } = validateCreatePassword(password);
      if (!lettersAndNumbersRule) {
        matches.push({
          pattern: 'lettersAndNumbers',
          token: password,
          i: 0,
          j: password.length - 1,
        });
      }
      return matches;
    }
  },
  feedback() {
    return {
      warning: '',
      suggestions: [],
    };
  },
  scoring(match: MatchExtended) {
    return match.token.length * 10;
  },
};

// Special Character Rule
export const specialCharMatcher: Matcher = {
  Matching: class MatchSpecialCharRule {
    match({ password }: { password: string }) {
      const matches: Match[] = [];
      const { specialCharRule } = validateCreatePassword(password);
      if (!specialCharRule) {
        matches.push({
          pattern: 'specialChar',
          token: password,
          i: 0,
          j: password.length - 1,
        });
      }
      return matches;
    }
  },
  feedback() {
    return {
      warning: '',
      suggestions: [],
    };
  },
  scoring(match: MatchExtended) {
    return match.token.length * 10;
  },
};

// No Space Rule
export const noSpaceMatcher: Matcher = {
  Matching: class MatchNoSpace {
    match({ password }: { password: string }) {
      const matches: Match[] = [];
      const { noSpaceRule } = validateCreatePassword(password);
      if (!noSpaceRule) {
        matches.push({
          pattern: 'noSpace',
          token: password,
          i: 0,
          j: password.length - 1,
        });
      }
      return matches;
    }
  },
  feedback() {
    return {
      warning: '',
      suggestions: [],
    };
  },
  scoring(match: MatchExtended) {
    return match.token.length * 10;
  },
};
