import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEvent, SystemEventLevel } from 'bernie-logger';
import { action } from 'mobx';
import { SerializedData } from 'bernie-core';
import jwtDecode from 'jwt-decode';
import { Context } from 'bernie-context';

import { callFetch } from 'src/utils';
import { CreditCardInfoResponse } from 'src/types';

export class OneKeyCreditCardStore extends Store {
  egCcobServiceUrl: string;

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    if (state.egCcobService) {
      this.egCcobServiceUrl = state.egCcobService.url;
    }
  }

  public hydrate(data: SerializedData) {
    delete data.egCcobService;
    Object.assign(this, data);
  }

  @action
  public async getCreditCardInfo(session: string, context: Context) {
    try {
      const { traceInfo } = context;
      const extraHeaders = {
        'trace-id': traceInfo?.['Trace-ID'] || '',
      };
      const decodedSession = jwtDecode(session);
      const response = (await callFetch(this.egCcobServiceUrl, 'GET', context, extraHeaders, {
        eg_user_id: decodedSession.sub,
      })) as CreditCardInfoResponse;

      if (!response.hasError && response.card_holder) {
        this.logger.logEvent(
          new SystemEvent(SystemEventLevel.INFO, 'oneKeyCreditCardStore'),
          `CreditCardInfoResponse response=${response.credit_card_type} card_holder=${response.card_holder}`
        );
        return response.credit_card_type;
      }

      this.logger.logEvent(
        new SystemEvent(SystemEventLevel.INFO, 'oneKeyCreditCardStore'),
        `CreditCardInfoResponse failed response=${response.hasError} code=${response.code}`
      );
      return null;
    } catch (error) {
      this.logger.logEvent(
        new SystemEvent(SystemEventLevel.INFO, 'oneKeyCreditCardStore'),
        `Unexpected error on oneKeyCreditCardStore ${error}`
      );
      return null;
    }
  }
}
