import { CHANGE_PASSWORD_PAGE, CREATE_PASSWORD_PAGE, SET_PASSWORD_PAGE } from './useTrackEvents.constants';

export const ADD_PASSWORD_SCREEN_VARIANT_MAP = {
  3: 'CREATE_PASSWORD_WITH_MARKETING_CONSENT',
  PASSWORD_0: 'SET_PASSWORD_WITH_MARKETING_CONSENT',
  PASSWORD_1: 'CREATE_PASSWORD_WITH_MARKETING_CONSENT',
  PASSWORD_2: 'SET_PASSWORD_WITHOUT_MARKETING_CONSENT',
  PASSWORD_3: 'CREATE_PASSWORD_WITHOUT_MARKETING_CONSENT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const ADD_PASSWORD_SCENARIO_CONFIG = {
  SET_PASSWORD_WITH_MARKETING_CONSENT: {
    title: 'eg.one.key.set.new.password.page.title.with.brand',
    heading: 'eg.one.key.set.new.password.page.heading',
    subheading: 'eg.one.key.set.new.password.page.subheading',
    submitButton: 'eg.one.key.set.new.password.page.submit.button',
    showMarketingConsent: true,
    showKoreaTnC: false,
    pageName: SET_PASSWORD_PAGE,
  },
  CREATE_PASSWORD_WITH_MARKETING_CONSENT: {
    title: 'eg.one.key.create.password.page.title.with.brand',
    heading: 'eg.one.key.create.password.page.heading',
    subheading: 'eg.one.key.otp.create.password.page.subheading',
    subheadingOI: 'eg.one.key.create.password.page.subheading',
    submitButton: 'eg.one.key.create.password.page.submit.button',
    showMarketingConsent: true,
    showKoreaTnC: true,
    pageName: CREATE_PASSWORD_PAGE,
  },
  SET_PASSWORD_WITHOUT_MARKETING_CONSENT: {
    title: 'eg.one.key.set.new.password.page.title.with.brand',
    heading: 'eg.one.key.set.new.password.page.heading',
    subheading: 'eg.one.key.set.new.password.page.subheading',
    submitButton: 'eg.one.key.set.new.password.page.submit.button',
    showMarketingConsent: false,
    showKoreaTnC: false,
    pageName: SET_PASSWORD_PAGE,
  },
  CREATE_PASSWORD_WITHOUT_MARKETING_CONSENT: {
    title: 'eg.one.key.create.password.page.title.with.brand',
    heading: 'eg.one.key.create.password.page.heading',
    subheading: 'eg.one.key.otp.create.password.page.subheading',
    subheadingOI: 'eg.one.key.create.password.page.subheading',
    submitButton: 'eg.one.key.create.password.page.submit.button',
    showMarketingConsent: false,
    showKoreaTnC: true,
    pageName: CREATE_PASSWORD_PAGE,
  },
  CHANGE_PASSWORD: {
    title: 'eg.update.password.page.title.with.brand',
    heading: 'eg.update.password.page.title',
    subheading: 'eg.update.password.page.subheading',
    submitButton: 'eg.update.password.page.submit.button',
    showMarketingConsent: false,
    showKoreaTnC: false,
    pageName: CHANGE_PASSWORD_PAGE,
  },
};
