// External dependencies
import { Context } from 'bernie-context';
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';

// Internal dependencies
import { action, observable } from 'mobx';
import { callFetch } from 'src/utils';
import { Headers } from 'src/types';

export class CaptchaServiceStore extends Store {
  captchaServiceUrlOld: string;
  captchaServiceUrlNew: string;
  forwardedHeaders: Headers;

  @observable captchaHtml: string;
  @observable captchaBiometricHtml: string;
  @observable verifyOtpSigninCaptchaHtml: string;
  @observable verifyOtpSignupCaptchaHtml: string;

  public constructor(
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forwardedHeaders?: Headers
  ) {
    super(state, logger);
    if (state) {
      this.captchaServiceUrlOld = state.oldUrl;
      this.captchaServiceUrlNew = state.newUrl;
    }
  }

  public hydrate(data: SerializedData) {
    delete data.captchaData;
    Object.assign(this, data);
  }

  @action
  public setCaptchaScriptOnPage(script: string) {
    this.captchaHtml = script;
  }

  @action
  public setCaptchaBiometricScriptOnPage(script: string) {
    this.captchaBiometricHtml = script;
  }

  @action
  public setVerifyOtpSigninCaptchaScriptOnPage(script: string) {
    this.verifyOtpSigninCaptchaHtml = script;
  }

  @action
  public setVerifyOtpSignupCaptchaScriptOnPage(script: string) {
    this.verifyOtpSignupCaptchaHtml = script;
  }

  @action
  public async getCaptcha(context: Context, useOldUrl: boolean, placement, oldCaptchaUrl, newCaptchaUrl) {
    const brand = context.site.brand;
    const url = useOldUrl
      ? `${this.captchaServiceUrlOld}widget/${brand}/${placement}`
      : `${this.captchaServiceUrlNew}widget/${brand}/${placement}`;
    const additionalHeadersForCaptcha = Object.assign({}, this.forwardedHeaders || {}, {
      clientId: 'eg-auth-ui-v2',
    });
    const requestParams = {
      'language-code': context.locale,
    };

    // TODO: This code will be commented to verify if it's needed
    /* if (this.host) {
      context['host'] = this.host;
    } */

    this.logger.logEvent(
      { level: SystemEventLevel.INFO, name: 'eg-auth-ui' },
      `Captcha Url=${url} brand=${brand} headers=${additionalHeadersForCaptcha} requestParams=${requestParams} context=${context}`
    );

    try {
      const data = await callFetch(url, 'GET', context, additionalHeadersForCaptcha, requestParams);

      if (data && !data.errors) {
        this.logger.logEvent({ level: SystemEventLevel.INFO, name: 'eg-auth-ui' }, 'Captcha api return successfully');
        return data;
      } else {
        this.logger.logEvent(
          { level: SystemEventLevel.ERROR, name: 'eg-auth-ui' },
          `Captcha api return with error= ${data.errors}`
        );
        return {
          ...data,
          hasError: true,
        };
      }
    } catch (error) {
      this.logger.logEvent(
        { level: SystemEventLevel.ERROR, name: 'eg-auth-ui' },
        `Unexpected captcha error during api call error=${error.message}`
      );
      // recover and pass object with error details
      return {
        hasError: true,
        errors: [
          {
            errorCode: 'default',
          },
        ],
      };
    }
  }
}
