// External dependencies

// Internal dependencies
import { CheckboxInputData, EmailInputData, PasswordInputsData, SelectData, TextInputsData } from 'src/types';

export const CHECKBOX_INPUTS_DATA: CheckboxInputData = {
  rememberMeSignUpCheckbox: {
    className: '',
    id: 'rememberMeSignUpCheckbox',
    label: 'eg.user.keep.signed.in.label',
  },
  rememberMeSignInCheckbox: {
    className: '',
    id: 'rememberMeSignInCheckbox',
    label: 'eg.user.keep.signed.in.label',
  },
  publicDeviceSignUpCheckbox: {
    className: '',
    id: 'publicDeviceSignUpCheckbox',
    label: 'eg.user.public.shared.device.label',
  },
  promotionalMailSignUpCheckbox: {
    className: '',
    id: 'promotionalMailSignUpCheckbox',
    label: 'eg.signup.travel.deals',
  },
  phoneSignUpCheckbox: {
    className: '',
    id: 'phoneSignUpCheckbox',
    // TODO: Hardcoded line
    label: 'Use my mobile number to sign in',
  },
  deleteAccountTooManyEmailsCheckbox: {
    className: '',
    id: 'tooManyEmailsCheckbox',
    label: 'eg.delete.account.why.leaving.too.many.emails',
  },
  deleteAccountHaveOtherAccount: {
    className: '',
    id: 'haveOtherAccount',
    label: 'eg.delete.account.why.leaving.another.account',
  },

  deleteAccountTripIsOverCheckbox: {
    className: '',
    id: 'tripIsOverCheckbox',
    label: 'eg.delete.account.why.leaving.trip.over',
  },

  deleteAccountNegativeBookingExperienceCheckbox: {
    className: '',
    id: 'negativeBookingExperienceCheckbox',
    label: 'eg.delete.account.why.leaving.negative.experience',
  },

  deleteAccountReasonOtherCheckbox: {
    className: '',
    id: 'reasonOtherCheckbox',
    label: 'eg.delete.account.why.leaving.reason.other',
  },
};

export const EMAIL_INPUTS_DATA: EmailInputData = {
  signupFormEmailInput: {
    className: '',
    id: 'signupFormEmailInput',
    label: 'eg.signup.form.email.label',
    missingError: 'eg.signup.form.missing.email.label',
    invalidError: 'eg.signup.form.invalid.email.label',
  },
  emailSignInInput: {
    className: '',
    id: 'emailSignInInput',
    label: 'eg.user.email.label',
    missingError: 'eg.user.email.missing.label',
    invalidError: 'eg.user.email.invalid.label',
  },
  emailLoginInput: {
    className: '',
    id: 'emailLoginInput',
    label: 'eg.user.email.label',
    missingError: 'eg.signup.form.missing.email.label',
    invalidError: 'eg.signup.form.invalid.email.label',
  },
  emailSelectProviderInput: {
    className: '',
    id: 'emailSelectProviderInput',
    label: 'eg.user.email.label',
    missingError: 'eg.user.email.missing.label',
    invalidError: 'eg.user.email.invalid.label',
  },
  loginFormEmailInput: {
    className: 'replay-reveal',
    id: 'loginFormEmailInput',
    label: 'eg.one.key.login.page.email.textbox.placeholder',
    missingError: 'eg.user.email.missing.label',
    invalidError: 'eg.user.email.invalid.label',
  },
  forgotPasswordEmailInput: {
    className: '',
    id: 'forgotPasswordEmailInput',
    label: 'eg.user.email.label',
    missingError: 'eg.user.email.missing.label',
    invalidError: 'eg.user.email.invalid.label',
  },
};

export const PASSWORD_INPUTS_DATA: PasswordInputsData = {
  passwordSingUpInput: {
    className: '',
    id: 'signupFormPasswordInput',
    label: 'eg.signup.form.password.label',
    missingError: 'eg.signup.form.missing.password.label',
  },
  passwordSignInInput: {
    className: '',
    id: 'passwordSignInInput',
    label: 'eg.user.password.label',
    missingError: 'eg.user.password.missing.label',
  },
  passwordAddInput: {
    className: '',
    id: 'passwordAddInput',
    label: 'eg.user.password.label',
    missingError: 'eg.user.password.missing.label',
  },
};

export const PASSWORD_WITH_STRENGTH_METER_DEFAULT_VALUES = {
  passwordScore: 0,
  passwordDescription: '',
  passwordSuggestions: [],
  isValid: false,
};

export const PASSWORD_WITH_STRENGTH_METER_DICTIONARY = {
  l33t: 'eg.signup.form.suggestion.letterSub',
  reverseWords: 'eg.signup.form.suggestion.reverse',
  allUppercase: 'eg.signup.form.suggestion.capitalizeSome',
  capitalization: 'eg.signup.form.suggestion.capitalizeMore',
  dates: 'eg.signup.form.suggestion.dates',
  recentYears: 'eg.signup.form.suggestion.recentYears',
  associatedYears: 'eg.signup.form.suggestion.years',
  sequences: 'eg.signup.form.suggestion.sequence',
  repeated: 'eg.signup.form.suggestion.repeat',
  longerKeyboardPattern: 'eg.signup.form.suggestion.longPatterns',
  anotherWord: 'eg.signup.form.suggestion.lessCommon',
  useWords: 'eg.signup.form.suggestion.multiple',
};

export const COUNTRY_CODE_PHONE_INPUT_SELECT_NAME = 'countryCodePhoneInputSelect';

export const SELECTS_DATA: SelectData = {
  countryCodePhoneInputSelect: {
    className: '',
    id: COUNTRY_CODE_PHONE_INPUT_SELECT_NAME,
    label: 'Country',
  },
};

export const PHONE_NUMBER_PHONE_INPUT_NAME = 'phoneNumberPhoneInputName';

export const TEXT_INPUTS_DATA: TextInputsData = {
  firstNameSignUp: {
    className: '',
    id: 'signupFormFirstNameInput',
    label: 'eg.signup.form.firstname.label',
    missingError: 'eg.signup.form.missing.firstname.label',
    maxLengthError: 'eg.signup.form.firstname.max.length.error',
    specialCharacterError: 'eg.signup.form.firstname.special.char.error',
  },
  lastNameSignUp: {
    className: '',
    id: 'signupFormLastNameInput',
    label: 'eg.signup.form.lastname.label',
    missingError: 'eg.signup.form.missing.lastname.label',
    maxLengthError: 'eg.signup.form.lastname.max.length.error',
    specialCharacterError: 'eg.signup.form.lastname.special.char.error',
  },
  phoneNumberPhoneInputName: {
    className: '',
    id: PHONE_NUMBER_PHONE_INPUT_NAME,
    label: 'Mobile number',
    missingError: 'Enter a mobile number',
    maxLengthError: '',
    specialCharacterError: '',
  },
};
