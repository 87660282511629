import { codeSplit, Controller } from 'bernie-core';
import { PageData } from 'bernie-http';

export class OIDCAuthenticateController implements Controller {
  public pageId = 'OIDCAuthenticate';
  public path = '/user/oidc/authenticate';
  public routeName = 'oidcauthenticate';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('views/oidc-authenticate/oidc-authenticate.view'));

  public exact = true;

  public async fetch() {
    return Promise.resolve();
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({ seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
