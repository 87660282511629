import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';
import { EN_US_LOCALE } from 'src/constants';
import { ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { ExtendedContextStore } from 'src/types';
import { SelectProviderEvents } from 'src/loggers';

export class SelectProviderController implements Controller {
  public pageId = 'SelectProvider';
  public path = '/oidc/provider';
  public routeName = 'provider';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/select-provider-page/select-provider-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('SelectProviderController.fetch received invalid FetchOptions');
    }

    const { stores, logger, isServer } = options;
    const contextStore = stores.get<ExtendedContextStore>('context');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');

    logger.logEvent(SelectProviderEvents.startLog);

    if (isServer) {
      await requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(SelectProviderEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(SelectProviderEvents.requestContextError(error.message));
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const formattedTitle = localization.formatText('eg.selectprovider.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
