// External dependencies
import { Context } from 'bernie-context';
import { v4 as UUIDv4 } from 'uuid';
import axios from 'axios';

// Internal dependencies
import { XSS_PROTECTION_HEADER } from '../constants';
import { Headers, HttpRequestMethodType } from '../types';

export async function callAxios(
  url: string,
  methodType: HttpRequestMethodType,
  context: Context,
  additionalHeaders?: Headers,
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  },
  requestBody?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
) {
  // @ts-ignore asd
  let traceId = context.traceInfo ? context.traceInfo['Trace-ID'] : '';
  const headers = Object.assign({}, additionalHeaders || {}, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-MC1-GUID': context.deviceId,
    //'X-REMOTE-ADDR': context.remoteAddress,
    //'X-USER-AGENT': context.userAgent,
    'Device-User-Agent-Id': context.deviceId,
    'Trace-Id': traceId,
    'X-B3-TraceId': UUIDv4(),
    'X-XSS-Protection': XSS_PROTECTION_HEADER,
    ...(context.deviceInformation?.type && { 'Device-Type': context.deviceInformation.type }),
  });

  const query: string[] = [];
  for (const pk in data) {
    if ({}.hasOwnProperty.call(data, pk)) {
      const pkValue = data[pk];
      if (pkValue) {
        query.push(`${encodeURIComponent(pk)}=${encodeURIComponent(pkValue)}`);
      }
    }
  }
  const queryString = query.join('&') ? `?${query.join('&')}` : '';
  const urlWithQuery = url + queryString;

  const config = {
    method: methodType,
    url: urlWithQuery,
    headers: headers,
    withCredentials: true,
    data: requestBody, // payload
  };

  let response;
  try {
    response = await axios(config);
    traceId = response.headers['Trace-Id'];
    return { hasError: !response.statusText, code: response.status, ...response.data, traceId };
  } catch (e) {
    return { hasError: !e.response.statusText, code: e.response.status, ...e, traceId };
  }
}
