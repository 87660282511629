import { CsrfData } from 'src/types';
import { getAtoShieldPayload } from 'src/utils';

export const deleteAccountPayload = (csrfData: CsrfData, isOneIdentityOnboardedUser: boolean) => {
  const authSvcEndpoint = `/eg-auth-svcs/v1/delete-account`;
  const orchestratorEndpoint = `/identity/v1/user/account/forget`;
  const apiEndpoint = isOneIdentityOnboardedUser ? orchestratorEndpoint : authSvcEndpoint;
  const payload = getAtoShieldPayload({ csrfData, formData: null, OI_FLOW: isOneIdentityOnboardedUser });

  return {
    payload,
    apiEndpoint,
  };
};
