// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions, Result } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';

// Internal dependencies
import { AuthDataStore, CaptchaServiceStore, ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { ExtendedContextStore } from 'types';
import { VerifyOtpLogEvents } from 'loggers';
import { isProfileUpdateFlow, isChangeEmailFlow } from 'src/utils';

export class VerifyOtpController implements Controller {
  public pageId = 'VerifyOTP';
  public path = '/:locale?/verifyotp';
  public routeName = 'verifyotp';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/verify-otp-page/verify-otp-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions): Promise<Result[]> {
    if (!options || !options.stores) {
      return Promise.reject('VerifyOtpController.fetch received invalid FetchOptions');
    }

    const {
      isServer,
      stores,
      query: { oldAto, path },
      logger,
    } = options;

    let verifyOtpSigninCaptchaWidget, verifyOtpSignupCaptchaWidget, pageRequestContext, emailOtpToSessionUser;

    logger.logEvent(VerifyOtpLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const captchaStore: CaptchaServiceStore = stores.get<CaptchaServiceStore>('captchaStore');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const authDataStore = stores.get<AuthDataStore>('authDataStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');

    logger.logEvent(VerifyOtpLogEvents.storesCreated);
    logger.logEvent(VerifyOtpLogEvents.captchaStart);

    if (isServer) {
      const verifyOtpPlacement = isChangeEmailFlow(path)
        ? configStore.getVerifyOtpChangeEmailPlacement()
        : configStore.getVerifyOtpSigninPlacement();
      logger.logEvent(VerifyOtpLogEvents.placementLogger(verifyOtpPlacement));
      verifyOtpSigninCaptchaWidget = captchaStore
        .getCaptcha(context, oldAto, verifyOtpPlacement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setVerifyOtpSigninCaptchaScriptOnPage(data.widget);
            logger.logEvent(VerifyOtpLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(VerifyOtpLogEvents.captchaLoadedError(error.message));
        });

      logger.logEvent(VerifyOtpLogEvents.placementLogger(configStore.getVerifyOtpSignupPlacement()));
      verifyOtpSignupCaptchaWidget = captchaStore
        .getCaptcha(
          context,
          oldAto,
          configStore.getVerifyOtpSignupPlacement(),
          configStore.oldCaptchaUrl,
          configStore.newCaptchaUrl
        )
        .then((data) => {
          if (data.widget) {
            captchaStore.setVerifyOtpSignupCaptchaScriptOnPage(data.widget);
            logger.logEvent(VerifyOtpLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(VerifyOtpLogEvents.captchaLoadedError(error.message));
        });

      logger.logEvent(VerifyOtpLogEvents.requestContextStart);

      pageRequestContext = requestContextStore
        .getRequestContext(
          context,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(VerifyOtpLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(VerifyOtpLogEvents.requestContextError(error.message));
        });

      if (isProfileUpdateFlow(path)) {
        emailOtpToSessionUser = authDataStore
          .sendOtpAndGetUserEmailFromSession(
            context,
            configStore.emailOtpToSessionUserHost,
            configStore.emailOtpToSessionUserUrl
          )
          .catch((error) => {
            logger.logEvent(VerifyOtpLogEvents.emailOtpToSessionUserError(error.message));
          });
      }
    }
    return await Promise.all([
      emailOtpToSessionUser,
      verifyOtpSigninCaptchaWidget,
      verifyOtpSignupCaptchaWidget,
      pageRequestContext,
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.login.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
