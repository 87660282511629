import { getAbsoluteRedirectUrl, isHcomCheckoutRedirectUrl } from './redirect-urls';
import { AuthenticationStatus, FlowIdentifierType, OTP_FLOW_TYPE, PageRedirectDataType, ScenarioType } from '../types';
import {
  AUTHENTICATION_STATUS,
  FLOW_IDENTIFIER_MAP,
  HOME_REDIRECT_URL,
  MULTI_ACCOUNT_SIGNUP_FLOW_IDENTIFIER_LIST,
  OTP_FLOW_CONFIG,
  OTP_PATH,
  OTP_SCREEN_IDENTIFIER_MAP,
  SCENARIO,
  SIGNUP_FLOW_IDENTIFIER_LIST,
} from '../constants';
import {
  enableSmsOtp,
  isAccountMergeSharedUiEnabled,
  isLoginWithSmsOtpEnabled,
  isTagCommanderFlowEnabled,
} from 'utils/experiment.util';

export const evalOtpFlow = ({ flowIdentifier, path, signupExperiment }): OTP_FLOW_TYPE => {
  if (path === OTP_PATH.EMAIL) {
    if (signupExperiment && flowIdentifier) {
      return OTP_SCREEN_IDENTIFIER_MAP[flowIdentifier];
    } else {
      return 'OLD_OTP_PAGE';
    }
  } else if (path === OTP_PATH.CHANGE_EMAIL) {
    return 'PROFILE_FLOW';
  } else if (path === OTP_PATH.CHANGE_PASSWORD) {
    return 'PROFILE_FLOW';
  }
  return 'OLD_OTP_PAGE';
};

export const getOtpViewConfig = (flowObj) => {
  const otpFlow = evalOtpFlow(flowObj);
  return OTP_FLOW_CONFIG[otpFlow] || OTP_FLOW_CONFIG['OLD_OTP_PAGE'];
};

export const getAccountMergePageUrl = (pageRedirectData) => {
  const { cmsToken, scenario, flowIdentifier, exposures, redirectTo } = pageRedirectData;
  const encodedRedirectTo = encodeURIComponent(redirectTo);
  if (isAccountMergeSharedUiEnabled(exposures)) {
    return `/accountmerge?redirectTo=${encodedRedirectTo}&token=${cmsToken}&scenario=${scenario}&variant=${flowIdentifier}`;
  }
  return `/onboarding?originUrl=${encodedRedirectTo}&cmsToken=${cmsToken}&scenario=${scenario}`;
};

export const generateFlowRedirectUrl = (pageRedirectData: PageRedirectDataType) => {
  const { flowIdentifier, cmsToken, scenario, redirectTo } = pageRedirectData;
  const encodedRedirectTo = encodeURIComponent(redirectTo);
  switch (flowIdentifier) {
    case FLOW_IDENTIFIER_MAP.ONE_KEY_INTERSTITIAL:
    case FLOW_IDENTIFIER_MAP.BETA_CONSENT_NEW_ACCOUNT:
    case FLOW_IDENTIFIER_MAP.BETA_CONSENT_SINGLE_ACCOUNT:
    case FLOW_IDENTIFIER_MAP.BETA_CONSENT_MULTI_ACCOUNT:
      return `/onboarding?originUrl=${encodedRedirectTo}&cmsToken=${cmsToken}&scenario=${scenario}`;
    case FLOW_IDENTIFIER_MAP.UPGRADE_MERGE_CONTINUE:
    case FLOW_IDENTIFIER_MAP.UPGRADE_MERGE_CREATE_PASSWORD:
    case FLOW_IDENTIFIER_MAP.UPGRADE_MERGE_SET_PASSWORD:
      return getAccountMergePageUrl(pageRedirectData);
    case FLOW_IDENTIFIER_MAP.CREATE_PASSWORD_LEGACY:
    case FLOW_IDENTIFIER_MAP.CREATE_PASSWORD_WITH_MARKETING_CONSENT:
    case FLOW_IDENTIFIER_MAP.CREATE_PASSWORD_WITHOUT_MARKETING_CONSENT:
    case FLOW_IDENTIFIER_MAP.SET_PASSWORD_WITH_MARKETING_CONSENT:
    case FLOW_IDENTIFIER_MAP.SET_PASSWORD_WITHOUT_MARKETING_CONSENT:
    case FLOW_IDENTIFIER_MAP.CHANGE_PASSWORD:
      return `/addpassword?redirectTo=${encodedRedirectTo}&token=${cmsToken}&scenario=${scenario}&variant=${flowIdentifier}`;
    case FLOW_IDENTIFIER_MAP.ACCOUNT_INFO_WITH_MARKETING_CONSENT:
    case FLOW_IDENTIFIER_MAP.ACCOUNT_INFO_WITHOUT_MARKETING_CONSENT:
      return `/accountinfo?redirectTo=${encodedRedirectTo}&token=${cmsToken}&scenario=${scenario}&variant=${flowIdentifier}`;
    case FLOW_IDENTIFIER_MAP.MARKETING_CONSENT:
      return `/marketingconsent?redirectTo=${encodedRedirectTo}&token=${cmsToken}&scenario=${scenario}&variant=${flowIdentifier}`;
    case FLOW_IDENTIFIER_MAP.CHANGE_EMAIL: {
      const absoluteUrlToRedirect = getAbsoluteRedirectUrl(redirectTo);
      return getUpdatedRedirectionUrlWithQueryParams(absoluteUrlToRedirect, { token: cmsToken });
    }
    case FLOW_IDENTIFIER_MAP.OI_ORIGIN:
    case FLOW_IDENTIFIER_MAP.ORIGIN:
    default:
      return `${redirectTo}`;
  }
};

export const generateFlowRedirectWithQueryParams = (pageRedirectData: PageRedirectDataType, tagsignup: number) => {
  const { scenario, exposures, flowIdentifier } = pageRedirectData;

  let flowRedirectUrl = generateFlowRedirectUrl(pageRedirectData);
  const isTCEnabled = isTagCommanderFlowEnabled(exposures);
  const signUpFlow = isSignUpFlow(flowIdentifier, scenario);
  if (tagsignup && signUpFlow && isTCEnabled) {
    if (flowRedirectUrl) flowRedirectUrl += '&tagsignup=1';
    else {
      flowRedirectUrl += '?tagsignup=1';
    }
  }
  return flowRedirectUrl;
};

const getUpdatedRedirectionUrlWithQueryParams = (redirectUrl, queryParamMap) => {
  try {
    const redirectionUrl = new URL(redirectUrl);
    for (const key in queryParamMap) {
      if ({}.hasOwnProperty.call(queryParamMap, key)) {
        const value = queryParamMap[key];
        if (value) {
          redirectionUrl.searchParams.append(key, value);
        }
      }
    }
    return `${redirectionUrl.pathname}${redirectionUrl.search}`;
  } catch (e) {
    return HOME_REDIRECT_URL;
  }
};

export const isHcomLoginFromCheckout = (exposures, redirectTo: string) => {
  if (isHcomCheckoutRedirectUrl(redirectTo)) {
    return exposures?.['UL_CKO_Web_Optimization_Phase_1']?.bucket || 0;
  }
  return 0;
};

export const isSignUpFlow = (flowIdentifier: FlowIdentifierType, scenario: ScenarioType) => {
  const signUpScenarios = [SCENARIO.SIGNUP, SCENARIO.CHECKOUT_SIGNUP];
  if (SIGNUP_FLOW_IDENTIFIER_LIST.includes(flowIdentifier) || signUpScenarios.includes(scenario as string)) {
    return true;
  }
  return false;
};

export const isMultiAccountSigninFlow = (flowIdentifier: FlowIdentifierType) => {
  return MULTI_ACCOUNT_SIGNUP_FLOW_IDENTIFIER_LIST.includes(flowIdentifier as string);
};

export const isProfileUpdateFlow = (path) => {
  const profileFlows = [OTP_PATH.CHANGE_PASSWORD, OTP_PATH.CHANGE_EMAIL];
  return profileFlows.includes(path);
};

export const isChangePasswordFlow = (path) => {
  return path === OTP_PATH.CHANGE_PASSWORD;
};

export const isChangeEmailFlow = (path) => path === OTP_PATH.CHANGE_EMAIL;

export const isCheckoutScenario = (scenario: ScenarioType) => {
  return [SCENARIO.CHECKOUT_SIGNIN, SCENARIO.CHECKOUT_SIGNUP].includes(scenario as string);
};

export const isAddPhoneNumberAuthFlow = (
  status: AuthenticationStatus,
  flowIdentifier: FlowIdentifierType,
  scenario: ScenarioType,
  exposures,
  isChangePasswordFlow = false
) => {
  if (isChangePasswordFlow || isCheckoutScenario(scenario)) {
    return false;
  }
  const authenticationComplete =
    flowIdentifier === FLOW_IDENTIFIER_MAP.OI_ORIGIN || flowIdentifier === FLOW_IDENTIFIER_MAP.ONE_KEY_INTERSTITIAL;
  return authenticationComplete && status === AUTHENTICATION_STATUS.SUCCESS && enableSmsOtp(exposures);
};

export const enableLoginWithSmsOtp = (scenario: ScenarioType, exposures) => {
  if (isCheckoutScenario(scenario)) {
    return false;
  }

  return isLoginWithSmsOtpEnabled(exposures);
};

export const getPlacementForVerifyOTP = ({
  flowIdentifier,
  scenarioFromUrl,
  path,
  verifyOtpSigninPlacement,
  verifyOtpSignupPlacement,
  verifyOtpChangePasswordPlacement,
  verifyOtpChangeEmailPlacement,
}) => {
  if (isChangePasswordFlow(path)) {
    return verifyOtpChangePasswordPlacement;
  } else if (isSignUpFlow(flowIdentifier, scenarioFromUrl)) {
    return verifyOtpSignupPlacement;
  } else if (isChangeEmailFlow(path)) {
    return verifyOtpChangeEmailPlacement;
  } else {
    return verifyOtpSigninPlacement;
  }
};

export const getAtoShieldPayload = ({ OI_FLOW, formData, csrfData }) => {
  const trustWidgetPayload = window['trustApi']?.getTrustPayload() || '';
  const atoTokens = formData ? formData : {};
  const { csrfToken, placement } = csrfData;
  const devices = [
    {
      payload: trustWidgetPayload,
      type: 'TRUST_WIDGET',
    },
  ];

  const atoShieldPayload = OI_FLOW
    ? {
        atoShieldData: {
          atoTokens,
          placement,
          csrfToken,
          devices,
        },
      }
    : {
        atoShieldData: {
          atoTokens,
          placement,
        },
        csrfData,
        devices,
      };

  return atoShieldPayload;
};

export const getAtoShieldPayloadForSocial = ({ OI_SOCIAL_FLOW, csrfData }) => {
  const trustWidgetPayload = window['trustApi']?.getTrustPayload() || '';
  const atoTokens = {};
  const { csrfToken, placement } = csrfData;
  const devices = [
    {
      payload: trustWidgetPayload,
      type: 'TRUST_WIDGET',
    },
  ];

  const atoShieldPayload = OI_SOCIAL_FLOW
    ? {
        atoShieldData: {
          atoTokens,
          placement,
          csrfToken,
          devices,
        },
      }
    : {
        csrfData,
      };

  return atoShieldPayload;
};
