// Internal dependencies
import { BRAND_VRBO } from '../../src/constants/constants';

export const getPlacement = (isBucket, brandName, configStore) => {
  let placement;

  if (isBucket && brandName === BRAND_VRBO) {
    placement = configStore.getVrboLoginPlacement();
  } else if (isBucket && brandName !== BRAND_VRBO) {
    placement = configStore.getLoginPlacement();
  } else placement = configStore.getSignUpPlacement();
  return placement;
};
