// External dependencies
import { ClientFactories, Store } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';

// Internal dependencies
import {
  CaptchaServiceStore,
  ConfigServiceStore,
  RequestContextServiceStore,
  SetPasswordStore,
  AuthDataStore,
  UserDeleteAccountDataStore,
  OneIdentityCheckStore,
  InviteStore,
  OneKeyCreditCardStore,
  OAuth2Configuration,
} from 'src/stores';

export const stores: ClientFactories = {
  captchaStore: (): Store => new CaptchaServiceStore({}, NOOP_LOGGER),
  configStore: (): Store => new ConfigServiceStore({}, NOOP_LOGGER),
  requestContextStore: (): Store => new RequestContextServiceStore({}, NOOP_LOGGER),
  setPasswordStore: (): Store => new SetPasswordStore({}, NOOP_LOGGER),
  authDataStore: (): Store => new AuthDataStore({}, NOOP_LOGGER),
  userDeleteAccountStore: (): Store => new UserDeleteAccountDataStore({}, NOOP_LOGGER),
  oneIdentityCheckStore: (): Store => new OneIdentityCheckStore({}, NOOP_LOGGER),
  inviteStore: (): Store => new InviteStore({}, NOOP_LOGGER),
  oneKeyCreditCardStore: (): Store => new OneKeyCreditCardStore({}, NOOP_LOGGER),
  oauth2Configuration: (): Store => new OAuth2Configuration({}, NOOP_LOGGER),
};
