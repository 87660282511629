// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';

// Internal dependencies
import { CaptchaServiceStore, ConfigServiceStore, RequestContextServiceStore, SetPasswordStore } from 'src/stores';
import { SetPasswordLogEvents } from 'loggers';
import { ExtendedContextStore } from 'types';

export class SetPasswordController implements Controller {
  public pageId = 'SetPassword';
  public path = '/:locale?/setpassword';
  public routeName = 'setpassword';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/set-password-page/set-password-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('SetPasswordController.fetch received invalid FetchOptions');
    }

    const { isServer, stores, query, logger } = options;

    logger.logEvent(SetPasswordLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const captchaStore: CaptchaServiceStore = stores.get<CaptchaServiceStore>('captchaStore');
    const setPasswordStore: SetPasswordStore = stores.get<SetPasswordStore>('setPasswordStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    const requestContextStore: RequestContextServiceStore =
      stores.get<RequestContextServiceStore>('requestContextStore');

    let setPwdCaptchaStore, setPwdRequestStore, setPwdVerifyResetPwdLink;

    logger.logEvent(SetPasswordLogEvents.storesCreated);

    const placement = configStore.getSetPasswordPlacement();

    logger.logEvent(SetPasswordLogEvents.placementLogger(placement));
    logger.logEvent(SetPasswordLogEvents.captchaStart);

    if (isServer) {
      setPwdCaptchaStore = captchaStore
        .getCaptcha(context, query.oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(SetPasswordLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(SetPasswordLogEvents.captchaLoadedError(error.message));
        });

      logger.logEvent(SetPasswordLogEvents.requestContextStart);

      setPwdRequestStore = requestContextStore
        .getRequestContext(
          context,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          requestContextStore.setRequestContextDataForPage(data);
          logger.logEvent(SetPasswordLogEvents.requestContextLoaded(JSON.stringify(data)));
        })
        .catch((error) => {
          logger.logEvent(SetPasswordLogEvents.requestContextError(error.message));
        });

      setPwdVerifyResetPwdLink = setPasswordStore
        .verifyResetPasswordLink(context, query, configStore.resetPwdHost, configStore.resetPwdUrl)
        .then((data) => {
          setPasswordStore.setResetPasswordLinkData(data.isExpired, data.emailAddress, query);
          logger.logEvent(SetPasswordLogEvents.verifyLinkResponse(data.isExpired, data.emailAddress));
        })
        .catch((error) => {
          logger.logEvent(SetPasswordLogEvents.verifyLinkError(error.message));
        });
    }

    return await Promise.all([setPwdCaptchaStore, setPwdRequestStore, setPwdVerifyResetPwdLink]);
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.resetpassword.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
