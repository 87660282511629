import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEvent, SystemEventLevel } from 'bernie-logger';
import { action, observable } from 'mobx';
import { EG_SESSIONTOKEN } from 'src/constants';
import { Request } from 'bernie-http';
import axios from 'axios';
import { ExtendedContextStore } from 'src/types';
import jwtDecode from 'jwt-decode';

interface TokenServiceV3Response {
  encodedJwt: string;
}

interface IdentitySearchResponse {
  eg_user_id: string;
  identifier: UserIdentifierResponse;
  legacy_user_ids: LegacyUserIdentifierResponse;
  type: string;
  status: string;
  credential_type: string;
  account_upgrade_status: string;
}

interface UserIdentifierResponse {
  email: string;
  phone: string;
  username: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

interface LegacyUserIdentifierResponse {
  [brand: string]: {
    egaid: string;
    linking_status: string;
    create_date: string;
    account_type: string;
    journey_completed: boolean;
  };
}

export class OneIdentityCheckStore extends Store {
  tokenServiceV3Url: string;
  identitySearchUrl: string;

  @observable isOneIdentityOnboarded: boolean;

  public constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
    if (state.identitySearch && state.tokenServiceV3) {
      this.identitySearchUrl = state.identitySearch.url;
      this.tokenServiceV3Url = state.tokenServiceV3.url;
    }
  }

  public hydrate(data: SerializedData) {
    delete data.tokenServiceV3;
    delete data.identitySearch;
    Object.assign(this, data);
  }

  @action
  public getOneIdentityFlag(): boolean {
    return this.isOneIdentityOnboarded;
  }

  @action
  public setOneIdentityFlag(oneIdentityFlag: boolean) {
    this.isOneIdentityOnboarded = oneIdentityFlag;
  }

  private getCookieLookoutName(): string {
    const sessionLookout = EG_SESSIONTOKEN;
    const concatSession = sessionLookout;
    this.logger.logEvent(
      new SystemEvent(SystemEventLevel.INFO, 'oneIdentityCheckStore'),
      `Cookie name that will be retrieve is=${concatSession}`
    );

    return concatSession;
  }

  private getCookieLookoutHeader(request: Request, cookieName: string): string {
    const cookies = {};
    const cookiesArray = request.headers.cookie.split(';');
    cookiesArray.forEach((cookie) => {
      const [key, value] = cookie.trim().split('=');
      cookies[key] = value;
    });

    this.logger.logEvent(
      new SystemEvent(SystemEventLevel.INFO, 'oneIdentityCheckStore'),
      `${cookieName} retrieved value=${cookies[cookieName]}`
    );

    return cookies[cookieName] ? cookies[cookieName] : '';
  }

  @action
  public async exchangePrincipalToken(context: ExtendedContextStore, request: Request) {
    const { traceInfo } = context;

    const cookieLookOut = this.getCookieLookoutName();

    const tokenHeaders = {
      'content-type': 'application/json',
      'trace-id': traceInfo?.['Trace-ID'] || '',
    };
    const payload = {
      opaqueToken: this.getCookieLookoutHeader(request, cookieLookOut),
    };

    const tokenServiceV3Response = await this.executeDynamicRequest(
      'POST',
      this.tokenServiceV3Url,
      tokenHeaders,
      '',
      payload,
      this.logger,
      'oneIdentityCheckStore'
    );

    if (tokenServiceV3Response.status !== 200) {
      this.logger.logEvent(
        new SystemEvent(SystemEventLevel.INFO, 'oneIdentityCheckStore, TokenServiceResponseV3'),
        `TokenServiceResponseV3 status failed returning false`
      );
      return null;
    }

    const { encodedJwt } = tokenServiceV3Response.data as TokenServiceV3Response;
    return encodedJwt;
  }

  @action
  public async getOneIdentity(session: string) {
    const decodedSession = jwtDecode(session);

    const identitySearchHeaders = {
      Authorization: `EGToken Principal-JWT=${session}`,
    };

    const identitySearchResponse = await this.executeDynamicRequest(
      'GET',
      `${this.identitySearchUrl}/${decodedSession.sub}`,
      identitySearchHeaders,
      '',
      '',
      this.logger,
      'identitySearch'
    );

    if (identitySearchResponse.status !== 200) {
      this.logger.logEvent(
        new SystemEvent(SystemEventLevel.INFO, 'oneIdentityCheckStore, identitySearchResponse'),
        `identitySearchResponse status failed returning false`
      );

      return false;
    }

    this.logger.logEvent(
      new SystemEvent(SystemEventLevel.INFO, 'oneIdentityCheckStore, identitySearchResponse'),
      `identitySearchResponse data=${JSON.stringify(identitySearchResponse.data)}`
    );

    const { account_upgrade_status } = identitySearchResponse.data as IdentitySearchResponse;
    const isOneIdentityOnboarded = account_upgrade_status === 'UPGRADED' ? true : false;

    return isOneIdentityOnboarded;
  }

  @action
  public async executeDynamicRequest(method, url, headers, params, payload, logger = null, controllerName = '') {
    const config = { method, url, headers, params, data: payload };
    logger.logEvent(
      new SystemEvent(SystemEventLevel.INFO, `controllerName=${controllerName}`),
      `API Call to ${url} with ${JSON.stringify(config)} `
    );
    try {
      const response = await axios(config);
      logger.logEvent(
        new SystemEvent(SystemEventLevel.INFO, `controllerName=${controllerName}`),
        `Succeeded API Call to ${url} with ${JSON.stringify(config)} `
      );
      return response;
    } catch (error) {
      logger.logEvent(
        new SystemEvent(SystemEventLevel.ERROR, `controllerName=${controllerName}`),
        `Failed to call ${url} with ${JSON.stringify(error)}`
      );
      return { status: error.response.status, data: error.response.data };
    }
  }
}
