// This file is shared between init.js and the client side code.
// Because it's used by init.js, it needs to be a javascript file and not a typescript file

// External dependencies
const qs = require('qs');

// Internal dependencies

// contains only lower case letters, numbers, and dashes. Doesn't actually validate the exact format,
// but good enough to block malicious input
const UUID_REGEX = /^[a-z0-9-]+$/;
const ABSOLUTE_URL_REGEX = /(?:^[a-z][a-z0-9+.-]*:|\/\/)/;
const WORD_REGEX = /^[a-zA-Z0-9-_.]+$/;

const CAS_PATH = '/auth/traveler/login';
const QUERY_PARAMS_KEY_RENAME = {
  login_challenge: 'loginChallenge',
  login_reference_id: 'loginReferenceId',
};

const isStringUUID = (str) => UUID_REGEX.test(str);

const isWord = (str) => WORD_REGEX.test(str);

const isStringBoolean = (str) => ['true', 'false'].includes(str);

const getDecodedUrl = (url) => {
  try {
    const decodedUrl = decodeURIComponent(url);
    return decodedUrl !== url ? getDecodedUrl(decodedUrl) : decodedUrl;
  } catch (_) {
    return url;
  }
};

const isUrlAbsolute = (url) => {
  return ABSOLUTE_URL_REGEX.test(getDecodedUrl(url));
};

const buildCasRedirectUrl = (url) => {
  const isDecodedUrl = url.startsWith('https://') || url.startsWith('http://');
  const encodedUrl = isDecodedUrl ? encodeURIComponent(url) : url;
  return `${CAS_PATH}?service=${encodedUrl}&authui=false`;
};

const addRedirectTo = (key, query, brand) => {
  if (key === 'redirectTo') {
    return Boolean(query) && ((isUrlAbsolute(query) && brand === 'vrbo') || !isUrlAbsolute(query));
  } else if (key === 'target') {
    return Boolean(query) && !isUrlAbsolute(query);
  } else if (key === 'uurl') {
    const rurl = decodeURIComponent(query).split('rurl=')[1];
    return Boolean(rurl) && !isUrlAbsolute(rurl);
  }
};

const getRedirectTo = (key, query, brand, logger, traceId = '') => {
  let result = '';

  if (key === 'redirectTo') {
    if (isUrlAbsolute(query) && brand === 'vrbo') {
      try {
        const decodedRedirectTo = decodeURIComponent(query);
        const redirectToParams = qs.parse(decodedRedirectTo, { ignoreQueryPrefix: true });
        const skipCas = redirectToParams.svctkt === 'false';
        const redirectToURL = new URL(decodedRedirectTo);
        const redirectToPathname = redirectToURL.pathname;
        const redirectToSearch = redirectToURL.search;

        result = skipCas ? `${redirectToPathname}${redirectToSearch}` : buildCasRedirectUrl(query);
      } catch (error) {
        const { input = '', code = '', message = '', name = '', stack = '' } = error;
        logger(
          `Failed to parse redirectTo URL during query string processing. Error details: name=${name}, code=${code}, message=${message}, input=${input}, stack=${stack}, with traceId=${traceId}`
        );
        return result;
      }
    } else if (!isUrlAbsolute(query)) {
      result = query;
    }
  } else if (key === 'target') {
    result = query;
  } else if (key === 'uurl') {
    const rurl = decodeURIComponent(query).split('rurl=')[1];
    result = rurl;
  }

  return result;
};

const queryParamsValidation = (queryKey, query, brand, logger, traceId = '') => {
  const result = {
    addValue: false,
    key: queryKey,
    value: query,
  };

  // String Word
  const stringWordElements = ['client_id', 'response_type', 'code_challenge', 'login_reference_id'];
  const verifyStringWord = stringWordElements.includes(queryKey);
  if (verifyStringWord) {
    result.addValue = isWord(query);
  }

  // String UUID
  const stringUUIDElements = ['conversationId', 'flow', 'login_challenge'];
  const verifyStringUUID = stringUUIDElements.includes(queryKey);
  if (verifyStringUUID) {
    result.addValue = isStringUUID(query);
  }

  // String Boolean
  const stringBooleanElements = ['enable_login', 'mfaEnabled', 'oldAto', 'passwordless', 'enable_registration'];
  const verifyStringBoolean = stringBooleanElements.includes(queryKey);
  if (verifyStringBoolean) {
    result.addValue = isStringBoolean(query);
    result.value = query === 'true';
  }

  // Redirect To
  const redirectToElements = ['redirectTo', 'target', 'uurl'];
  const verifyRedirectTo = redirectToElements.includes(queryKey);
  if (verifyRedirectTo) {
    result.addValue = addRedirectTo(queryKey, query, brand);
    result.key = 'redirectTo';
    result.value = getRedirectTo(queryKey, query, brand, logger, traceId);
  }

  // Key rename
  const keyRenameElements = ['login_challenge', 'login_reference_id'];
  const verifyKeyRename = keyRenameElements.includes(queryKey);
  if (verifyKeyRename) {
    result.key = QUERY_PARAMS_KEY_RENAME[queryKey];
  }

  // Default
  if (!(verifyStringUUID || verifyStringBoolean || verifyRedirectTo || verifyKeyRename)) {
    result.addValue = Boolean(query);
  }

  return result;
};

const validateQueryParams = (queryParams, brand = '', logger, traceId = '') => {
  const result = {};

  Object.keys(queryParams).forEach((queryKey) => {
    const query = queryParams[queryKey];

    const { addValue, key, value } = queryParamsValidation(queryKey, query, brand, logger, traceId);

    if (addValue) result[key] = value;
  });

  return result;
};

const parseQueryString = (queryString) => {
  const inputParams = qs.parse(queryString, { ignoreQueryPrefix: true });
  return validateQueryParams(inputParams);
};

module.exports = {
  buildCasRedirectUrl,
  getDecodedUrl,
  isUrlAbsolute,
  parseQueryString,
  validateQueryParams,
};
