// Internal dependencies
import { FailureReason } from 'src/types';

export function getFailureMessage(reason: FailureReason) {
  switch (reason) {
    case 'AUTHENTICATION_FAILURE':
      return 'eg.user.errors.email.password.not.match.legend';
    case 'SYSTEM_PASSWORD_RESET':
      return 'eg.user.errors.system.reset.password.legend';
    case 'SIGNUP_DUPLICATE_ACCOUNT_FAILURE':
      return 'eg.signup.form.duplicate.account.error';
    case 'SIGNUP_INVALID_EMAIL_FAILURE':
      return 'eg.user.email.invalid.label';
    case 'SOCIAL_MISSING_EMAIL':
      return 'eg.social.auth.missing.email.error';
    case 'SOCIAL_PERMISSION_DENIED':
      return 'eg.social.auth.permission.denied.error';
    case '2FA_SMS_FAILURE':
    case '2FA_VERIFY_FAILURE':
      return 'This code is incorrect. Click Resend code.'; //eg.2fa.signup.code.input.error.label
    case '2FA_TOO_MANY':
      return 'eg.2fa.login.blocked.attempt';
    case 'INVALID_OTP':
      return 'eg.one.key.otp.page.code.invalid.label';
    case 'EXPIRED_OTP':
      return 'eg.one.key.otp.page.code.expired.label';
    case 'LOGIN_BAD_REQUEST':
      return 'eg.default.errors.message.content';
    case 'SIGNUP_FAILURE':
    case 'BAD_REQUEST':
    case 'FORBIDDEN':
    case 'NOT_FOUND':
    case 'SERVICE_FAILURE':
      return 'eg.user.errors.general.legend';
    case 'SYSTEM_PASSWORD_RESET_OI':
      return 'eg.one.key.system.reset.password.legend';
    case 'SERVICE_FAILURE_OI':
    case 'BAD_REQUEST_OI':
      return 'eg.user.errors.general.retry.legend';
    case 'OI_NOT_SUPPORTED':
      return 'eg.signup.form.oi.not.supported.error';
    case 'CHANGE_PASSWORD_FAILURE':
      return 'eg.user.errors.general.legend';
  }
}

export function getWarningHeader(reason: FailureReason) {
  switch (reason) {
    case 'LOGIN_BAD_REQUEST':
      return 'eg.default.errors.message.header';
    default:
      break;
  }
}

export function getWarningFooter(reason: FailureReason) {
  switch (reason) {
    case 'SERVICE_FAILURE_OI':
    case 'BAD_REQUEST_OI':
      return 'eg.user.reload.link.label';
    case 'SYSTEM_PASSWORD_RESET_OI':
      return 'eg.default.errors.message.footer';
    default:
      break;
  }
}
