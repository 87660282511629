// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { ExtendedContextStore } from 'types';
import { ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { EN_US_LOCALE } from 'src/constants';
import { MarketingConsentLogEvents } from 'loggers';

export class MarketingConsentController implements Controller {
  public pageId = 'MarketingConsent';
  public path = '/:locale?/marketingconsent';
  public routeName = 'marketingconsent';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('views/marketing-consent-page/marketing-consent-page.view'));
  public exact = true;

  public async fetch(options: FetchOptions) {
    const { isServer, stores, logger } = options;

    logger.logEvent(MarketingConsentLogEvents.startLog);

    const contextStore = stores.get<ExtendedContextStore>('context');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    logger.logEvent(MarketingConsentLogEvents.storesCreated);

    let pageRequestContextStore;

    if (isServer) {
      logger.logEvent(MarketingConsentLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(MarketingConsentLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(MarketingConsentLogEvents.requestContextError(error.message));
        });
    }

    return await Promise.all([pageRequestContextStore]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const formattedTitle = localization.formatText('eg.login.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
