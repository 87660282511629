// External dependencies
import { Context } from 'bernie-context';

export const generateResetPasswordPayload = (context: Context, query, hostUrl: string, oldUrl: string) => {
  const customQueryParameter = {};
  for (const key in query) {
    customQueryParameter[key] = query[key];
  }

  const flow = query && query.flow;
  const passKey = query && String(query.code);
  const expUser = query && Number(query.expuid);

  let apiEndpoint = null;
  let payload = null;

  switch (flow) {
    case 'newreset':
      apiEndpoint = `${hostUrl}${'/eg-auth-svc/v1/password/verify-token'}`;
      payload = {
        passwordToken: query && query.code,
        channelType: 'WEB',
      };
      break;
    case 'oneid':
      apiEndpoint = `${hostUrl}${'/eg-auth-svc/v2/checkpasswordkey'}`;
      payload = { channelType: 'WEB', passKey: passKey };
      break;
    default:
      apiEndpoint = `${hostUrl}${oldUrl}`;
      payload = {
        siteId: context.site && context.site.id,
        channelType: 'WEB',
        expUserId: expUser,
        passKey: passKey,
        customQueryParameter,
      };
  }

  return {
    apiEndpoint,
    payload,
  };
};
