// Internal dependencies
import { CsrfData, EGAuthUiContext } from 'src/types';

export const generateSetPasswordPayload = (
  csrfData: CsrfData,
  pageContext: EGAuthUiContext,
  userName: string,
  egAccountId: string,
  passKey: string,
  flow: string,
  password,
  captchaServicePayload,
  trustWidgetPayload,
  userEmail: string,
  passwordToken: string,
  setPasswordUrl: string,
  setPasswordOldUrl: string,
  setPasswordOneIdUrl: string,
  setPasswordPlacement: string
) => {
  let apiEndpoint = null;
  let payload = null;

  switch (flow) {
    case 'newreset':
      apiEndpoint = setPasswordUrl;
      payload = {
        passwordToken,
        newPassword: password,
        egAccountId,
        userName: userEmail || '',
        channelType: 'WEB',
        atoShieldData: {
          atoTokens: captchaServicePayload,
          placement: setPasswordPlacement,
        },
        csrfData,
        devices: [
          {
            payload: trustWidgetPayload,
            type: 'TRUST_WIDGET',
          },
        ],
      };
      break;
    case 'oneid':
      apiEndpoint = setPasswordOneIdUrl;
      payload = {
        userName: userName,
        password: password,
        channelType: 'WEB',
        passKey,
        atoShieldData: {
          atoTokens: captchaServicePayload,
          placement: setPasswordPlacement,
        },
        csrfData,
        devices: [
          {
            payload: trustWidgetPayload,
            type: 'TRUST_WIDGET',
          },
        ],
      };
      break;
    default:
      apiEndpoint = setPasswordOldUrl;
      payload = {
        password: password,
        egAccountId,
        userName,
        channelType: 'WEB',
        langId: pageContext.langId,
        passKey,
        atoShieldData: {
          atoTokens: captchaServicePayload,
          placement: setPasswordPlacement,
        },
        csrfData,
        devices: [
          {
            payload: trustWidgetPayload,
            type: 'TRUST_WIDGET',
          },
        ],
      };
  }

  return {
    apiEndpoint,
    payload,
  };
};
