// External dependencies
import { Context } from 'bernie-context';
import fetch from 'isomorphic-fetch';
import { v4 as UUIDv4 } from 'uuid';

// Internal dependencies
import { XSS_PROTECTION_HEADER } from '../constants';
import { Headers, HttpRequestMethodType } from '../types';

export async function callFetch(
  url: string,
  methodType: HttpRequestMethodType,
  context: Context,
  additionalHeaders?: Headers,
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  },
  requestBody?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
) {
  // @ts-ignore asd
  let traceId = context.traceInfo ? context.traceInfo['Trace-ID'] : '';
  const headers = Object.assign({}, additionalHeaders || {}, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-MC1-GUID': context.deviceId,
    'X-REMOTE-ADDR': context.remoteAddress,
    'X-USER-AGENT': context.userAgent,
    'Device-User-Agent-Id': context.deviceId,
    'Trace-Id': traceId,
    'X-B3-TraceId': UUIDv4(),
    'X-XSS-Protection': XSS_PROTECTION_HEADER,
    ...(context.deviceInformation?.type && { 'Device-Type': context.deviceInformation.type }),
  });

  const query: string[] = [];
  for (const pk in data) {
    if ({}.hasOwnProperty.call(data, pk)) {
      const pkValue = data[pk];
      if (pkValue) {
        query.push(`${encodeURIComponent(pk)}=${encodeURIComponent(pkValue)}`);
      }
    }
  }
  const queryString = query.join('&') ? `?${query.join('&')}` : '';
  const urlWithQuery = url + queryString;

  const response = await fetch(urlWithQuery, {
    method: methodType,
    // comment out headers when testing locally
    headers,
    credentials: 'include',
    body: requestBody ? JSON.stringify(requestBody) : null,
  });

  try {
    const body = await response.json();
    traceId = response.headers?.get('Trace-Id');

    return { hasError: !response.ok, code: response.status, ...body, traceId };
  } catch (e) {
    return { hasError: !response.ok, code: response.status, ...e, traceId };
  }
}
