export const ACCOUNT_INFO_CONFIG = {
  PROFILE_DETAIL_0: {
    showMarketingConsent: true,
    showFirstName: true,
    showLastName: true,
    title: 'eg.firstnamelastname.header',
    subHeading: 'eg.firstnamelastname.subheader',
  },

  PROFILE_DETAIL_1: {
    showMarketingConsent: false,
    showFirstName: true,
    showLastName: true,
    title: 'eg.firstnamelastname.header',
    subHeading: 'eg.firstnamelastname.subheader',
  },

  PROFILE_DETAIL_2: {
    showMarketingConsent: true,
    showFirstName: false,
    showLastName: false,
    title: 'eg.marketingconsent.form.header',
    subHeading: '',
  },
};
