import { HaSessionV3CookieName, EgSessionTokenCookieName, TgtCookieName } from '../../types';

export const isProd = () => {
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  return false;
};

export const getHaSessionV3CookieName = (): HaSessionV3CookieName => (isProd() ? 'HASESSIONV3' : 'HASESSIONV3_STG');

export const getVrboEGSessionTokenCookieName = (): EgSessionTokenCookieName => EG_SESSIONTOKEN;

export const getTgtTokenCookieName = (): TgtCookieName => (isProd() ? 'HATGC_LOTC' : 'HATGC_STAGE_LOTC');

export const EG_SESSIONTOKEN = 'EG_SESSIONTOKEN';
