// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';

// Internal dependencies
import { CaptchaServiceStore, ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { ForgotPasswordLogEventsV2 } from 'loggers';
import { ExtendedContextStore } from 'types';

export class ForgotPasswordControllerV2 implements Controller {
  public pageId = 'ForgotPasswordV2';
  public path = '/:locale?/forgotpassword-v2';
  public routeName = 'forgotpassword-v2';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/forgot-password-page-v2/forgot-password-page-v2.view'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('ForgotPasswordControllerV2.fetch received invalid FetchOptions');
    }

    const {
      isServer,
      stores,
      query: { oldAto },
      logger,
    } = options;

    logger.logEvent(ForgotPasswordLogEventsV2.startLog);

    const contextStore = stores.get<ExtendedContextStore>('context');
    const captchaStore: CaptchaServiceStore = stores.get<CaptchaServiceStore>('captchaStore');
    const requestContextStore = stores.get<RequestContextServiceStore>('requestContextStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    let forgotPasswordWidget, pageRequestContextStore;
    logger.logEvent(ForgotPasswordLogEventsV2.storesCreated);

    const placement = configStore.getForgotPasswordPlacement();

    logger.logEvent(ForgotPasswordLogEventsV2.placementLogger(placement));
    logger.logEvent(ForgotPasswordLogEventsV2.captchaStart);

    if (isServer) {
      forgotPasswordWidget = captchaStore
        .getCaptcha(contextStore, oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(ForgotPasswordLogEventsV2.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(ForgotPasswordLogEventsV2.captchaLoadedError(error.message));
        });

      logger.logEvent(ForgotPasswordLogEventsV2.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          contextStore,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(ForgotPasswordLogEventsV2.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(ForgotPasswordLogEventsV2.requestContextError(error.message));
        });
    }
    return await Promise.all([forgotPasswordWidget, pageRequestContextStore]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.forgotpassword.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
