import { EventName } from 'src/types';

export const UNIVERSAL_LOGIN_TEXT = 'Universal Login';
export const SIGN_IN_FORM_NAME = 'loginForm';
export const SIGN_UP_FORM_NAME = 'signupForm';
export const FORGOT_PASSWORD_FORM_NAME = 'forgotPasswordForm';
export const SET_PASSWORD_FORM_NAME = 'setPasswordForm';
export const RESEND_LINK_FORM_NAME = 'resendLinkForm';
export const CREATE_PASSWORD_FORM_NAME = 'createPasswordForm';
export const DELETE_ACCOUNT_FORM_NAME = 'DeleteAccountForm';
export const ERROR_PAGE_FORM_NAME = 'ErrorPageForm';
export const OTP_LOGIN_ERROR = 'otpLogin';
export const ADD_PHONE_ERROR = 'addPhone';
export const VERIFY_OTP_FORM_NAME = 'verifyOtpForm';
export const LOGIN_EMAIL_FORM_NAME = 'loginEmailForm';
export const LOGIN_EMAIL_PAGE = 'sign in';
export const SIGN_IN_PAGE = 'Sign In';
export const SIGN_UP_PAGE = 'Sign Up';
export const INCENTIVES_WELCOME_SCREEN = 'Incentives Welcome Screen';
export const RESET_PASSWORD_PAGENAME = 'Reset Password';
export const RESET_PASSWORD_PAGE = 'reset password';
export const RESET_PASSWORD_EXPIRED_PAGE = 'reset password expired';
export const FORGOT_PASSWORD_PAGE = 'Forgot Password';
export const VERIFY_OTP_PAGE = 'Verify OTP';
export const SELECT_PROVIDER_PAGE = 'Select Provider';
export const MFA_PAGE = 'MFA';
export const DELETE_ACCOUNT = 'Delete Account';
export const ERROR_PAGE = 'Error Page';
export const CUSTOM_EVENT_VERSION = '1.0.0';
export const SWITCH_TO_EMAIL_OTP_SUBMITTED_VERSION = '1.3.0';
export const CUSTOM_RESEND_FAILED_VERSION = '1.4.0';
export const DEFAULT_EVENT_VERSION = '2.1.0';
export const CUSTOM_FORM_PRESENTED_EVENT_VERSION = '3.0.0';
export const BIOMETRIC_EVENT_VERSION = '1.2.0';
export const BIOMETRIC_EVENT_VERSION_1 = '1.3.0';
export const CUSTOM_FORM_CHECKBOX_EVENT_VERSION = '3.2.0';
export const CUSTOM_FORM_CHECKBOX_DESELECTED_EVENT_VERSION = '3.13.0';
export const FORM_SUBMITTED_VERSION = '1.7.0';
export const FORM_SUCCEEDED_VERSION = '1.2.0';
export const FORM_FAILED_VERSION = '1.7.0';
export const FORM_LINK_SELECTED = '3.8.1';
export const AUTHENTICATION_METHOD_GOOGLE = 'google';
export const AUTHENTICATION_METHOD_FACEBOOK = 'facebook';
export const AUTHENTICATION_METHOD_EMAIL = 'email';
export const AUTHENTICATION_METHOD_APPLE = 'apple';
export const BIOMETRIC_SIGIN_FORM = 'Biometric Signin Form';
export const BIOMETRIC_SIGNUP_FORM = 'Biometric SignUp Form';
export const BIOMETRIC_LOGIN_FORM = 'Biometric Login Form';
export const STEP1 = 'step 1';
export const STEP2 = 'step 2';
export const STEP3 = 'step 3';
export const ENTER_PASSWORD_PAGE = 'Enter Password';
export const ENTER_PASSWORD_FORM_NAME = 'enterPasswordForm';
export const DELETE_ACCOUNT_PAGE = 'Delete Account';

export const DEFAULT_ERROR_PAGE = 'Generic Error';
export const ADD_PASSWORD_PAGE = 'Add Password';
export const CREATE_PASSWORD_PAGE = 'create password';
export const SET_PASSWORD_PAGE = 'set password';
export const CHANGE_PASSWORD_PAGE = 'change password';
export const ADD_PASSWORD_FORM_NAME = 'addPasswordForm';
export const CHANGE_PASSWORD_FORM_NAME = 'changePasswordForm';
export const INITIAL_AUTH_STEP = 'initial auth step';
export const EMAIL_OTP = 'email otp';
export const ENTER_PASSWORD = 'enter password';
export const MARKETING_CONSENT_PAGE = 'marketing consent';
export const MARKETING_CONSENT = 'marketing consent';
export const MARKETING_CONSENT_FORM_NAME = 'marketingconsentform';
export const ACCOUNT_INFO_PAGE = 'first name last name';
export const ADD_PHONE_NUMBER_PAGE = 'add mobile number';
export const ACCOUNT_INFO_FORM_NAME = 'firstNameLastNameForm';
export const ACCOUNT_MERGE_PAGE = 'account merge';
export const LEGAL_POPUP_INITIAL_AUTH_PAGE_NAME_DETAILED = 'initial auth step social consent';

/**
 * @todo
 * check and add the form names
 */
export const HEADER_EVENT_FORM_NAME = {
  'Sign In': SIGN_IN_FORM_NAME,
  'Sign Up': SIGN_UP_FORM_NAME,
  'Forgot Password': FORGOT_PASSWORD_FORM_NAME,
  'Reset Password': SET_PASSWORD_FORM_NAME,
};

export const HEADER_PAGE_NAME_DETAILED = {
  'Sign In': `${SIGN_IN_PAGE} ${UNIVERSAL_LOGIN_TEXT}`,
  'Sign Up': `${SIGN_UP_PAGE} ${UNIVERSAL_LOGIN_TEXT}`,
  'Forgot Password': `${FORGOT_PASSWORD_PAGE} ${UNIVERSAL_LOGIN_TEXT}`,
  'Reset Password': `${RESET_PASSWORD_PAGE} ${UNIVERSAL_LOGIN_TEXT}`,
};

export const AUTHENTICATION_METHOD = {
  apple: 'Apple',
  google: 'Google',
  facebook: 'Facebook',
  email: 'Email',
  naver: 'Naver',
};

export const ERROR_MESSAGE_FORMATED = {
  'eg.user.errors.email.password.not.match.legend': "Email and password don't match. Try again.",
  'eg.signup.form.duplicate.account.error': 'This email address is already associated with an account',
  'eg.user.email.invalid.label': 'Enter a valid email address',
  'eg.social.auth.missing.email.error':
    'Sign in was not successful. You must use your email address and set it to be shared from your social media account.',
  'eg.social.auth.permission.denied.error':
    'Sign in was not successful. Please try again or create an account on this site to proceed.',
  'eg.user.errors.general.legend': 'Sorry, something went wrong on our end.',
  'eg.2fa.signup.code.input.error.label': 'This code is incorrect. Click Resend code.',
  'eg.2fa.login.blocked.attempt': 'You have exceeded the maximum number of sign in attempts. Please try again later.',
  'eg.user.errors.system.reset.password.legend':
    'Your account was paused in a routine security check. Your info is safe, but a password reset is required. Make a change with the "Forgot password?" link below to sign in.',
  'eg.one.key.otp.page.code.invalid.label': 'Invalid code, please try again',
  'eg.one.key.otp.page.code.expired.label': 'Code expired, please resend and try again',
  'eg.one.key.system.reset.password.legend':
    'Your account was paused in a routine security check. Your info is safe, but a password reset is required.',
  'eg.user.errors.general.retry.legend': 'Sorry, something went wrong on our end. Please wait a moment and try again.',
  'eg.signup.form.oi.not.supported.error':
    'Sorry, something went wrong on our end. Please wait a few minutes, then try again.',
};

export const ERROR_ACTIVE_TRIPS_FOUND = {
  'eg.delete.account.active.booking.title': "Sorry, you can't delete till your trip is complete.",
  'eg.delete.account.active.booking.title.oneid': 'Sorry, you need to wait until your trip is complete',
  'eg.delete.account.multiple.active.booking.title.oneid': 'Sorry, you need to wait until after your upcoming trips',
  'eg.delete.account.active.booking.content':
    "To ensure a smooth trip, you'll need the account to access your booking and get help from our customer care in case of last-minute changes.",
  'eg.delete.account.active.booking.details.oneid':
    'Your account lets you access booking details and support if needed. Please try again after your trip is over.',
  'eg.delete.account.multiple.active.booking.details.oneid':
    'Your account lets you access booking details and support if needed. Please try again after your trips are over.',
};

export const ERROR_MESSAGE_REASON = {
  400: 'Bad Request',
  401: 'Authentication Failure',
  403: 'Authentication Failure',
  409: 'Account Already Exists',
  423: 'System Password Reset',
  429: 'Maximum Attempts Reached',
  SERVICE_FAILURE: 'Service Error',
};

export const EVENT_WITH_USER_DATA: EventName[] = ['sign_in.succeeded', 'sign_up.succeeded', 'username.succeeded'];

export const EVENT_WITH_FORM_REDIRECT_URL: EventName[] = [
  'forgot_password.submitted',
  'forgot_password.succeeded',
  'resend_link.failed',
  'resend_link.succeeded',
  'form.presented',
  'sign_in.succeeded',
  'sign_in.submitted',
  'sign_up.submitted',
  'sign_up.succeeded',
  'sign_up_reset_link.succeeded',
  'form_button.selected',
  'form.closed',
];

export const EVENT_WITHOUT_FORM_REDIRECT_URL: EventName[] = [
  'form.failed',
  'resend_link.failed',
  'form.succeeded',
  'form.submitted',
  'switch_to_password.submitted',
  'switch_to_email_otp.submitted',
  'resend_code.submitted',
  'resend_code.succeeded',
  'resend_code.failed',
];

export const SOCIAL_AUTH_ANALYTICS_EVENTS = {
  loginForm: {
    submitted: 'sign_in.submitted',
    succeeded: 'sign_in.succeeded',
    failed: 'sign_in.failed',
  },
  signupForm: {
    submitted: 'sign_up.submitted',
    succeeded: 'sign_up.succeeded',
    failed: 'sign_up.failed',
  },
  loginEmailForm: {
    submitted: 'form.submitted',
    succeeded: 'form.succeeded',
    failed: 'form.failed',
  },
};

/**
 *  Biometric Events with User Data
 *  https://confluence.expedia.biz/pages/viewpage.action?pageId=2392206348
 */
export const BIOMETRIC_EVENT_WITH_USER_DATA: EventName[] = [
  'biometric_login.succeeded',
  'biometric_signup.succeeded',
  'biometric_turnedoff.succeeded',
];

/**
 * https://confluence.expedia.biz/pages/viewpage.action?pageId=2392206348
 */
export const BIOMETRIC_EVENT_WITH_FORM_REDIRECT_URL: EventName[] = [
  'biometric_signup.presented',
  'biometric_now.selected',
  'biometric_later.selected',
  'biometric_options.presented',
  'biometric_signup.failed',
  'biometric_login.presented',
  'biometric_login.selected',
  'biometric_login.submitted',
  'biometric_login.succeeded',
  'biometric_login.failed',
  'biometric_login.timedout',
  'biometric_delink.selected',
  'biometric_turnedoff.succeeded',
];

export const COMPONENT_ELEMENT_TEXT = {
  'eg.user.keep.signed.in.label': 'Keep me signed in',
  'eg.user.public.shared.device.label': 'This is a public or shared device',
  'eg.signup.travel.deals': "I'd like to receive travel deals, special offers, and other information via email",
  'eg.delete.account.why.leaving.too.many.emails': 'I get too many emails or notifications.',
  'eg.delete.account.why.leaving.trip.over': "My trip is over, so I don't need this account.",
  'eg.delete.account.why.leaving.reason.other': 'Other',
  'eg.delete.account.permanently.delete.button': 'Permanently delete account',
  'eg.delete.account.do.not.delete.account.button': "Don't delete account",
  'eg.delete.account.got.it.button': 'Got it',
  'eg.delete.account.why.leaving.submit.button': 'Submit',
  'eg.delete.account.why.leaving.skip.button': 'Skip',
  'eg.delete.account.please.try.again': 'Please try again',
  'eg.user.errors.general.legend': 'Sorry, something went wrong on our end.',
  'eg.delete.account.why.leaving.another.account.oneid': 'I have an account with another email',
  'eg.delete.account.why.leaving.negative.experience.oneid': 'I had a negative booking experience',
};
