export const base64UrlSafeToBase64 = (str) => {
  return str.replace(new RegExp('_', 'g'), '/').replace(new RegExp('-', 'g'), '+');
};

export const binaryToString = (bin) => {
  return btoa(new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), ''));
};

export const stringToBinary = (str) => {
  return Uint8Array.from(atob(base64UrlSafeToBase64(str)), (c) => c.charCodeAt(0));
};
