// External dependencies
import { Context } from 'bernie-context';
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';
import { action, observable } from 'mobx';

// Internal dependencies
import { Headers } from 'src/types';
import { callFetch } from 'src/utils';
import { generateResetPasswordPayload } from 'src/payloads';

export class SetPasswordStore extends Store {
  verifyResetPasswordLinkHost: string;
  verifyResetPasswordLinkUrl: string;
  verifyResetPasswordLinkOldUrl: string;
  forwardedHeaders: Headers;
  @observable isExpired: boolean;
  @observable userName: string;
  @observable passKey: string;
  @observable egAccountId: string;
  public constructor(
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forwardedHeaders?: Headers
  ) {
    super(state, logger);
    if (state) {
      this.verifyResetPasswordLinkHost = state.host;
      this.verifyResetPasswordLinkUrl = state.url;
      this.verifyResetPasswordLinkOldUrl = state.oldUrl;
    }
  }

  public hydrate(data: SerializedData) {
    delete data.resetPasswordLinkVerification;
    Object.assign(this, data);
  }

  @action
  public setResetPasswordLinkData(isExpired: boolean, emailAddress: string, query) {
    this.isExpired = isExpired;
    this.userName = emailAddress;
    //TODO: need to update this to support other brands HCOM, VRBO
    this.egAccountId = `egaid:BEX:${query && query.expuid}`;
    this.passKey = query && query.code;
  }

  @action
  public async verifyResetPasswordLink(context: Context, query, resetPwdHost: string, resetPwdUrl: string) {
    const systemWarnEvent = { level: SystemEventLevel.WARN, name: 'eg-auth-ui' };

    const params = [context, query, this.verifyResetPasswordLinkHost, this.verifyResetPasswordLinkOldUrl] as const;

    const { apiEndpoint, payload } = generateResetPasswordPayload(...params);

    const extraHeaders = Object.assign(
      {},
      this.forwardedHeaders,
      { 'X-Forwarded-Host': context.site.domain },
      resetPwdHost,
      resetPwdUrl
    );

    this.logger.logEvent(
      { level: SystemEventLevel.INFO, name: 'eg-auth-ui' },
      `SET_PASSWORD_STORE - Reset Password endpoint=${apiEndpoint} payload=${JSON.stringify(payload)}`
    );

    const { hasError, failure, userName, code, message } = await callFetch(
      apiEndpoint,
      'POST',
      context,
      extraHeaders,
      {},
      payload
    );
    if (hasError) {
      this.logger.logEvent(
        systemWarnEvent,
        failure
          ? `Reset Password Link Verification failed. Code = ${code}. Failure Reason ${JSON.stringify(failure)}`
          : message
          ? `Reset Password Link Verification failed. Code = ${code}. Message: ${message} `
          : `Reset Password Link Verification failed. Code = ${code}. `
      );
    } else {
      this.logger.logEvent(
        { level: SystemEventLevel.INFO, name: 'eg-auth-ui' },
        `SET_PASSWORD_STORE - Reset Password Link Verification API return successfully`
      );
    }

    return {
      isExpired: hasError,
      emailAddress: userName,
    };
  }
}
