export const SOCIAL_AUTH_INITIATE = 'social-auth-initiate';
export const SOCIAL_AUTH_WINDOW_OPEN = 'social-auth-window-open';
export const SOCIAL_AUTH_CANCEL = 'social-auth-cancel';
export const SOCIAL_AUTH_SUCCESS = 'social-auth-success';
export const SOCIAL_AUTH_FAILURE = 'social-auth-failure';

export const RESET_AUTH_INITIATE = 'initiate';
export const RESET_AUTH_SUCCESS = 'success';
export const RESET_AUTH_MFA_STATE = 'mfa-state';
export const RESET_AUTH_FAILURE = 'failure';
export const RESET_AUTH_WARNING = 'warning';

export const MFA_AUTH_INITIATE = 'initiate';
export const MFA_AUTH_SMS_SUCCESS = 'sms_success';
export const MFA_AUTH_VERIFY = 'verify';
export const MFA_AUTH_VERIFY_SUCCESS = 'verify_success';
export const MFA_AUTH_SETUP = 'mfa-setup';
export const MFA_AUTH_CHALLENGE = 'mfa-challenge';
export const MFA_AUTH_SUCCESS = 'mfa-success';
export const MFA_AUTH_FAILURE = 'failure';
export const MFA_AUTH_CANCEL = 'cancel-mfa';

export const AUTH_INITIATE = 'initiate';
export const AUTH_UNREGISTERED = 'unregistered';
export const AUTH_SUCCESS = 'success';
export const AUTH_MFA_STATE = 'mfa-state';
export const AUTH_FAILURE = 'failure';
export const AUTH_WARNING = 'warning';
export const BIOMETRIC_STATE = 'biometric-state';
export const BIOMETRIC_FAILURE = 'biometric-failure';

export const DELETE_ACCOUNT_INITIATE = 'initiate';
export const DELETE_ACCOUNT_SUCCESS = 'success';
export const DELETE_ACCOUNT_FAILURE = 'failure';
export const DELETE_ACCOUNT_RETRY = 'retry';
