// External dependencies
import { instance as egClickstream } from 'eg-clickstream-sdk-js';

// Internal dependencies
import { ClickstreamPayload } from '../types';
import { DEFAULT_APP_NAME, DEFAULT_APP_VERSION } from '../constants';
import { getVersion } from '../utils';

export const egAnalyticsSdk = () => {
  return (analyticsPayload: ClickstreamPayload, exceptionalVersion = '') => {
    const payload = {
      ...analyticsPayload,
      event: {
        ...analyticsPayload.event,
        event_version: exceptionalVersion || getVersion(analyticsPayload.event.event_name),
      },
      experience: {
        ...analyticsPayload.experience,
        app_name: DEFAULT_APP_NAME,
        app_version: DEFAULT_APP_VERSION,
      },
    };
    egClickstream.publish(payload);
  };
};
