// External dependencies

// Internal dependencies

export const capitalizeFirstLetter = (string: string) => {
  if (typeof string !== 'string') return '';

  return `${string[0].toUpperCase()}${string.substring(1)}`;
};

export const getUserAccountFromEGAccountId = (egAccountId: string) => {
  if (typeof egAccountId !== 'string') return '';

  return egAccountId.split(':')[2];
};

export const getReferalCode = (refCode: any) => {
  if (typeof refCode === 'string') {
    return refCode;
  } else if (Array.isArray(refCode)) {
    return refCode[0];
  }
  return '';
};
