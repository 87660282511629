// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { EN_US_LOCALE } from 'src/constants';
import { OAuthVerifyEmailLogEvents } from 'src/loggers';
import { capitalizeFirstLetter } from 'src/utils';

export class OauthVerifyEmailController implements Controller {
  public pageId = 'OAuthVerifyEmail';
  public path = '/:locale?/oauth/verify/email/';
  public routeName = 'oauthverifyemail';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('views/oauth-email-verification/oauth-verify-email-page.view'));
  public exact = true;

  public async fetch(options: FetchOptions) {
    const { logger } = options;
    if (!options || !options.stores) {
      logger.logEvent(OAuthVerifyEmailLogEvents.fetchOptionsFailed);
      return Promise.reject('OauthVerifyEmailController.fetch received invalid FetchOptions');
    }
    logger.logEvent(OAuthVerifyEmailLogEvents.startLog);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    // TODO: Check with UX for page title
    const brandName = capitalizeFirstLetter(options?.context?.site?.uiBrand);
    const formattedTitle = localization.formatText('eg.login.account.page.title', brandName);
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
