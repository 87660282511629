// External dependencies
import { Dispatch, SetStateAction } from 'react';
// Internal dependencies
import {
  MAX_VERIFICATION_CODE_LENGTH,
  EMAIL_REGEX,
  INPUT_HAS_SPACE,
  PASSWORD_MIN_LENGTH,
  PASSWORD_HAS_ALPHA_REGEX,
  PASSWORD_MAX_LENGTH,
  PASSWORD_HAS_NUMBER_REGEX,
  PASSWORD_HAS_SPECIAL_CHAR_REGEX,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  NAME_HAS_SPECIAL_CHAR_REGEX,
  INPUT_HAS_ONLY_NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  EXPEDIA_GROUP_EMAIL_REGEX,
  CODE_HAS_SIX_DIGITS,
} from '../constants';

export const isSpacePresent = (text = '') => INPUT_HAS_SPACE.test(text);

export const validatePasswordLength = (pwd = '') =>
  pwd.length >= PASSWORD_MIN_LENGTH && pwd.length <= PASSWORD_MAX_LENGTH;

export const validateCreatePassword = (pwd: string) => {
  const lengthRule = pwd ? validatePasswordLength(pwd) : false;
  const lettersAndNumbersRule = pwd ? PASSWORD_HAS_ALPHA_REGEX.test(pwd) && PASSWORD_HAS_NUMBER_REGEX.test(pwd) : false;
  const specialCharRule = pwd ? PASSWORD_HAS_SPECIAL_CHAR_REGEX.test(pwd) : false;
  const noSpaceRule = pwd ? !isSpacePresent(pwd) : false;

  return { lengthRule, lettersAndNumbersRule, specialCharRule, noSpaceRule };
};

export const validateName = (name: string) => {
  const nameWithSpaceTrimmed = name.trim();
  const minLengthRule = nameWithSpaceTrimmed ? nameWithSpaceTrimmed.length >= NAME_MIN_LENGTH : false;
  const maxLengthRule = nameWithSpaceTrimmed ? nameWithSpaceTrimmed.length <= NAME_MAX_LENGTH : false;
  const noSpecialCharRule = nameWithSpaceTrimmed ? !NAME_HAS_SPECIAL_CHAR_REGEX.test(nameWithSpaceTrimmed) : false;
  return { minLengthRule, maxLengthRule, noSpecialCharRule };
};

export const validateEmail = (emailId: string) => {
  return EMAIL_REGEX.test(emailId);
};

export const validateExpediaGroupEmail = (emailId: string) => {
  const validEmail = validateEmail(emailId);
  const expediaGroupEmail = EXPEDIA_GROUP_EMAIL_REGEX.test(emailId);

  return validEmail && expediaGroupEmail;
};

export const validatePropField = (
  validatedResult: boolean | string, // either true or the error message
  setIsPropFieldValid: Dispatch<SetStateAction<boolean>>
) => {
  if (validatedResult === true) {
    setIsPropFieldValid(true);
    return true;
  }

  setIsPropFieldValid(false);
  return validatedResult;
};

export const validateSMSCode = (code: string) => {
  return INPUT_HAS_ONLY_NUMBER_REGEX.test(code);
};

export const validatePhoneNumber = (phoneNumber: string) =>
  PHONE_NUMBER_REGEX.test(phoneNumber) ? true : 'Invalid Phone number';

export const validate2FACodeLength = (typedCode: string) => typedCode.length === MAX_VERIFICATION_CODE_LENGTH;

export const validateSixDigitCode = (code: string) => CODE_HAS_SIX_DIGITS.test(code);
