export const getTermsAndConditionsKey = (loyalty_context) => {
  return loyalty_context?.auto_enroll_loyalty_enabled
    ? 'eg.user.terms.condition.label.with.loyalty'
    : 'eg.user.terms.condition.label';
};
export const getOITermsAndConditionsKey = (loyalty_context) => {
  return loyalty_context?.auto_enroll_loyalty_enabled
    ? 'eg.one.key.login.page.tnc.statement.with.loyalty'
    : 'eg.one.key.login.page.tnc.statement';
};
export const getLoyaltyProgramName = (loyaltyBrandName, l10n) => {
  switch (loyaltyBrandName) {
    case 'Expedia Rewards':
      return l10n.formatText('eg.loyalty.program.expedia');
    case 'CheapCash':
      return l10n.formatText('eg.loyalty.program.cheaptickets');
    case 'Orbitz Rewards':
      return l10n.formatText('eg.loyalty.program.orbitz');
    case 'BONUS+':
      return l10n.formatText('eg.loyalty.program.ebookers');
    case 'MrJet':
      return l10n.formatText('eg.loyalty.program.mrjet');
    case 'Hotels.com Rewards':
      return l10n.formatText('eg.loyalty.program.hotels');
    case 'One Key Rewards':
      return l10n.formatText('eg.loyalty.program.one.key');
    default:
      return loyaltyBrandName;
  }
};
