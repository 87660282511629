// External dependencies

// Internal dependencies
import {
  FORM_SUBMITTED_VERSION,
  DEFAULT_EVENT_VERSION,
  CUSTOM_EVENT_VERSION,
  CUSTOM_RESEND_FAILED_VERSION,
  BIOMETRIC_EVENT_VERSION,
  BIOMETRIC_EVENT_VERSION_1,
  CUSTOM_FORM_CHECKBOX_EVENT_VERSION,
  CUSTOM_FORM_CHECKBOX_DESELECTED_EVENT_VERSION,
  CUSTOM_FORM_PRESENTED_EVENT_VERSION,
  COMPONENT_ELEMENT_TEXT,
  FORM_SUCCEEDED_VERSION,
  FORM_FAILED_VERSION,
  FORM_LINK_SELECTED,
  SWITCH_TO_EMAIL_OTP_SUBMITTED_VERSION,
} from '../constants';
import { CustomClickStreamEventObjType, EventName, FormEventName, LoginEventContext } from '../types';
import { isSignUpFlow } from './auth.utils';

export const getVersion = (nameEvent: EventName) => {
  switch (nameEvent) {
    case 'form_button.selected':
      return CUSTOM_EVENT_VERSION;
    case 'account_delete.submitted':
      return CUSTOM_EVENT_VERSION;
    case 'form.closed':
      return CUSTOM_EVENT_VERSION;
    case 'error_message_button.selected':
      return CUSTOM_EVENT_VERSION;
    case 'account_delete.succeeded':
      return CUSTOM_EVENT_VERSION;
    case 'form_checkbox.selected':
      return CUSTOM_FORM_CHECKBOX_EVENT_VERSION;
    case 'form_checkbox.deselected':
      return CUSTOM_FORM_CHECKBOX_DESELECTED_EVENT_VERSION;
    case 'multi_factor_verification.submitted':
    case 'multi_factor_verification.succeeded':
    case 'multi_factor_verification.failed':
      return CUSTOM_EVENT_VERSION;
    case 'username.submitted':
    case 'username.succeeded':
    case 'username.failed':
    case 'resend_code.submitted':
    case 'resend_code.succeeded':
    case 'switch_to_password.submitted':
      return CUSTOM_EVENT_VERSION;
    case 'switch_to_email_otp.submitted':
      return SWITCH_TO_EMAIL_OTP_SUBMITTED_VERSION;
    case 'resend_code.failed':
      return CUSTOM_RESEND_FAILED_VERSION;
    case 'biometric_login.presented':
    case 'biometric_delink.selected':
    case 'biometric_facial.succeeded':
    case 'biometric_finger.succeeded':
    case 'biometric_later.selected':
    case 'biometric_login.selected':
    case 'biometric_login.succeeded':
    case 'biometric_login.timedout':
    case 'biometric_now.selected':
    case 'biometric_options.presented':
    case 'biometric_other.succeeded':
    case 'biometric_signup.failed':
    case 'biometric_signup.succeeded':
    case 'biometric_turnedoff.succeeded':
      return BIOMETRIC_EVENT_VERSION;
    case 'biometric_signup.presented':
    case 'biometric_login.failed':
    case 'biometric_login.submitted':
      return BIOMETRIC_EVENT_VERSION_1;
    case 'form.presented':
      return CUSTOM_FORM_PRESENTED_EVENT_VERSION;
    case 'form.submitted':
      return FORM_SUBMITTED_VERSION;
    case 'form.succeeded':
      return FORM_SUCCEEDED_VERSION;
    case 'form.failed':
      return FORM_FAILED_VERSION;
    case 'form_link.selected':
      return FORM_LINK_SELECTED;
    case 'form_field.inputted':
    case 'form_field.succeeded':
    case 'form_field.focused':
    case 'form_field.blurred':
    case 'error_message.presented':
    case 'form_sign_in_field.deselected':
    case 'sign_in.submitted':
    case 'sign_in.succeeded':
    case 'sign_in.failed':
    case 'sign_up.submitted':
    case 'form_sign_up_field.deselected':
    case 'sign_up_reset_link.succeeded':
    case 'sign_up.succeeded':
    case 'sign_up.failed':
    case 'forgot_password.submitted':
    case 'forgot_password.succeeded':
    case 'forgot_password.failed':
    case 'resend_link.succeeded':
    case 'resend_link.failed':
    case 'reset_password_expired.presented':
      return DEFAULT_EVENT_VERSION;
    default:
      return DEFAULT_EVENT_VERSION;
  }
};

export const getComponentElementText = (label: string, brandName = '') => {
  switch (label) {
    case 'eg.delete.account.why.leaving.another.account':
      return `I have another ${brandName} account.`;
    case 'eg.delete.account.why.leaving.negative.experience':
      return `I had a negative experience booking with ${brandName}.`;
    default:
      return COMPONENT_ELEMENT_TEXT[label];
  }
};

export const getSuccessEventNameForSocialAuth = (variant, scenario) => {
  return isSignUpFlow(variant, scenario) ? 'sign_up_succeeded' : 'sign_in_succeeded';
};

export const getFormRedirectUrlForSocialAuthInteractionEvent = (formName, redirectTo) => {
  return formName === 'loginEmailForm' ? '' : redirectTo;
};

export const trackCheckboxInput = (
  trackInteractionEvent: (
    eventName: FormEventName,
    eventContext?: LoginEventContext,
    customEventsObj?: CustomClickStreamEventObjType
  ) => void,
  checked: boolean,
  customEventsObj: CustomClickStreamEventObjType = {}
) => {
  const eventName = checked ? 'form_checkbox.selected' : 'form_checkbox.deselected';
  trackInteractionEvent(eventName, {}, customEventsObj);
};
