import { ACCOUNT_MERGE_IDENTITY_ONBOARDING_V2 } from 'src/constants';

export const isOneIdentityOrOneKeyFlow = (exposures) => {
  return (
    exposures?.['2_Step_sign_up_and_sign_in_BEX_NON_CKO_IN_EGTNL']?.bucket ||
    0
  );
};

export const shouldHaveSubHeaderAndFooter = (exposures) => {
  return exposures?.['UL_3LOGO_FOOTER']?.bucket || 0;
};

export const isSignInWithNaverEnabled = (exposures) => {
  return exposures?.['Naver_Social_Implementation']?.bucket || 0;
};
export const shouldPromoteSignInWithGoogle = (exposures) => {
  return isSignInWithNaverEnabled(exposures) ? false : exposures?.['UL_GOOGLE_SOCIAL_LOGIN_EGTNL']?.bucket || 0;
};

export const shouldDisplaySocialLoginSection = (exposures) => {
  return exposures?.['EG_ID_UL_SOCIAL_AUTH']?.bucket || 0;
};

export const shouldEnableSocialPopUpInSafari = (exposures) => {
  return exposures?.['UL_SAFARI_SOCIAL_DEVICE_POPUP']?.bucket || 0;
};

export const isConsole = (exposures) => {
  return !!exposures?.['EG_CONSOLE_UL_CREATE_ACCOUNT_OPTION']?.bucket || false;
};

export const enableSmsOtp = (exposures) => {
  return exposures?.['ENABLE_SMS_OTP_UL_WEB']?.bucket || false;
};

export const isLoginWithSmsOtpEnabled = (exposures) => {
  return exposures?.['ENABLE_LOGIN_WITH_PHONE_OTP_UL_WEB']?.bucket || false;
};

export const isAccountMergeSharedUiEnabled = (exposures) => {
  return exposures?.[ACCOUNT_MERGE_IDENTITY_ONBOARDING_V2]?.bucket || 0;
};

export const isTagCommanderFlowEnabled = (exposures) => {
  return exposures?.['TAG_COMMANDER_MARKETING_UL_FLAG']?.bucket || 0;
};

export const isWotifExpansionEnabled = (exposures) => {
  return exposures?.['UP_EXPANSION_WOTIF']?.bucket || 0;
};

export const isShowItalyLegalConsent = (exposures) => {
  return exposures?.['ITALY_LEGAL_CONSENT_EGTNL']?.bucket || 0;
};

export const isKoreaSocialLegalConsentPopupEnabled = (exposures) => {
  return exposures?.['KR_Consents_SocialPopUps_WEB']?.bucket || 0;
};

export const isItalySocialLegalPopupEnabled = (exposures) => {
  return exposures?.['IT_Consents_SocialPopUps_Web']?.bucket || 0;
};

export const isPromotionalMailEnabled = (exposures) => {
  return (
    exposures?.['Communication_Preferences_Update_Phase_1']?.bucket &&
    exposures?.['Switch_Consent_Management_to_UMP_UL_Web']?.bucket || false
  );
};

export const isPromotionalMutationMailEnabled = (exposures) => {
  return exposures?.['Switch_Consent_Management_to_UMP_UL_Web_API_Switch']?.bucket || false;
};
