import { FooterLogoAssets, VrboHeritageBrandEapIds } from 'src/assets';
import { isWotifExpansionEnabled } from './experiment.util';

export const getWotifExpansionAssets = (brand: string, localeKey: string, exposures) => {
  const swapLogos = isWotifExpansionEnabled(exposures) && FooterLogoAssets[`${brand}`]?.[`${localeKey}`].asset4;
  return swapLogos
    ? {
        ...FooterLogoAssets,
        [brand]: {
          ...FooterLogoAssets[brand],
          [localeKey]: {
            ...FooterLogoAssets[brand][localeKey],
            asset2: FooterLogoAssets[brand][localeKey].asset3,
            asset3: FooterLogoAssets[brand][localeKey].asset2,
          },
        },
      }
    : FooterLogoAssets;
};

export const getVrboHeritageBrandLogo = (brand: string, localeKey: string, eapid: number) => {
  if (VrboHeritageBrandEapIds.includes(eapid)) {
    return { link: 'https://a.travel-assets.com/egds/marks/onekey__vrbo.svg', brand: 'vrbo' };
  }
  return FooterLogoAssets[`${brand}`]?.[`${localeKey}`].asset3;
};
