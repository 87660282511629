// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';
import { ExperimentStore } from 'bernie-plugin-mobx';

// Internal dependencies
import { CaptchaServiceStore, ConfigServiceStore, InviteStore, RequestContextServiceStore } from 'src/stores';
import { LoginLogEvents, SignUpLogEvents } from 'loggers';
import { ExtendedContextStore } from 'types';
import { getPlacement } from 'src/utils';
import { isConsole } from 'src/utils/experiment.util';
import { UNIVERSAL_LOGIN_WITH_EMAIL_OTP } from 'src/constants';

export class SignupController implements Controller {
  public pageId = 'Signup';
  public path = '/:locale?/signup';
  public routeName = 'v2signup';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/signup-page/signup-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('SignupController.fetch received invalid FetchOptions');
    }

    const {
      isServer,
      stores,
      query: { oldAto },
      logger,
    } = options;

    logger.logEvent(SignUpLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const captchaStore: CaptchaServiceStore = stores.get<CaptchaServiceStore>('captchaStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    const requestContextStore: RequestContextServiceStore =
      stores.get<RequestContextServiceStore>('requestContextStore');
    const experiments: ExperimentStore = stores.get<ExperimentStore>('experiment');
    const inviteStore: InviteStore = stores.get<InviteStore>('inviteStore');
    const isTwoStepEnabledForUser = experiments?.exposures?.[UNIVERSAL_LOGIN_WITH_EMAIL_OTP]?.bucket || 0;
    let verifyOtpSigninCaptchaWidget,
      verifyOtpSignupCaptchaWidget,
      signUpCaptchaWidget,
      pageRequestContextStore,
      inviteCheck;

    logger.logEvent(SignUpLogEvents.storesCreated);

    const brand = context.site.brand;
    const placement = getPlacement(isTwoStepEnabledForUser, brand, configStore);

    logger.logEvent(SignUpLogEvents.placementLogger(placement));
    logger.logEvent(SignUpLogEvents.captchaStart);

    if (isServer) {
      if (isConsole(experiments?.exposures)) {
        const { invite_code, invite_partner_account_id, invite_client_id } = options.request.query;
        inviteCheck = inviteStore.verifyInviteCode(context, invite_code, invite_partner_account_id, invite_client_id);
      }

      signUpCaptchaWidget = captchaStore
        .getCaptcha(context, oldAto, placement, configStore.oldCaptchaUrl, configStore.newCaptchaUrl)
        .then((data) => {
          if (data.widget) {
            captchaStore.setCaptchaScriptOnPage(data.widget);
            logger.logEvent(SignUpLogEvents.captchaLoaded);
          }
        })
        .catch((error) => {
          logger.logEvent(SignUpLogEvents.captchaLoadedError(error.message));
        });

      if (isTwoStepEnabledForUser) {
        verifyOtpSigninCaptchaWidget = captchaStore
          .getCaptcha(
            context,
            oldAto,
            configStore.getVerifyOtpSigninPlacement(),
            configStore.oldCaptchaUrl,
            configStore.newCaptchaUrl
          )
          .then((data) => {
            if (data.widget) {
              captchaStore.setVerifyOtpSigninCaptchaScriptOnPage(data.widget);
              logger.logEvent(LoginLogEvents.captchaLoaded);
            }
          })
          .catch((error) => {
            logger.logEvent(LoginLogEvents.captchaLoadedError(error.message));
          });

        verifyOtpSignupCaptchaWidget = captchaStore
          .getCaptcha(
            context,
            oldAto,
            configStore.getVerifyOtpSignupPlacement(),
            configStore.oldCaptchaUrl,
            configStore.newCaptchaUrl
          )
          .then((data) => {
            if (data.widget) {
              captchaStore.setVerifyOtpSignupCaptchaScriptOnPage(data.widget);
              logger.logEvent(LoginLogEvents.captchaLoaded);
            }
          })
          .catch((error) => {
            logger.logEvent(LoginLogEvents.captchaLoadedError(error.message));
          });
      }

      logger.logEvent(SignUpLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          context,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          logger.logEvent(SignUpLogEvents.requestContextLoaded(JSON.stringify(data)));
          requestContextStore.setRequestContextDataForPage(data);
        })
        .catch((error) => {
          logger.logEvent(SignUpLogEvents.requestContextError(error.message));
        });
    }
    return await Promise.all([
      signUpCaptchaWidget,
      verifyOtpSigninCaptchaWidget,
      verifyOtpSignupCaptchaWidget,
      pageRequestContextStore,
      inviteCheck,
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.signup.page.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
