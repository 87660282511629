// External dependencies
import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';
import { action, observable } from 'mobx';
import {
  Headers,
  EmailOtpToSessionUserResponse,
  AuthenticationStatus,
  FlowIdentifierType,
  ForgotPasswordLinkCaseType,
} from 'src/types';
import { Context } from 'bernie-context';
import { callFetch } from 'src/utils';
export class AuthDataStore extends Store {
  @observable public userEmail: string;
  @observable public flowIdentifier: FlowIdentifierType;
  @observable public sendOtpToSessionUserStatus: AuthenticationStatus;
  @observable public forgotPasswordLinkCase: ForgotPasswordLinkCaseType;
  forwardedHeaders: Headers;

  constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER, forwardedHeaders?: Headers) {
    super(state, logger);

    if (state.authData) {
      this.userEmail = state.authData.email;
      this.flowIdentifier = state.authData.flowIdentifier;
      this.forgotPasswordLinkCase = state.authData.forgotPasswordLinkCase;
    }
    this.forwardedHeaders = forwardedHeaders;
  }

  public hydrate(data: SerializedData) {
    Object.assign(this, data);
  }

  @action
  public getUserEmail(): string {
    return this.userEmail;
  }

  @action
  public setUserEmail(email: string) {
    this.userEmail = email;
  }

  @action
  public setFlowIdentifier(flowIdentifier: FlowIdentifierType) {
    this.flowIdentifier = flowIdentifier;
  }

  @action
  public getFlowIdentifier(): FlowIdentifierType {
    return this.flowIdentifier;
  }

  public async sendOtpAndGetUserEmailFromSession(context: Context, requestHost: string, requestUrl: string) {
    const systemErrorEvent = { level: SystemEventLevel.ERROR, name: 'eg-auth-ui-v2' };
    const requestHeaders = Object.assign(
      {},
      { cookie: this.forwardedHeaders['cookie'] },
      { 'X-Forwarded-Host': context.site.domain }
    );
    const duaid = context?.deviceId;

    try {
      const { hasError, code, message, username } = (await callFetch(
        `${requestHost}${requestUrl}`,
        'POST',
        context,
        requestHeaders
      )) as EmailOtpToSessionUserResponse;
      if (username && code === 200) {
        this.sendOtpToSessionUserStatus = 'SUCCESS';
        this.userEmail = username;
        this.logger.logEvent(
          { level: SystemEventLevel.INFO, name: 'eg-auth-ui-v2' },
          `AUTH_DATA_STORE - sendOtpToSessionUser API success
          [duaid=${duaid}, userEmail=${username}]`
        );
      } else if (username && hasError) {
        this.sendOtpToSessionUserStatus = 'WARNING';
        this.userEmail = username;
        this.logger.logEvent(
          systemErrorEvent,
          `AUTH_DATA_STORE: sendOtpToSessionUser failed,
        response_status=${code}, error=${message} duaid=${duaid}`
        );
      } else {
        this.sendOtpToSessionUserStatus = 'UNAUTHORISED';
        this.userEmail = null;
        this.logger.logEvent(
          systemErrorEvent,
          `AUTH_DATA_STORE: Error while calling sendOtpToSessionUser API,
        response_status=${code}, error=${message} duaid=${duaid}`
        );
      }
      return {
        hasError,
        code,
        message,
      };
    } catch (_e) {
      this.logger.logEvent(
        systemErrorEvent,
        `AUTH_DATA_STORE: Unexpected Error during sendOtpToSessionUser API call, duaid=${duaid} error=[${_e}]`
      );
      // recover and pass object with error details
      return {
        hasError: true,
        username: null,
      };
    }
  }
}
