import { LoginController } from 'src/controllers/login.controller';
import { SignupController } from 'src/controllers/signup.controller';
import { ForgotPasswordController } from 'src/controllers/forgot-password.controller';
import { SetPasswordController } from 'src/controllers/set-password.controller';
import { SelectProviderController } from 'src/controllers/select-provider.controller';
import { ForgotPasswordControllerV2 } from 'src/controllers/forgot-password-v2.controller';
import { VerifyOtpController } from 'src/controllers/verify-otp.controller';
import { EnterPasswordController } from 'src/controllers/enter-password.controller';
import { UserDeleteAccountController } from 'src/controllers/user-delete-account.controller';
import { AddPasswordController } from './add-password.controller';
import { MarketingConsentController } from './marketing-consent.controller';
import { AccountInfoController } from 'src/controllers/account-info.controller';
import { LoginDefaultErrorController } from 'src/controllers/default-login-error.controller';
import { AddPhoneController } from 'src/controllers/add-phone.controller';
import { AccountMergeController } from 'src/controllers/account-merge.controller';
import { OauthVerifyEmailController } from 'controllers/oauth-verify-email.controller';
import { OIDCAuthenticateController } from 'src/controllers/oidc-authenticate.controller';

export const controllers = [
  LoginController,
  SignupController,
  ForgotPasswordController,
  SetPasswordController,
  SelectProviderController,
  ForgotPasswordControllerV2,
  VerifyOtpController,
  EnterPasswordController,
  UserDeleteAccountController,
  AddPasswordController,
  MarketingConsentController,
  AccountInfoController,
  LoginDefaultErrorController,
  AddPhoneController,
  AccountMergeController,
  OauthVerifyEmailController,
  OIDCAuthenticateController,
];
