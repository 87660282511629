// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';

// Internal dependencies
import { AccountMergeLogEvents } from 'loggers';
import { EN_US_LOCALE } from 'src/constants';
import { capitalizeFirstLetter } from 'src/utils';

export class AccountMergeController implements Controller {
  public pageId = 'AccountMerge';
  public path = '/:locale?/accountmerge';
  public routeName = 'accountmerge';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('views/account-merge-page/account-merge-page.view'));
  public exact = true;

  public async fetch(options: FetchOptions) {
    const { logger } = options;
    if (!options || !options.stores) {
      return Promise.reject('AccountMergeController.fetch received invalid FetchOptions');
    }
    logger.logEvent(AccountMergeLogEvents.startLog);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const locale = options?.request?.context?.locale || EN_US_LOCALE;
    const localization = new Localization(locale);
    const brandName = capitalizeFirstLetter(options?.context?.site?.uiBrand);
    const localizedTitle = localization.formatText('eg.oi.onboarding.page.title.with.brand', brandName);
    return Promise.resolve({
      title: localizedTitle,
      seo: { robots: 'noindex' },
    }) as Promise<PageData>;
  }
}
