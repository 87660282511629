// External dependencies
import { codeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { UserDeleteLogEvents } from 'loggers';
import { ExtendedContextStore } from 'src/types';
import {
  ConfigServiceStore,
  OneIdentityCheckStore,
  OneKeyCreditCardStore,
  UserDeleteAccountDataStore,
} from 'src/stores';
import { Localization } from 'bernie-l10n';
import { ExperimentStore } from 'bernie-plugin-mobx';

export class UserDeleteAccountController implements Controller {
  public pageId = 'UserDelete';
  public path = '/:locale?/user/delete';
  public routeName = 'userDelete';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/user-delete/user-delete-page.view'));
  public exact = true;

  // eslint-disable-next-line class-methods-use-this
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('UserDeleteController.fetch received invalid FetchOptions');
    }

    const {
      isServer,
      stores,
      logger,
      query: { mock },
      request,
    } = options;

    logger.logEvent(UserDeleteLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const userDeleteAccountStore: UserDeleteAccountDataStore =
      stores.get<UserDeleteAccountDataStore>('userDeleteAccountStore');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    const oneIdentityStore: OneIdentityCheckStore = stores.get<OneIdentityCheckStore>('oneIdentityCheckStore');
    const experiments: ExperimentStore = stores.get<ExperimentStore>('experiment');
    const oneKeyCreditCardStore = stores.get<OneKeyCreditCardStore>('oneKeyCreditCardStore');
    const isNewDeleteScreenReady = experiments?.exposures?.['EG_ENABLE_DELETE_ACCOUNT_UPGRADE_ACCT']?.bucket || 0;

    logger.logEvent(UserDeleteLogEvents.storesCreated);

    const placement = configStore.getSignUpPlacement();

    logger.logEvent(UserDeleteLogEvents.placementLogger(placement));

    const requestHeaders = {
      'X-Forwarded-Host': context.site.domain,
    };

    if (isServer) {
      logger.logEvent(UserDeleteLogEvents.getIsOneIdentityOrOneKeyAccount());
      const session = await oneIdentityStore.exchangePrincipalToken(context, request);
      const isOneIdentityOnboarded = await oneIdentityStore.getOneIdentity(session);

      if (isNewDeleteScreenReady === 1) {
        oneIdentityStore.setOneIdentityFlag(isOneIdentityOnboarded);
        userDeleteAccountStore.setIsOneIdentityOnboardedUser(isOneIdentityOnboarded);
      } else {
        oneIdentityStore.setOneIdentityFlag(false);
        userDeleteAccountStore.setIsOneIdentityOnboardedUser(false);
      }

      logger.logEvent(UserDeleteLogEvents.setIsOneIdentityOrOneKeyAccount(isOneIdentityOnboarded));
      const userDeleteAccountData = await userDeleteAccountStore.getUserDeleteAccountData(
        context,
        requestHeaders,
        configStore,
        mock
      );
      userDeleteAccountStore.setUserDeleteAccountData(userDeleteAccountData);
      if (isOneIdentityOnboarded && session) {
        const creditCardType = await oneKeyCreditCardStore.getCreditCardInfo(session, context);
        userDeleteAccountStore.setCreditCardType(creditCardType);
      }
      logger.logEvent(UserDeleteLogEvents.deleteAccountLoaded);
    }

    return await Promise.resolve();
  }

  // eslint-disable-next-line class-methods-use-this
  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const localization = new Localization(options?.request?.context?.locale);
    const formattedTitle = localization.formatText('eg.delete.account.section.title');
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
