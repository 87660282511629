// External dependencies
import { Controller, codeSplit, FetchOptions, FetchPageOptions } from 'bernie-core';
import { Localization } from 'bernie-l10n';
import { PageData } from 'bernie-http';

// Internal dependencies
import { ConfigServiceStore, RequestContextServiceStore } from 'src/stores';
import { AddPasswordLogEvents } from 'src/loggers';
import { ExtendedContextStore } from 'src/types';
import { capitalizeFirstLetter, getAddPasswordScenarioConfig } from 'src/utils';

export class AddPasswordController implements Controller {
  public pageId = 'AddPassword';
  public path = '/:locale?/addpassword';
  public routeName = 'addpassword';
  public bundles = [];
  public caseSensitive = false;

  /* istanbul ignore next */
  public component = codeSplit(() => import('src/views/add-password-page/add-password-page.view'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async fetch(options: FetchOptions) {
    if (!options || !options.stores) {
      return Promise.reject('AddPasswordController.fetch received invalid FetchOptions');
    }

    const { isServer, stores, logger } = options;

    logger.logEvent(AddPasswordLogEvents.startLog);

    const context = stores.get<ExtendedContextStore>('context');
    const configStore: ConfigServiceStore = stores.get<ConfigServiceStore>('configStore');
    const requestContextStore: RequestContextServiceStore =
      stores.get<RequestContextServiceStore>('requestContextStore');
    let pageRequestContextStore;
    logger.logEvent(AddPasswordLogEvents.storesCreated);

    if (isServer) {
      logger.logEvent(AddPasswordLogEvents.requestContextStart);

      pageRequestContextStore = requestContextStore
        .getRequestContext(
          context,
          options.request.headers,
          configStore.requestContextHost,
          configStore.requestContextUrl
        )
        .then((data) => {
          requestContextStore.setRequestContextDataForPage(data);
          logger.logEvent(AddPasswordLogEvents.requestContextLoaded(JSON.stringify(data)));
        })
        .catch((error) => {
          logger.logEvent(AddPasswordLogEvents.requestContextError(error.message));
        });
    }
    return await Promise.all([pageRequestContextStore]);
  }

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    const variant = options?.query?.variant;
    const localization = new Localization(options?.request?.context?.locale);
    const brandName = capitalizeFirstLetter(options?.context?.site?.uiBrand);
    const { title } = getAddPasswordScenarioConfig(variant);
    const formattedTitle = localization.formatText(title, brandName);
    return Promise.resolve({ title: formattedTitle, seo: { robots: 'noindex' } }) as Promise<PageData>;
  }
}
