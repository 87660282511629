import { ACCOUNT_INFO_CONFIG } from '../constants';
import { FlowIdentifierType } from 'src/types';

export const getAccountInfoConfigUtils = (variant: FlowIdentifierType) => {
  const defaultConfig = ACCOUNT_INFO_CONFIG.PROFILE_DETAIL_0;

  if (!variant) {
    return defaultConfig;
  }

  const config = ACCOUNT_INFO_CONFIG[variant];
  if (!config) {
    return defaultConfig;
  }
  return config;
};
